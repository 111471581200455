import Dropdown from '@/gf/components/next/Dropdown'

import { useAssignUserMutation, useUsersListQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Spinner from '@/gf/components/Spinner'
import { Maybe } from '@/types'
import { Placement } from '@floating-ui/react'
import { CheckIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { ReactNode } from 'react'
import useSession from '@/buyers/hooks/useSession'

const UsersList = ({
  assignedUserId,
  onAssignClick,
  assignInProgress,
  menuClassName,
}: {
  assignedUserId: Maybe<string>
  onAssignClick: (userId: string) => void
  assignInProgress: boolean
  menuClassName?: string
}) => {
  const { user } = useSession()
  const { data, loading } = useUsersListQuery({
    client: useGqlClient(),
  })

  const orgUsers = data?.fetchOrganizationUsers ?? []

  return (
    <div
      className={classNames(
        'flex flex-col bg-white rounded-md shadow-lg py-1 border border-gray-200 w-72 min-h-[4rem]',
        menuClassName
      )}
    >
      {loading || assignInProgress ? (
        <Spinner size="sm" />
      ) : (
        orgUsers
          .filter((u) => u.userRole?.name && ['Purchaser', 'admin'].includes(u.userRole?.name))
          // Sort the current user at the top of the list, or fall back on sorting alphabetically
          .sort((userA, userB) =>
            userA.id === user.id
              ? -1
              : userB.id === user.id
                ? 1
                : // Fall back on alphabetical sorting
                  userA.displayName < userB.displayName
                  ? -1
                  : userA.displayName > userB.displayName
                    ? 1
                    : 0
          )
          .map((u) => (
            <button
              key={u.id}
              type="button"
              onClick={() => assignedUserId !== u.id && onAssignClick(u.id)}
              className="truncate w-full py-2 pl-1.5 pr-3 border-b last:border-0 hover:bg-blue-100 text-left text-sm flex gap-x-1"
            >
              <CheckIcon
                className={classNames(
                  'h-5 w-5 inline-block flex-shrink-0',
                  assignedUserId === u.id ? 'text-blue-600' : 'text-transparent'
                )}
              />

              {u.displayName}
            </button>
          ))
      )}
    </div>
  )
}

const AssignUserDropdown = ({
  rfq,
  onUserAssigned,
  trigger,
  menuClassName,
  placement = 'bottom-start',
}: {
  rfq: { id: string; assignedUser: Maybe<{ id: string; displayName: string; email: string }> }
  onUserAssigned: () => void
  trigger: ReactNode
  menuClassName?: string
  placement?: Placement
}) => {
  const [assignUser, { loading: assignInProgress }] = useAssignUserMutation({
    client: useGqlClient(),
  })

  return (
    <Dropdown trigger={trigger} placement={placement} stopEventPropagation closeOnMenuClick>
      <UsersList
        onAssignClick={(userId) =>
          assignUser({ variables: { userId, rfqId: rfq.id } }).then(() => onUserAssigned())
        }
        assignedUserId={rfq.assignedUser?.id ?? null}
        assignInProgress={assignInProgress}
        menuClassName={menuClassName}
      />
    </Dropdown>
  )
}

export default AssignUserDropdown
