import {
  CreateRequestPageQuery,
  useAdvancedCreateRfqMutation,
  useCreateRequestPageQuery,
  useCreateRequestSelectLocationsQuery,
  useRequestOrderLazyQuery,
} from '@/buyers/_gen/gql'
import Page from '@/gf/components/Page'
import * as GE from '@/gf/modules/GrammarEvents'
import pick from 'lodash/pick'
import { useEffect, useMemo } from 'react'
import { Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import BrokerWarning from '../components/BrokerWarning'
import Frame from '../components/Frame'
import { CreateRequestTutorialBanner } from '../components/TutoriaBanners'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import PartDetailsStep from './CreateRequest/PartDetailsStep'
import ProgressBar from './CreateRequest/ProgressBar'
import ReviewStep from './CreateRequest/ReviewStep'
import SelectVendorsStep from './CreateRequest/SelectVendorsStep'
import SelectLocationStep from './CreateRequest/SelectLocationStep'
import { CreateRequestState } from './CreateRequest/types'
import usePersistedState from './CreateRequest/usePersistedState'
import useVendorSelectionType from './CreateRequest/useVendorSelectionType'
import Action from '@/gf/components/Action'
import OrderType from './CreateRequest/OrderType'
import { nth } from 'lodash'
import { US_CENTER } from '@/gf/modules/Map'

type Org = NonNullable<CreateRequestPageQuery['org']>
type DefaultLocation = CreateRequestPageQuery['defaultShippingLocation']

type Step = {
  label: string
  stepPath: string
  index: number
  hidden?: boolean
  progressFn: (request: CreateRequestState) => number
}

const BASE_PATH = '/rfqs/create'

const locationAndReferencePoint = (location: NonNullable<DefaultLocation>) => ({
  locationId: location.id,
  nearbyReferencePoint: location?.address?.point
    ? pick(location?.address?.point, ['lat', 'lng'])
    : undefined,
})

const partsDetailsProgress = (request: CreateRequestState) => {
  const machineProgress = !request.machineInvolved ? 40 : request.machineOrgId ? 40 : 0
  const partsProgress = request.parts?.[0]?.partNumber || request.parts?.[0]?.description ? 50 : 0
  const urgencyProgress = request.urgency?.priority ? 10 : 0
  return machineProgress + partsProgress + urgencyProgress
}

const locationProgress = (request: CreateRequestState) => (request.locationId ? 100 : 0)
const orderTypeProgress = (request: CreateRequestState) =>
  request.quickOrder !== undefined ? 100 : 0

const vendorsProgress = (request: CreateRequestState) => {
  const dealersProgress = request.dealerLocationIds.length > 0 ? 50 : 0

  return request.broadcastToNetwork ? 100 : dealersProgress
}

const createStepsConfig = (includeVendors: boolean) =>
  [
    { label: 'Add Part Details', stepPath: 'part-details', progressFn: partsDetailsProgress },
    includeVendors
      ? {
          label: 'Select Location',
          stepPath: 'location',
          progressFn: locationProgress,
        }
      : null,
    includeVendors
      ? { label: 'Select Vendors', stepPath: 'vendors/*', progressFn: vendorsProgress }
      : null,
    includeVendors
      ? {
          label: 'Order Type',
          stepPath: 'order-type',
          progressFn: orderTypeProgress,
          hidden: true,
        }
      : null,
    { label: 'Review', stepPath: 'review', progressFn: (_request: CreateRequestState) => 100 },
  ]
    .filter((s) => s !== null)
    .map((s, index) => ({ ...s, index }) as Step)

const Redirect = () => {
  const { user } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    GE.initiatesRequest()

    navigate('./part-details', { replace: true })
  }, [user.id])

  return null
}

const CreateRequestLoaded = ({
  org,
  defaultLocation,
}: {
  org: Org
  defaultLocation: DefaultLocation
}) => {
  const client = useGqlClient()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, organization } = useSession()
  const [getRequestOrder] = useRequestOrderLazyQuery({ client })

  const { values, update, reset } = usePersistedState('new-request-data-2')

  const { data: selectLocationsData } = useCreateRequestSelectLocationsQuery({
    variables: {
      orgId: organization.id,
      userId: user.id,
      search: '',
      orderBy: { id: 'request_date', order: 'desc' },
    },
    client,
  })

  const vendorsSectionEnabled = !(
    organization.requestApproval && user.userRole?.name === 'Requester'
  )

  const gearflowNetworkEnabled =
    (!organization.requestApproval || user.userRole?.name !== 'Requester') && !values.brokerRfqId

  const [createRfq, { loading: createRequestInProgress }] = useAdvancedCreateRfqMutation({ client })

  const resetForm = () => {
    GE.clicksButtonOnFlow('start-over', GE.UserFlow.CreateRequest)
    reset()
    GE.initiatesRequest()
    navigate('part-details')

    if (!vendorsSectionEnabled && defaultLocation) {
      update(locationAndReferencePoint(defaultLocation))
    }

    // Clear the state if the form is still on the first step
    if (location.pathname.includes('part-details')) window.location.reload()
  }

  const exitTutorial = () => {
    reset()

    navigate('/')
  }

  const stepsConfig = useMemo(
    () => createStepsConfig(vendorsSectionEnabled),
    [vendorsSectionEnabled]
  )

  const currentStep = useMemo(() => {
    const matchingStep = stepsConfig.find(
      (step) => !!matchPath({ path: `${BASE_PATH}/${step.stepPath}` }, location.pathname)
    )
    const step = matchingStep ?? stepsConfig[0]

    return { ...step, progress: matchingStep?.progressFn(values) ?? 0 }
  }, [location.pathname, values, vendorsSectionEnabled])

  const onPartDetailsSubmitted = () => {
    const nextUrl = vendorsSectionEnabled ? 'location' : 'review'

    navigate(nextUrl)
  }

  const vendorSelectionType = useVendorSelectionType(org)

  const onVendorsSubmitted = () => navigate(`review`)

  const onCreateRequest = async (poNumber?: string) => {
    const createResult = await createRfq({
      variables: {
        name: user.name || '',
        email: user.email,
        phoneNumber: user.phoneNumber,
        billingCompanyId: values.billingCompanyId ?? null,
        splitOrder: false,
        partsRequest: values.comments ?? null,
        orgMachineId: values.machineOrgId || null,
        vendorIds: values.vendors.map((vi) => vi.vendorId),
        vendors: values.vendors.map((v) => ({
          vendorContactIds: v.contactIds,
          deliveryMethod: v.deliveryMethod,
          id: v.vendorId,
          customerNote: null,
          vendorContactId: null,
          pickup: null,
          accountNumber: v.accountNumber,
        })),
        shippingLocationIds: [values.locationId as string],
        shippingAddress: null,
        neededBy: values.urgency?.neededByDate.toUTC() ?? null,
        imageUrls: values.parts?.map((p) => p.pictures).flat() ?? [],
        partRequests:
          values.parts?.map((part) => ({
            mpn: part.partNumber,
            description: part.description,
            quantity: part.quantity,
            externalId: part.externalId,
            taskNumber: part.taskNumber,
            suggestion: part.suggestion,
            jobQuantities: part.jobQuantities,
          })) ?? null,
        operator: null,
        engineHours: null,
        lat: null,
        lng: null,
        machineDown: values.urgency?.machineDown ?? false,
        workOrderNumber: values.workOrderNumber ?? null,
        brokerRequestForQuoteId: values.brokerRfqId ?? null,
        quickOrder: values.quickOrder ?? false,
        broadcastToNetwork: values.broadcastToNetwork,
        purchaseOrder: poNumber ?? null,
      },
    })

    if (createResult.data) {
      reset()

      const rfqId = createResult.data.advancedCreateRfq.id
      GE.createsRequest(rfqId)

      if (values.quickOrder) {
        const { data } = await getRequestOrder({
          variables: { orgId: organization.id, filter: `[["id_eq","${rfqId}"]]` },
        })

        navigate(`/orders/${data?.searchRequests.requests[0]?.storeOrders[0]?.id}/quick-order`)
      } else {
        navigate(`/rfqs/${rfqId}?new=1&init=1`)
      }
    } else {
      console.error(createResult.errors)
    }
  }

  useEffect(() => {
    if (!vendorsSectionEnabled && defaultLocation) {
      update(locationAndReferencePoint(defaultLocation))
    }
  }, [vendorsSectionEnabled, defaultLocation])

  // Set the default location if there isn't one already
  useEffect(() => {
    if (!values.locationId && selectLocationsData) {
      const defaultLocation =
        selectLocationsData.defaultShippingLocation ?? nth(selectLocationsData.org?.locations, 0)
      if (defaultLocation) {
        update({
          nearbyReferencePoint: defaultLocation.address.point
            ? pick(defaultLocation.address.point, ['lat', 'lng'])
            : US_CENTER,
          locationId: defaultLocation.id,
        })
      }
    }
  }, [values, selectLocationsData])

  return (
    <Frame topBanner={values.tutorial ? <CreateRequestTutorialBanner /> : undefined}>
      <Page
        className="mt-4"
        title={values.tutorial ? 'Order Parts Tutorial' : 'Order Parts'}
        actionsNext={
          values.tutorial
            ? [
                <Action.S onClick={exitTutorial} className="flex-shrink-0 font-medium">
                  Exit Tutorial
                </Action.S>,
              ]
            : []
        }
      >
        <div className="flex flex-col gap-y-6 mt-4 pb-12">
          {values.brokerRfqId && <BrokerWarning.Request fulfillingRfqId={values.brokerRfqId} />}
          <div className={values.tutorial ? 'flex gap-x-4' : undefined}>
            <ProgressBar
              steps={stepsConfig.filter((s) => !s.hidden).map((s) => s.label)}
              currentStep={currentStep.hidden ? currentStep.index - 1 : currentStep.index}
              currentStepProgress={currentStep.hidden ? 0 : currentStep.progress}
            />
          </div>

          <div className="space-y-4">
            <Routes>
              <Route path="" element={<Redirect />} />
              <Route
                path="part-details"
                element={
                  <PartDetailsStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onSubmit={onPartDetailsSubmitted}
                    billingCompanyRequired={organization.requireBillingCompany ?? false}
                    workOrderNumberRequired={organization.createRequestRequireWorkOrder ?? false}
                    submitButtonText={vendorsSectionEnabled ? 'Save and Select Location' : 'Next'}
                    onOrgMachineSelected={(orgMachineId) => GE.selectsOrgMachine(orgMachineId)}
                    onAddPartsClicked={() =>
                      GE.clicksButtonOnFlow('add-part', GE.UserFlow.CreateRequest)
                    }
                    onPriorityChanged={(value) => GE.selectsPriority(value)}
                    orgHasMachines={org.stats.fleetCount > 0}
                    jobNumbersEnabled={organization.requestsJobNumbersEnabled ?? false}
                  />
                }
              />
              <Route
                path="location"
                element={
                  <SelectLocationStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onBackClicked={() => navigate('part-details')}
                    orgHasLocations={org.stats.shippingLocationCount > 0}
                    onLocationChanged={(point, selectedLocationId) =>
                      update({ nearbyReferencePoint: point, locationId: selectedLocationId })
                    }
                  />
                }
              />
              <Route
                path="order-type"
                element={
                  <OrderType
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onBackClicked={() => navigate('location')}
                    vendorSelectionType={vendorSelectionType}
                  />
                }
              />
              <Route
                path="vendors/*"
                element={
                  <SelectVendorsStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onSubmit={onVendorsSubmitted}
                    onBackClicked={() => navigate('order-type')}
                    vendorSelectionType={values.tutorial ? 'limited' : vendorSelectionType}
                    gearflowNetworkEnabled={gearflowNetworkEnabled}
                  />
                }
              />
              <Route
                path="review"
                element={
                  <ReviewStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onBackClicked={() =>
                      navigate(
                        vendorsSectionEnabled
                          ? vendorSelectionType === 'disabled'
                            ? 'order-type'
                            : 'vendors'
                          : 'part-details'
                      )
                    }
                    onCreateRequestClicked={onCreateRequest}
                    submitInProgress={createRequestInProgress}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </Page>
    </Frame>
  )
}

const CreateRequest = () => {
  const client = useGqlClient()
  const { orgId, user } = useSession()

  const { org, planSubscription, defaultShippingLocation } =
    useCreateRequestPageQuery({ variables: { orgId, userId: user.id }, client }).data || {}

  return planSubscription !== undefined && org && defaultShippingLocation !== undefined ? (
    <CreateRequestLoaded org={org} defaultLocation={defaultShippingLocation} />
  ) : null
}

export default CreateRequest
