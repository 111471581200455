import { ExclamationIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { Link as RouterLink } from 'react-router-dom'

import { DeliveryMethod, StoreOrderStep } from '@/buyers/_gen/gql'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { useOrderContext } from './context'

import AssignUserDropdown from '@/buyers/components/AssignUserDropdown'
import Link from '@/gf/components/Link'
import NeededBy from '@/gf/components/NeededBy'
import TimeOpen from '@/gf/components/TimeOpen' // TODO: don't import from dealers
import UserInitials from '@/gf/components/UserInitials'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import StepBadge from './StepBadge'
import useSession from '@/buyers/hooks/useSession'

const NOT_SET = <span className="italic text-gray-500">(not set)</span>

export const Info = ({ label, children }) => (
  <div className="space-y-1 text-base flex-shrink-0">
    <div className="font-medium">{label}</div>
    <div className="inline-flex">{children}</div>
  </div>
)

const Header = () => {
  const { storeOrder, user, refetchStoreOrder } = useOrderContext()
  const { featureFlags } = useSession()
  const rfq = storeOrder.order.requestForQuote

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col md:flex-row gap-x-3 gap-y-2 justify-between lg:items-center">
        <h2 className="flex shrink-0 flex-row flex-nowrap gap-x-3 items-center">
          {storeOrder.store.logo?.url && (
            <img
              src={storeOrder.store.logo.url}
              className="h-[2.8125rem] w-[2.8125rem] rounded-full overflow-hidden object-contain bg-white p-px"
              alt={`${storeOrder.store.name} logo`}
            />
          )}
          <div className="text-2xl font-medium leading-tight max-w-lg">{storeOrder.store.name}</div>
        </h2>

        <div className="flex flex-wrap flex-row gap-x-4 lg:justify-end items-center text-sm text-gray-900 whitespace-nowrap">
          {rfq?.taskNumber && <div>Task: {rfq.taskNumber}</div>}
          {rfq?.workOrderNumber && <div>Work Order: {rfq.workOrderNumber}</div>}
          <div>Order ID: {storeOrder.shortId}</div>
          {storeOrder.quoteNumber && <div>Quote Number: {storeOrder.quoteNumber}</div>}
          {(StoreOrderM.isQuoteApproved(storeOrder) || storeOrder.purchaseOrder) && (
            <div className="flex gap-1 items-center">
              <span>PO Number: {storeOrder.purchaseOrder || NOT_SET}</span>
              <RouterLink
                to="edit-purchase-order"
                replace
                type="button"
                className="p-1 text-gray-700 flex items-center rounded-full group hover:bg-gray-200 transition duration-75"
              >
                <PencilIcon className="h-5 w-5 flex shrink-0 text-gray-700" />
              </RouterLink>
            </div>
          )}

          {featureFlags.routingCode && (
            <div className="flex gap-1 items-center">
              <span>Routing Code: {storeOrder.routingCode || NOT_SET}</span>
              <RouterLink
                to="set-routing-code"
                replace
                type="button"
                className="p-1 text-gray-700 flex items-center rounded-full group hover:bg-gray-200 transition duration-75"
              >
                <PencilIcon className="h-5 w-5 flex shrink-0 text-gray-700" />
              </RouterLink>
            </div>
          )}
        </div>
      </div>

      <div className="lg:mx-6 flex flex-col md:flex-row justify-between md:items-center gap-x-8 gap-y-2">
        <div className="flex flex-row items-start gap-8">
          {rfq && (
            <Info label="Time Open">
              <TimeOpen
                insertedAt={rfq.insertedAt}
                completedAt={storeOrder.readyAt}
                showIcon={false}
              />
            </Info>
          )}
          {rfq?.neededBy && (
            <Info label="Urgency">
              <div className="inline-flex items-center gap-x-2">
                {rfq.machineDown && (
                  <Tooltip>
                    <TooltipTrigger>
                      <span className="p-1 flex items-center justify-center border border-red-200 bg-red-100 rounded-full">
                        <ExclamationIcon className="h-4 w-4 flex shrink-0 text-red-700" />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                      Machine down
                    </TooltipContent>
                  </Tooltip>
                )}
                <NeededBy date={rfq.neededBy} prefix="Needed " />
              </div>
            </Info>
          )}
        </div>
        <div className="flex flex-row items-start gap-8">
          {rfq && user.claimed && (
            <Info label="Request">
              <Link.T to={`/rfqs/${rfq.id}`}>{rfq.shortId}</Link.T>
            </Info>
          )}
          <Info label="Status">
            <div className="-mb-0.5">
              {storeOrder.step === StoreOrderStep.Canceled && storeOrder.rejection ? (
                <div className="rounded border font-medium text-sm px-2 py-1 bg-purple-100 border-purple-200 text-purple-700">
                  Quote Denied
                </div>
              ) : (
                <StepBadge step={storeOrder.step} />
              )}
            </div>
          </Info>
          <Info label="Method">
            {storeOrder.deliveryMethod &&
            [DeliveryMethod.Shipping, DeliveryMethod.VendorDelivery].includes(
              storeOrder.deliveryMethod
            )
              ? 'Shipping'
              : 'Will Call'}
          </Info>
          {storeOrder.order.requestForQuote && (
            <Info label="Owner">
              <div
                className={storeOrder.order.requestForQuote.assignedUser ? '-my-0.5' : undefined}
              >
                <AssignUserDropdown
                  rfq={storeOrder.order.requestForQuote}
                  onUserAssigned={refetchStoreOrder}
                  placement="bottom-end"
                  menuClassName="mt-1"
                  trigger={
                    <span>
                      {storeOrder.order.requestForQuote.assignedUser ? (
                        <Tooltip placement="left">
                          <TooltipTrigger>
                            <UserInitials
                              name={storeOrder.order.requestForQuote.assignedUser.displayName}
                              size="sm"
                            />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-col">
                                <span>
                                  {storeOrder.order.requestForQuote.assignedUser.displayName}
                                </span>

                                {storeOrder.order.requestForQuote.assignedUser.displayName !==
                                  storeOrder.order.requestForQuote.assignedUser.email && (
                                  <span>
                                    ({storeOrder.order.requestForQuote.assignedUser.email})
                                  </span>
                                )}
                              </div>
                              <span className="text-gray-500 italic">
                                Click to assign someone else
                              </span>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      ) : (
                        <span className="text-blue-500 underline">Assign</span>
                      )}
                    </span>
                  }
                />
              </div>
            </Info>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
