import { useEffect, useState } from 'react'
import uniqueId from 'lodash/uniqueId'
import { Maybe, ModalSize } from '@/types'
import JobSelector from '@/buyers/components/JobSelector'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import TextInput from '@/gf/components/next/forms/TextInput'
import Action from '@/gf/components/Action'
import { PlusIcon } from '@heroicons/react/solid'
import {
  useUpdateLineItemJobNumbersMutation,
  useUpdatePartRequestJobNumbersMutation,
} from '@/buyers/_gen/gql'
import Field from '@/gf/components/next/forms/Field'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import FieldError from '@/gf/components/next/forms/FieldError'

type JobQuantityInput = { id: string; jobId: Maybe<string>; quantity: string; error: Maybe<string> }

const NA = <span className="italic text-gray-500">N/A</span>

type PartDetails = { partNumber: Maybe<string>; description: Maybe<string>; quantity: number }
type JobQuantity = {
  job: {
    id: string
  }
  quantity: number
}

const initialState = (part: PartDetails, jobQuantities: JobQuantity[]) =>
  jobQuantities && jobQuantities.length > 0
    ? jobQuantities.map((jq) => ({
        id: uniqueId(),
        jobId: jq.job.id,
        quantity: jq.quantity.toFixed(0),
        error: null,
      }))
    : [{ id: uniqueId(), jobId: null, quantity: part.quantity.toFixed(0), error: null }]

type PartReqOrLineItem =
  | { lineItemId: string; partRequestId?: never }
  | { lineItemId?: never; partRequestId: string }

const EditPartRequestJobNumbersModal = ({
  open,
  onClose,
  part,
  onSuccess,
  lineItemId,
  partRequestId,
  jobQuantities: jobQuantitiesProp,
}: {
  part: PartDetails
  jobQuantities: JobQuantity[]
  open: boolean
  onClose: () => void
  onSuccess: () => void
} & PartReqOrLineItem) => {
  const client = useGqlClient()
  const [partRequestMutation] = useUpdatePartRequestJobNumbersMutation({ client })
  const [lineItemMutation] = useUpdateLineItemJobNumbersMutation({ client })
  const [_, msgs] = useMsgs()
  const [performing, performingToggle] = useToggle()
  const [error, setError] = useState<Maybe<string>>(null)
  const [jobQuantities, setJobQuantities] = useState<JobQuantityInput[]>(() =>
    initialState(part, jobQuantitiesProp)
  )

  useEffect(() => {
    if (!open) {
      setJobQuantities(initialState(part, jobQuantitiesProp))
      setError(null)
      performingToggle.off()
    }
  }, [open, part, jobQuantitiesProp])

  const updateJobQuantity = (jobQuantity: JobQuantityInput) => {
    setJobQuantities((prev) =>
      prev.map((jq) => (jq.id === jobQuantity.id ? { ...jobQuantity, error: null } : jq))
    )
  }

  const removeJobQuantity = (id: string) => {
    setJobQuantities((prev) => {
      const newList = prev.filter((jq) => jq.id !== id)
      if (newList.length === 1) {
        newList[0].quantity = part.quantity.toFixed(0)
        return newList
      }
      return newList
    })
  }

  const validateItem = (item: JobQuantityInput) => {
    if (!item.jobId) {
      return 'Job Number is required'
    }

    if (!item.quantity) {
      return 'Quantity is required'
    }

    const parsedQty = parseInt(item.quantity, 10)
    if (Number.isNaN(parsedQty)) {
      return 'Quantity must be a number'
    }

    return null
  }

  const validate = (values: JobQuantityInput[]) => {
    const jobQuantities = values.map((jq) => ({
      ...jq,
      error: validateItem(jq),
    }))

    // validate total quantity

    const jobsQty = jobQuantities.reduce((sum, jq) => {
      const parsedQty = parseInt(jq.quantity, 10)
      return sum + (Number.isNaN(parsedQty) ? 0 : parsedQty)
    }, 0)

    const totalQtyError =
      jobQuantities.length > 0 && jobsQty !== part.quantity
        ? `The sum of job quantities (${jobsQty}) must be the same as requested quantity (${part.quantity})`
        : null

    return {
      jobQuantities,
      totalQtyError,
      hasErrors: !!totalQtyError || jobQuantities.some((jq) => !!jq.error),
    }
  }

  const onSubmit = async () => {
    try {
      performingToggle.on()

      const result = validate(jobQuantities)
      if (result.hasErrors) {
        setJobQuantities(result.jobQuantities)
        setError(result.totalQtyError)
      } else {
        const jobQuantities = result.jobQuantities.map((jq) => ({
          jobId: jq.jobId ?? '',
          quantity: parseInt(jq.quantity, 10),
        }))

        if (lineItemId) {
          await lineItemMutation({
            variables: {
              lineItemId: lineItemId,
              jobQuantities,
            },
          })
        }

        if (partRequestId) {
          await partRequestMutation({
            variables: {
              partRequestId: partRequestId,
              jobQuantities,
            },
          })
        }

        msgs.add('Job Numbers saved.', 'positive')

        onClose()
        onSuccess()
      }
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      performingToggle.off()
    }
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="p-8 relative flex flex-col gap-y-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <h3 className="text-2xl font-medium">Job Numbers</h3>

        <div className="space-y-2">
          <p className="grid grid-cols-[7rem_auto] gap-x-4 text-base">
            <span>Part Number: </span> <span>{part.partNumber || NA}</span>
            <span>Description: </span> <span>{part.description || NA}</span>
            <span>Quantity: </span> <span>{part.quantity}</span>
          </p>

          <FieldError error={error ?? undefined} />
        </div>

        <div className="space-y-6">
          {jobQuantities.length === 0 ? (
            <p className="text-sm max-w-96">This part is not assigned to a Job Number.</p>
          ) : (
            jobQuantities.map((jq) => (
              <div key={jq.id}>
                <div className="flex gap-x-3">
                  <Field label="Job Number" className="flex-grow" error={jq.error}>
                    <JobSelector
                      value={jq.jobId ?? null}
                      onChange={(selectedJobId) =>
                        updateJobQuantity({ ...jq, jobId: selectedJobId })
                      }
                    />
                  </Field>

                  {jobQuantities.length > 1 && (
                    <Field label="Quantity" className="flex-shrink-0 w-24">
                      <TextInput
                        value={jq.quantity}
                        onChange={(e) => updateJobQuantity({ ...jq, quantity: e.target.value })}
                        type="number"
                        min={1}
                      />
                    </Field>
                  )}
                </div>
                <Action.T onClick={() => removeJobQuantity(jq.id)} className="text-sm pt-2">
                  Remove
                </Action.T>
              </div>
            ))
          )}
        </div>

        <div>
          <Action.S
            size="sm"
            disabled={part.quantity === 1 && jobQuantities.length === 1}
            onClick={() =>
              setJobQuantities((prev) => [
                ...prev,
                { id: uniqueId(), jobId: null, quantity: '1', error: null },
              ])
            }
            className="inline-flex items-center gap-x-2"
          >
            <PlusIcon className="h-4 w-4 inline-block" /> Add Job Number
          </Action.S>
        </div>

        <div className="flex gap-x-4">
          <Action.S onClick={onClose} className="flex-1 text-base font-medium">
            Cancel
          </Action.S>
          <Action.P
            onClick={onSubmit}
            performing={performing}
            color="blue"
            className="flex-1 text-base font-medium"
          >
            Update
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default EditPartRequestJobNumbersModal
