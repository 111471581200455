import useQuoteTotals from '@/dealers/pages/Request/QuoteBuilder/useQuoteTotals'
import Summary from '@/dealers/pages/Request/Summary'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import Page from '@/gf/components/Page'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import QuantityInput from '@/gf/components/inputs/Quantity'
import TextInput from '@/gf/components/inputs/Text'
import MoneyM from '@/gf/modules/Money'
import { Maybe, Money } from '@/types'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  QuickOrderLineItemInput,
  useUpdateOrderQuery,
  useUpdateQuickOrderMutation,
} from '../_gen/gql'
import Box from '../components/Box'
import Frame from '../components/Frame'
import useGqlClient from '../hooks/useGqlClient'

type Item = {
  id: Maybe<string>
  partNumber: string
  description: string
  unitPrice: Money | null
  quantity: number | null
}

type Fields = {
  shippingCost: Money | null
  taxCost: Money | null
  items: Item[]
}

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const newItem = {
  id: null,
  partNumber: '',
  description: '',
  unitPrice: null,
  quantity: 1,
}

const newFields = {
  shippingCost: null,
  taxCost: null,
  items: [newItem],
}

const UpdateQuickOrder = () => {
  const { storeOrderId } = useParams() as { storeOrderId: string }
  const navigate = useNavigate()
  const client = useGqlClient()
  const [fields, setFields] = useState<Fields>(newFields)

  const { data } = useUpdateOrderQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client,
    onCompleted(data) {
      if (!data.storeOrder) {
        return
      }

      const items = data.storeOrder.lineItems.map((li) => ({
        id: li.id,
        partNumber: li.product.mpn ?? '',
        description: li.product.name ?? '',
        unitPrice: li.unitPrice,
        quantity: li.quantity,
      }))

      setFields({
        items,
        shippingCost: data.storeOrder.shippingAmount,
        taxCost: data.storeOrder.salesTax,
      })
    },
  })
  const [updateOrder] = useUpdateQuickOrderMutation({ client })

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  const updateItemField = (index: number, updates: Partial<Item>) => {
    const items = Object.assign([], fields.items, {
      [index]: { ...fields.items[index], ...updates },
    })

    updateFields({ items })
  }

  const { totals } = useQuoteTotals({
    items: fields.items.map((i) => ({
      ...i,
      unitPrice: i.unitPrice ? MoneyM.toDecimal(i.unitPrice) : null,
    })),
    taxAmount: fields.taxCost,
    shippingAmount: fields.shippingCost,
    customerDiscount: 0,
    customerFeeRate: null,
  })

  const onSubmit = async () => {
    await updateOrder({
      variables: {
        storeOrderId,
        shippingCost: fields.shippingCost,
        taxCost: fields.taxCost,
        lineItems: fields.items as QuickOrderLineItemInput[],
      },
    })

    navigate(`/orders/${storeOrderId}`)
  }

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Orders', href: '/orders' },
      { name: `Order ${data?.storeOrder?.shortId}`, href: `/orders/${data?.storeOrder?.id}` },
      { name: 'Update', href: `/orders/${data?.storeOrder?.id}/update` },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Update Order" previousPage={`/orders/${data?.storeOrder?.id}`}>
        <Form
          onSubmit={onSubmit}
          className="flex flex-wrap gap-x-6 gap-y-4 md:flex-nowrap max-w-screen-lg mt-4"
        >
          <Box className="space-y-8 text-sm">
            {fields.items.map((item, index) => (
              <div className="space-y-3">
                <div className="flex justify-between">
                  <h4 className="text-lg font-medium">
                    Part {fields.items.length > 1 ? index + 1 : null}
                  </h4>

                  {index > 0 && (
                    <Action.T
                      onClick={() =>
                        updateFields({ items: fields.items.filter((_, i) => i !== index) })
                      }
                    >
                      Remove
                    </Action.T>
                  )}
                </div>

                <div className="flex gap-x-2">
                  <Field label="Part Number" className="w-1/3">
                    <TextInput
                      value={item.partNumber}
                      setValue={(partNumber) => updateItemField(index, { partNumber })}
                      required
                      placeholder="ie. ES3897B"
                    />
                  </Field>

                  <Field label="Quantity" className="w-1/3">
                    <QuantityInput
                      required
                      value={item.quantity}
                      setValue={(quantity) => updateItemField(index, { quantity })}
                    />
                  </Field>

                  <Field label="Unit Price" className="w-1/3">
                    <PriceV2
                      required
                      price={item.unitPrice}
                      onChange={(unitPrice) => updateItemField(index, { unitPrice })}
                    />
                  </Field>
                </div>

                <Field label="Part Description">
                  <TextInput
                    required
                    value={item.description}
                    setValue={(description) => updateItemField(index, { description })}
                    placeholder="ie. Air Filter, Fuel Pump"
                  />
                </Field>
              </div>
            ))}

            <div>
              <Action.S
                onClick={() => updateFields({ items: [...fields.items, newItem] })}
                className="flex gap-1 items-center"
              >
                <Plus className="h-5 w-5" /> Add Item
              </Action.S>
            </div>
          </Box>

          <div className="relative">
            <div className="w-80 flex flex-col gap-y-4 sticky top-4">
              <Box className="shadow-base p-6 space-y-4">
                <Field label="Shipping cost (optional)" className="mt-4">
                  <PriceV2
                    price={fields.shippingCost}
                    onChange={(shippingCost) => updateFields({ shippingCost })}
                  />
                </Field>

                <Field label="Sales tax (optional)">
                  <PriceV2
                    price={fields.taxCost}
                    onChange={(taxCost) => updateFields({ taxCost })}
                  />
                </Field>

                <Summary
                  total={totals.total}
                  subtotal={totals.subtotal}
                  discount={totals.discount}
                  discountPercent={null}
                  shippingCost={fields.shippingCost}
                  taxCost={fields.taxCost ?? zeroPrice}
                  customerFee={totals.customerFee}
                  refunded={MoneyM.fromInt(0, 'USD')}
                  showShippingCost
                />
              </Box>

              <Action.P type="submit" className="w-full">
                Save
              </Action.P>
            </div>
          </div>
        </Form>
      </Page>
    </Frame>
  )
}

export default UpdateQuickOrder
