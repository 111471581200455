import { ReactNode } from 'react'
import { Link, To } from 'react-router-dom'
import classNames from 'classnames'

import { SvgIcon } from '@/types'
import uniqueId from 'lodash/uniqueId'

const Card = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div
    className={classNames(
      'flex flex-col bg-white shadow-sm border border-gray-200 rounded-md overflow-hidden',
      className
    )}
  >
    {children}
  </div>
)

const ScorecardContainer = ({
  title,
  SolidIcon,
  to,
  content,
}: {
  title: ReactNode
  SolidIcon?: SvgIcon
  to: To
  content: ReactNode[]
}) => (
  <Link className="group" to={to}>
    <Card>
      <div className="px-4 py-3 lg:px-6 lg:py-4 flex flex-row items-center gap-x-1.5 bg-gray-50">
        {SolidIcon && <SolidIcon className="w-5 h-5 text-gray-600" />}
        <div className="flex flex-row items-baseline gap-x-4">
          <div className="text-gray-900 text-base">{title}</div>
          <span className="text-sm text-blue-500 group-hover:underline">View details</span>
        </div>
      </div>
      <div className="p-4 lg:p-6">
        <div className="flex flex-col lg:flex-row divide-y lg:divide-y-0 lg:divide-x border-gray-300">
          {content.map((element) => (
            <div
              key={uniqueId()}
              className="py-4 first:pt-0 last:pb-0 lg:py-0 first:pl-0 last:pr-0 lg:px-8 flex grow"
            >
              {element}
            </div>
          ))}
        </div>
      </div>
    </Card>
  </Link>
)

ScorecardContainer.Card = Card
export default ScorecardContainer
