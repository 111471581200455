import { useCallback, useRef } from 'react'
import { useAutocompleteModelsLazyQuery } from '../_gen/gql'
import useGqlClient from './useGqlClient'
import debouncePromise from '@/gf/modules/debouncePromise'

type ModelOption = {
  id: string
  value: string
  label: string
}

const useModelAutocomplete = () => {
  const client = useGqlClient()
  const [autocompleteModels] = useAutocompleteModelsLazyQuery({ client })
  const debouncedAutocompleteModels = useRef(debouncePromise(autocompleteModels, 300)).current

  const onAutocompleteModels = useCallback(
    async (prefix: string, make: string): Promise<ModelOption[]> => {
      const result = await debouncedAutocompleteModels({
        variables: {
          search: prefix,
          make,
        },
      })

      if (result === 'skipped') {
        return []
      }

      return (result.data?.autocompleteModels ?? []).map((model) => ({
        id: model,
        value: model,
        label: model,
      }))
    },
    [debouncedAutocompleteModels]
  )

  return onAutocompleteModels
}

export default useModelAutocomplete
