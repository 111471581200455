import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRoutingCodeMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useOrderContext } from './context'
import Modal from '@/gf/components/ModalNext'
import Action from '@/gf/components/Action'
import TextInput from '@/gf/components/next/forms/TextInput'
import Field from '@/gf/components/next/forms/Field'
import CloseModalButton from '@/gf/components/CloseModalButton'
import { ModalSize } from '@/types'

const SetRoutingCode = () => {
  const [_, msgr] = useMsgs()
  const [performing, setPerforming] = useState(false)
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [routingCodeValue, setRoutingCodeValue] = useState(storeOrder.routingCode || '')

  const navigate = useNavigate()
  const [setRoutingCodeMutation] = useSetRoutingCodeMutation({ client: useGqlClient() })
  const close = () => navigate(`/orders/${storeOrder.id}`, { replace: true })

  const onSubmit = (code: string) => {
    setPerforming(true)

    setRoutingCodeMutation({ variables: { storeOrderId: storeOrder.id, code } })
      .then(() => {
        msgr.add('Routing Code updated!', 'positive')

        refetchStoreOrder()
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => {
        close()
        setPerforming(true)
      })
  }

  return (
    <Modal open onClose={close} size={ModalSize.SM}>
      <div className="p-6  relative">
        <CloseModalButton className="absolute top-3 right-3" onClick={close} />
        <h2 className="text-2xl font-medium pb-4">Set Routing Code</h2>

        <form
          onSubmit={(e) => {
            e.preventDefault()

            onSubmit(routingCodeValue)
          }}
          className="space-y-6"
        >
          <Field label="Routing Code">
            <TextInput
              value={routingCodeValue}
              onChange={(e) => setRoutingCodeValue(e.target.value)}
              className="w-40"
            />
          </Field>

          <div className="flex gap-x-4">
            <Action.S onClick={close} className="flex-1">
              Cancel
            </Action.S>
            <Action.P type="submit" color="blue" performing={performing} className="flex-1">
              Save
            </Action.P>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default SetRoutingCode
