/* eslint-disable @typescript-eslint/no-explicit-any */
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import {
  Control,
  Controller,
  DeepPartialSkipArrayKey,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/solid'
import Action from '@/gf/components/Action'
import JobSelector from '@/buyers/components/JobSelector'
import { FormValues, Part } from '../PartDetailsStep'

const JobQuantitiesForm = ({
  control,
  partIndex,
  errors,
  part,
  changePartQuantity,
}: {
  control: Control<FormValues, any>
  partIndex: number
  errors: NonNullable<UseFormReturn<FormValues, any>['formState']['errors']['parts']>[number]
  part: DeepPartialSkipArrayKey<Part>
  changePartQuantity: (newQty: number) => void
}) => {
  const jobQuantitiesField = useFieldArray({
    control: control,
    name: `parts.${partIndex}.jobQuantities`,
  })

  return (
    <>
      {jobQuantitiesField.fields.map((field, index) => (
        <div key={field.id} className="flex gap-x-4">
          <Field
            label="Job Number"
            error={
              errors?.jobQuantities?.[index]?.jobId?.message ??
              errors?.jobQuantities?.[index]?.quantity?.message
            }
            htmlFor={`costCode${index}`}
            className="flex-grow"
          >
            <Controller
              control={control}
              name={`parts.${partIndex}.jobQuantities.${index}.jobId`}
              render={({ field }) => <JobSelector value={field.value} onChange={field.onChange} />}
            />
          </Field>

          <Field label="Quantity" className="w-16 flex-shrink-0">
            <Controller
              control={control}
              name={`parts.${partIndex}.jobQuantities.${index}.quantity`}
              render={({ field }) => (
                <TextInput
                  type="number"
                  min="1"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    const jobQuantity = parseInt(e.target.value, 10)

                    if (Number.isInteger(jobQuantity)) {
                      const newQuantity =
                        part.jobQuantities!.reduce((sum, jq, currIndex) => {
                          return (
                            sum +
                            (index === currIndex
                              ? jobQuantity
                              : jq.quantity
                                ? parseInt(jq.quantity + '', 10)
                                : 0)
                          )
                        }, 0) ?? 0

                      changePartQuantity(newQuantity)
                    }
                  }}
                />
              )}
            />
          </Field>
          <Action.T
            className="mt-6 h-8 text-sm"
            onClick={() => {
              jobQuantitiesField.remove(index)

              const newQuantity = part.jobQuantities!.reduce(
                (sum, jq, currIndex) =>
                  sum +
                  (index === currIndex ? 0 : jq.quantity ? parseInt(jq.quantity + '', 10) : 0),
                0
              )

              changePartQuantity(newQuantity)
            }}
          >
            Remove
          </Action.T>
        </div>
      ))}
      <Action.S
        onClick={() => {
          jobQuantitiesField.append({
            jobId: '',
            quantity: 1,
          })

          const newQuantity =
            part.jobQuantities!.reduce(
              (sum, jq) => sum + (jq.quantity ? parseInt(jq.quantity + '', 10) : 0),
              0
            ) + 1

          changePartQuantity(newQuantity)
        }}
        size="sm"
        className="flex gap-x-1 items-center"
      >
        <PlusIcon className="h-4 w-4 text-gray-900 inline-block" /> Add Job Number
      </Action.S>
    </>
  )
}

export default JobQuantitiesForm
