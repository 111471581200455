import {
  useSetBranchBillingAddressMutation,
  useSetOrganizationBillingAddressMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import AddressModalForm from '@/gf/components/AddressModalForm'
import YellowAlert from '@/gf/components/YellowAlert'
import useMsgs from '@/gf/hooks/useMsgs'
import { Address, Maybe } from '@/types'
import pick from 'lodash/pick'

const UpdateBillingAddressModal = ({
  open,
  onClose,
  branch,
  address,
  organizationId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  branch: Maybe<{ id: string; name: string }>
  organizationId: string
  address: Address
  onSuccess: () => void
}) => {
  const client = useGqlClient()
  const [_msgs, msgsMgr] = useMsgs()

  const [setOrgBillingAddress, { loading: savingOrg }] = useSetOrganizationBillingAddressMutation({
    client,
  })
  const [setBranchBillingAddress, { loading: savingBranch }] = useSetBranchBillingAddressMutation({
    client,
  })

  const onSubmit = async (updatedAddress: Address) => {
    try {
      const addressInput = {
        ...pick(updatedAddress, [
          'lineOne',
          'lineTwo',
          'companyName',
          'country',
          'city',
          'postalCode',
          'state',
        ]),
        deliverable: null,
        rdi: null,
        point: null,
        firstName: null,
        lastName: null,
      }

      if (branch) {
        await setBranchBillingAddress({
          variables: {
            id: branch.id,
            billingAddress: addressInput,
          },
        })
      } else {
        await setOrgBillingAddress({
          variables: {
            id: organizationId,
            billingAddress: addressInput,
          },
        })
      }

      onSuccess()
      onClose()
    } catch (err) {
      msgsMgr.add('Error updating the billing address. Please contact support.', 'negative')
    }
  }

  return (
    <AddressModalForm
      title={`Change Billing Address${branch ? ` (${branch.name})` : ''}`}
      hideFirstLastName
      initialAddress={address}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonShowSpinner={savingBranch || savingOrg}
      submitButtonDisabled={savingBranch || savingOrg}
      details={
        branch ? (
          <YellowAlert
            title={`This will update the Billing Address of your Branch '${branch.name}'`}
          />
        ) : undefined
      }
    />
  )
}

export default UpdateBillingAddressModal
