import {
  useSelectedMachineQuery,
  PartNumbersQuery,
  usePartDiagramsQuery,
  useQueryDiagramsLazyQuery,
  usePartNumbersLazyQuery,
  useSendSuggestedFeedbackMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import AnimatedFeedback from '@/gf/components/AnimatedFeedback'
import DateInput from '@/gf/components/inputs/Date'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import TextArea from '@/gf/components/next/forms/TextArea'
import TextInput from '@/gf/components/next/forms/TextInput'
import { Maybe } from '@/types'
import LinkButton from '@/gf/components/LinkButtonOld'
import { BeakerIcon, PlusIcon } from '@heroicons/react/solid'
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm, useWatch, FieldPath } from 'react-hook-form'
import * as Yup from 'yup'
import FormSection from './FormSection'
import InventoryFulfillmentMessage from './InventoryFulfillmentMessage'
import MachineSelect from './MachineSelect'
import AddMachineButton from './PartDetailsStep/AddMachineButton'
import BranchSelector from './PartDetailsStep/BranchSelector'
import DuplicatedRfqsWarning from './PartDetailsStep/DuplicatedRfqsWarning'
import PartImages from './PartDetailsStep/PartImages'
import PrioritySelector from './PartDetailsStep/PrioritySelector'
import UploadPartsFileButton from './PartDetailsStep/UploadPartsFileButton'
import StartOverButton from './StartOverButton'
import StickyBottomBar from './StickyBottomBar'
import SuggestedParts from './SuggestedParts'
import {
  Diagram,
  DiagramOutput,
  JobQuantity,
  PartialRequestUpdate,
  Priority,
  Urgency,
} from './types'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import { twMerge } from 'tailwind-merge'
import Time from '@/gf/modules/Time'
import Link from '@/gf/components/Link'
import ViewMachinePartsHistory from './PartDetailsStep/ViewMachinePartsHistory'
import PartDiagramSlideOver from './PartDetailsStep/PartDiagramSlideOver'
import JobQuantitiesForm from './PartDetailsStep/JobQuantitiesForm'

const partRequestSchema = (jobQuantitiesEnabled: boolean) =>
  Yup.object().shape({
    partNumber: Yup.string()
      .trim()
      .when('description', (description, schema: Yup.StringSchema) =>
        !description
          ? schema.required('Part number or Description are required')
          : schema.optional()
      ),
    description: Yup.string().trim().max(255, 'Must be less than 256 characters'),
    quantity: Yup.number()
      .required('Quantity is required')
      .test(
        'matches-job-quantities-sum',
        "Job quantities don't match the part quantity",
        (value, context) => {
          const jobQuantitiesArr = context.parent.jobQuantities
          if (!jobQuantitiesEnabled || !jobQuantitiesArr || jobQuantitiesArr.length === 0) {
            return true
          }

          if (!Number.isInteger(value)) {
            return true
          }

          if (isArray(jobQuantitiesArr)) {
            return value === jobQuantitiesArr.reduce((sum, jq) => sum + (jq.quantity ?? 0), 0)
          }

          return false
        }
      ),
    pictures: Yup.array().of(Yup.string()),
    ...(jobQuantitiesEnabled
      ? {
          jobQuantities: Yup.array().of(
            Yup.object().shape({
              jobId: Yup.string().required('Select the Job Number'),
              quantity: Yup.number().required('Quantity is required').min(1),
            })
          ),
        }
      : {}),
  })

const createValidationSchema = (
  billingCompanyRequired: boolean,
  workOrderNumberRequired: boolean,
  jobQuantities: boolean
) =>
  Yup.object().shape({
    workOrderNumber: workOrderNumberRequired
      ? Yup.string().required('Work order number is required')
      : Yup.string().optional(),
    machineOrgId: Yup.string().when(
      'machineInvolved',
      (machineInvolved, schema: Yup.StringSchema) =>
        machineInvolved
          ? schema.nullable().required('Machine is required')
          : schema.nullable().transform(() => null)
    ),
    machineInvolved: Yup.boolean(),
    billingCompanyId: billingCompanyRequired
      ? Yup.string().nullable().required('Billing Company is required')
      : Yup.string().nullable().optional(),
    urgency: Yup.object().shape({
      priority: Yup.number()
        .oneOf([Priority.HIGH, Priority.MEDIUM, Priority.LOW])
        .required('Select the priority'),
      machineDown: Yup.boolean().required(),
      neededByDate: Yup.string().required(),
    }),
    parts: Yup.array().of(partRequestSchema(jobQuantities)).min(1),
    comments: Yup.string(),
  })

type PreviousStoreOrder = NonNullable<
  NonNullable<PartNumbersQuery['partNumbers']>[number]
>['storeOrders'][number]

type SuggestedPart = {
  probability: number | null
  suggestedFeedback: boolean | null
  suggestedFeedbackDone: boolean | null
  suggestedDiagrams?: Diagram[] | null
  suggestedPartNumber?: string
  suggestedPreviousCount?: number
  suggestedPreviousStoreOrders?: PreviousStoreOrder[]
  suggestedPartDescription?: string | null
}

export type Part = {
  partNumber: string
  description: string
  quantity: number
  externalId: string | null
  taskNumber: string | null
  suggestion: boolean | null
  pictures: string[]
  jobQuantities: JobQuantity[]
  loadingPartNumber?: boolean
  suggestedPartSelected?: boolean
  suggestedParts: SuggestedPart[]
}

export type FormValues = {
  billingCompanyId: Maybe<string>
  workOrderNumber: string
  machineOrgId: Maybe<string>
  machineInvolved: boolean
  urgency: { priority: Priority; machineDown: boolean; neededByDate: DateTime }
  parts: Part[]
  comments: string
}

const emptyCallback = () => undefined

const PartDetailsStep = ({
  reset,
  request,
  updateRequest,
  onSubmit,
  billingCompanyRequired,
  workOrderNumberRequired,
  commentsEnabled = true,
  uploadPartsEnabled = true,
  rfqId,
  submitButtonText,
  filfillFromInventoryMessageEnabled = false,
  onOrgMachineSelected = emptyCallback,
  onAddPartsClicked = emptyCallback,
  onPriorityChanged = emptyCallback,
  orgHasMachines = true,
  jobNumbersEnabled,
}: {
  reset?: () => void
  onSubmit: () => void
  billingCompanyRequired: boolean
  workOrderNumberRequired: boolean
  commentsEnabled?: boolean
  partsPicturesEnabled?: boolean
  uploadPartsEnabled?: boolean
  urgencyEnabled?: boolean
  rfqId?: string
  submitButtonText: string
  filfillFromInventoryMessageEnabled?: boolean
  onOrgMachineSelected?: (orgMachineId: string) => void
  onAddPartsClicked?: () => void
  onPriorityChanged?: (priority: Priority) => void
  orgHasMachines?: boolean
  jobNumbersEnabled: boolean
} & PartialRequestUpdate) => {
  const client = useGqlClient()
  const { featureFlags } = useSession()
  const priorityRef = useRef<HTMLFieldSetElement>(null)
  const [searchingDiagrams, setSearchingDiagrams] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [diagramSearchResult, setDiagramSearchResult] = useState<DiagramOutput>()
  const [availableDiagrams, setAvailableDiagrams] = useState<Diagram[] | undefined | null>(null)
  const form = useForm<FormValues>({
    shouldFocusError: true,
    defaultValues: {
      billingCompanyId: request.billingCompanyId,
      workOrderNumber: request.workOrderNumber ?? '',
      machineOrgId: request.machineOrgId ?? undefined,
      machineInvolved: request.machineInvolved,
      urgency: {
        priority: request.urgency?.priority,
        machineDown: request.urgency?.machineDown ?? false,
        neededByDate: request.urgency?.neededByDate,
      },
      parts:
        request.parts?.map((p) => ({
          partNumber: p.partNumber ?? '',
          description: p.description ?? '',
          pictures: p.pictures ?? [],
          quantity: p.quantity ?? 1,
          externalId: p.externalId,
          taskNumber: p.taskNumber,
          suggestion: p.suggestion,
          jobQuantities: p.jobQuantities ?? [],
        })) ?? [],
      comments: request.comments ?? '',
    },
    resolver: yupResolver(
      createValidationSchema(billingCompanyRequired, workOrderNumberRequired, jobNumbersEnabled)
    ),
  })

  const partsField = useFieldArray({ name: 'parts', control: form.control })
  const formValues = useWatch({ control: form.control })

  useEffect(() => {
    updateRequest({
      billingCompanyId: formValues.billingCompanyId ?? null,
      workOrderNumber: formValues.workOrderNumber,
      machineInvolved: formValues.machineInvolved,
      machineOrgId: formValues.machineOrgId,
      parts: (formValues.parts ?? []).map((p) => ({
        partNumber: p.partNumber ?? null,
        description: p.description ?? null,
        pictures: p.pictures ?? [],
        externalId: p.externalId ?? null,
        taskNumber: p.taskNumber ?? null,
        suggestion: p.suggestion ?? null,
        quantity: p.quantity ? Number(p.quantity) : 1,
        jobQuantities:
          p.jobQuantities?.map((jq) => ({
            jobId: jq.jobId ?? '',
            quantity: jq.quantity ?? 1,
          })) ?? [],
      })),
      comments: formValues.comments,
      urgency: formValues.urgency as Urgency,
    })
  }, [formValues])

  const insertPartNumber = useCallback(
    (partNumber: string | undefined, index: number) => {
      if (featureFlags && featureFlags.predictParts && partNumber) {
        form.setValue(`parts.${index}.suggestion`, true)
        form.setValue(`parts.${index}.suggestedPartSelected`, true)
        form.setValue(`parts.${index}.partNumber`, partNumber)
      }
    },
    [form, featureFlags]
  )

  const [queryDiagrams] = useQueryDiagramsLazyQuery({ client })

  const queryPartDiagrams = useCallback(
    (query: string, make: string | null, model: string | null) => {
      if (query && make && model) {
        setSearchingDiagrams(true)

        if (!searchingDiagrams) {
          queryDiagrams({
            client,
            variables: { make, model, query },
          })
            .then((result) => {
              if (result.data?.queryDiagrams) {
                setDiagramSearchResult(result.data.queryDiagrams)
              } else {
                setDiagramSearchResult(undefined)
              }
            })
            .finally(() => setSearchingDiagrams(false))
        }
      }
    },
    [form]
  )

  const [getPartNumber] = usePartNumbersLazyQuery({ client })

  const updatePartNumber = useCallback(
    (
      description: string,
      make: string,
      model: string | null,
      suggestedPart: boolean | undefined,
      index: number
    ) => {
      if (
        description &&
        description.length >= 3 &&
        !suggestedPart &&
        make &&
        model &&
        /^(cat|john|deere)/i.test(make)
      ) {
        form.setValue(`parts.${index}.loadingPartNumber`, true)
        getPartNumber({
          client,
          variables: { make, model, description },
        })
          .then((result) => {
            if (result.data?.partNumbers?.length) {
              const suggestedParts = result.data?.partNumbers?.map((item) => {
                const previousCount = item?.orderCount || 0
                const prevStoreOrders = item?.storeOrders || []
                const suggestedPartDesc = item?.description
                const diagrams = item?.diagrams

                return {
                  suggestedDiagrams: diagrams,
                  probability: item?.probability,
                  suggestedPartNumber: item?.id,
                  suggestedPreviousCount: previousCount,
                  suggestedPreviousStoreOrders: prevStoreOrders,
                  suggestedPartDescription: suggestedPartDesc,
                }
              }) as SuggestedPart[]
              form.setValue(`parts.${index}.suggestedParts`, suggestedParts)
            } else {
              form.setValue(`parts.${index}.suggestedParts`, [])
            }
          })
          .finally(() => {
            form.setValue(`parts.${index}.loadingPartNumber`, false)
            // form.setValue(`parts.${index}.suggestedFeedback`, false)
            // form.setValue(`parts.${index}.suggestedFeedbackDone`, false)
          })
      } else {
        form.setValue(`parts.${index}.suggestedParts`, [])
      }
    },
    [form]
  )

  const [sendSuggestion] = useSendSuggestedFeedbackMutation({ client })
  const onDescriptionChange = useCallback(debounce(updatePartNumber, 400), [updatePartNumber])

  const { data: machineData, loading: loadingMachine } = useSelectedMachineQuery({
    client,
    variables: { id: formValues.machineOrgId as string },
    skip: !formValues.machineOrgId,
  })

  const machine = machineData?.orgMachine
  const machineSelected = !!formValues.machineOrgId || !formValues.machineInvolved
  const machineMake = machine?.machine.make
  const machineModel = machine?.machine.model
  const catMachine = machine && machineMake && /^cat/i.test(machineMake)

  const { data: diagramData } = usePartDiagramsQuery({
    client,
    variables: { make: machineMake || '', model: machineModel || '' },
    skip:
      !featureFlags.partDiagrams &&
      !formValues.machineOrgId &&
      !machineModel &&
      !machineMake &&
      !catMachine,
  })
  const partDiagrams = diagramData?.partDiagrams || []

  const parts = useWatch({ control: form.control, name: 'parts' })
  const { partNumbers, suggestedPartsOrderWithText } = useMemo(() => {
    const filteredPartNumbers = parts.map((p) => p.partNumber).filter((s) => s && s.trim())
    const partNumberText = parts.map((p) => p.description).join(', ')
    return { partNumbers: filteredPartNumbers, suggestedPartsOrderWithText: partNumberText }
  }, [parts])

  const feedback = useCallback(
    (index, itemIndex, query, desc, mpn, feedbackBool) => {
      if (desc && mpn) {
        const suggestedMake = machine?.machine.make
        const suggestedModel = machine?.machine.model
        form.setValue(
          `parts.${index}.suggestedParts.${itemIndex}.suggestedFeedback` as FieldPath<FormValues>,
          true
        )
        sendSuggestion({
          client,
          variables: {
            query,
            description: desc,
            partNumber: mpn,
            vote: feedbackBool,
            make: suggestedMake || null,
            model: suggestedModel || null,
          },
        })
      }
    },
    [form, featureFlags, machine]
  )

  const addPart = (partNumber: string, description: string) => {
    partsField.append({
      suggestedParts: [],
      suggestedPartSelected: true,
      partNumber,
      description,
      quantity: 1,
      externalId: null,
      taskNumber: null,
      suggestion: true,
      pictures: [],
      jobQuantities: [],
    })
  }

  return (
    <>
      <PartDiagramSlideOver
        availableDiagrams={availableDiagrams}
        setAvailableDiagrams={setAvailableDiagrams}
        onAddPart={(partNumber, description) => {
          const partToAdd = {
            suggestedParts: [],
            suggestedPartSelected: true,
            partNumber,
            description,
            quantity: 1,
            externalId: null,
            taskNumber: null,
            suggestion: true,
            pictures: [],
            jobQuantities: [],
          }
          const parts = formValues.parts ?? []
          const suggestionIndex = parts.findIndex((p) =>
            p.suggestedParts?.findIndex((su) => su.suggestedPartNumber === partNumber)
          )
          if (suggestionIndex > -1) {
            partsField.update(suggestionIndex, partToAdd)
          } else {
            partsField.insert(parts.length, partToAdd)
          }
        }}
        addedPartNumbers={
          formValues.parts?.map((p) => p.partNumber ?? '').filter((pn) => pn !== '') ?? []
        }
      />
      <form
        onSubmit={form.handleSubmit(onSubmit, (errors) => {
          // Only scroll to urgency if there are no other errors (since it's at the end of the form)
          if (errors.urgency && Object.keys(errors).length === 1) {
            priorityRef.current?.scrollIntoView()
          }
        })}
        className="space-y-4"
      >
        <FormSection contentClassName="w-full flex flex-col">
          <div className="max-w-lg">
            {workOrderNumberRequired && (
              <Field
                label="Work order number"
                error={form.formState.errors.workOrderNumber?.message}
              >
                <TextInput {...form.register('workOrderNumber')} />
              </Field>
            )}

            {billingCompanyRequired && (
              <Controller
                control={form.control}
                name="billingCompanyId"
                render={({ field }) => (
                  <Field
                    label="What billing company?"
                    error={form.formState.errors.billingCompanyId?.message ?? null}
                    className="mt-4"
                  >
                    <BranchSelector value={field.value} onChange={field.onChange} />
                  </Field>
                )}
              />
            )}
          </div>

          <div className="w-full flex flex-col md:flex-row md:space-x-4 lg:space-x-12">
            <div className="flex-1 space-y-4 max-w-lg">
              <Field
                label="What machine do you need the part for?"
                className={workOrderNumberRequired ? 'mt-6' : undefined}
                error={form.formState.errors.machineOrgId?.message}
              >
                {!formValues.machineOrgId ? (
                  <>
                    {formValues.machineInvolved && (
                      <Controller
                        name="machineOrgId"
                        control={form.control}
                        render={({ field }) => (
                          <div className="flex gap-x-3">
                            {orgHasMachines && (
                              <MachineSelect
                                value={field.value}
                                onChange={(value) => {
                                  if (value != null) {
                                    onOrgMachineSelected(value)
                                  }

                                  field.onChange(value)
                                }}
                                id="machine"
                              />
                            )}

                            <AddMachineButton
                              onMachineAdded={field.onChange}
                              orgHasMachines={orgHasMachines}
                            />
                          </div>
                        )}
                      />
                    )}

                    <Controller
                      name="machineInvolved"
                      control={form.control}
                      render={({ field }) => (
                        <label className="flex items-center gap-x-2 py-2 text-sm">
                          <Checkbox
                            checked={!field.value}
                            onChange={(e) => field.onChange(!e.target.checked)}
                          />
                          No machine involved
                        </label>
                      )}
                    />
                  </>
                ) : loadingMachine ? (
                  <p>loading...</p>
                ) : (
                  <>
                    <div className="flex items-start text-sm gap-x-2">
                      <div className="flex-grow">
                        <span className="block font-medium leading-5">{machine?.name}</span>
                        <p className="text-gray-600 flex flex-col leading-5">
                          <span>Serial Number: {machine?.serialNumber}</span>
                          <span>Make: {machine?.machine.make}</span>
                          <span>Model: {machine?.machine.model}</span>
                          <span>Year: {machine?.machine.year}</span>
                          {machine?.description && <span>{machine?.description}</span>}
                        </p>
                      </div>
                      <Action.T
                        onClick={() => {
                          setSearchQuery('')
                          setDiagramSearchResult(undefined)
                          form.setValue('machineOrgId', null)
                          form.getValues('parts').forEach((_, index) => {
                            form.setValue(`parts.${index}.suggestedParts`, [])
                          })
                        }}
                        className="font-medium flex-shrink-0"
                      >
                        Change
                      </Action.T>
                    </div>

                    <ViewMachinePartsHistory
                      orgMachineId={formValues.machineOrgId}
                      onAddPart={(partNumber, description) => {
                        const partToAdd = {
                          suggestedParts: [],
                          suggestedPartSelected: false,
                          partNumber,
                          description,
                          quantity: 1,
                          externalId: null,
                          taskNumber: null,
                          suggestion: false,
                          pictures: [],
                          jobQuantities: [],
                        }
                        const parts = formValues.parts ?? []
                        if (
                          parts.length === 1 &&
                          (parts[0].partNumber?.trim() === '' ||
                            parts[0].description?.trim() === '')
                        ) {
                          partsField.update(0, partToAdd)
                        } else {
                          partsField.insert(parts.length, partToAdd)
                        }
                      }}
                      addedPartNumbers={
                        formValues.parts
                          ?.map((p) => p.partNumber ?? '')
                          .filter((pn) => pn !== '') ?? []
                      }
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="flex-1 md:block">
              {featureFlags.partDiagrams &&
                machineSelected &&
                machine?.machine.make &&
                partDiagrams.length > 0 &&
                /^cat/i.test(machine.machine.make) && (
                  <div className="max-w-lg py-3 px-4 rounded-lg bg-white border border-gray-200 shadow-base">
                    <div className="flex items-center justify-between gap-x-2">
                      <div className="min-w-0 flex-1">
                        <h3 className="flex-1 items-center capitalize text-xl font-medium leading-6 text-gray-900 truncate">
                          Parts Diagrams for {machineModel?.split(' ')[0]}
                        </h3>
                      </div>
                      <div className="hidden px-2 py-1 bg-gray-100 border border-gray-200 text-xs text-gray-600 font-medium rounded justify-end">
                        <span className="capitalize">upload</span>
                      </div>
                    </div>

                    <div className="pt-0.5 flex items-center capitalize text-gray-600 leading-5 text-lg">
                      <LinkButton onClick={() => setAvailableDiagrams(partDiagrams)}>
                        {partDiagrams.length} diagrams
                      </LinkButton>
                    </div>

                    <div className="pt-4 flex flex-row items-end gap-x-2">
                      <div className="flex flex-col grow">
                        <input
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md w-full"
                          placeholder="Ask a question to search diagrams"
                          type="text"
                        />
                      </div>
                      <div className="ml-1">
                        <Action.S
                          performing={searchingDiagrams}
                          onClick={() =>
                            queryPartDiagrams(
                              searchQuery,
                              machine?.machine.make,
                              machine?.machine.model
                            )
                          }
                        >
                          Search
                        </Action.S>
                      </div>
                    </div>

                    {diagramSearchResult && (
                      <div className="pt-2 flex items-center text-gray-600 leading-5 text-base">
                        <div className="flex items-center justify-between gap-x-2">
                          <div className="text-base min-w-0 flex-1">
                            {diagramSearchResult.output}
                          </div>
                        </div>
                      </div>
                    )}
                    {diagramSearchResult && diagramSearchResult.diagrams.length > 0 && (
                      <div className="pt-0.5 flex items-center capitalize text-gray-600 leading-5 text-lg">
                        <LinkButton
                          className="text-base"
                          onClick={() => setAvailableDiagrams(diagramSearchResult.diagrams)}
                        >
                          We found {diagramSearchResult.diagrams.length} diagrams that match
                        </LinkButton>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </FormSection>

        <FormSection disabled={!machineSelected}>
          <div className="w-full">
            {filfillFromInventoryMessageEnabled &&
            formValues.parts &&
            rfqId &&
            formValues.parts?.filter((p) => !!p.partNumber).length > 0 ? (
              <InventoryFulfillmentMessage rfqId={rfqId} parts={formValues.parts as Part[]} />
            ) : null}

            <DuplicatedRfqsWarning
              partNumbers={(formValues?.parts ?? []).map((p) => p.partNumber ?? '')}
              machineOrgId={formValues.machineOrgId ?? null}
              ignoreRfqIds={rfqId ? [rfqId] : []}
            />

            {partsField.fields.map((field, index) => (
              <div
                className="w-full border-b border-gray-300 mb-6 pb-6 space-y-4 flex flex-col md:flex-row md:space-x-4 lg:space-x-12"
                key={field.id}
              >
                <div className="flex-1 space-y-4 max-w-lg">
                  <div className="flex items-start">
                    {index === 0 && (
                      <div className="flex-grow">
                        <h5 className="leading-6">What part do you need?</h5>

                        <p className="text-sm text-gray-600 mt-2">
                          If you do not have the part number, provide a detailed description so that
                          vendors can assist in identifying it.
                        </p>
                      </div>
                    )}

                    {index === 0 ? (
                      uploadPartsEnabled && (
                        <UploadPartsFileButton
                          disabled={!machineSelected}
                          onFileUploaded={(uploadedParts) => partsField.replace(uploadedParts)}
                        />
                      )
                    ) : (
                      <Action.T
                        className="flex-shrink-0 text-sm"
                        onClick={() => partsField.remove(index)}
                      >
                        Remove
                      </Action.T>
                    )}
                  </div>

                  <div className="flex gap-x-3">
                    <Field
                      label="Part Description"
                      className="flex-grow"
                      error={
                        form.formState.errors.parts?.[index]?.description?.message ||
                        form.formState.errors.parts?.[index]?.quantity?.message
                      }
                    >
                      <TextInput
                        {...form.register(`parts.${index}.description`)}
                        onChange={
                          featureFlags.predictParts
                            ? (e) => {
                                if (machine?.machine.make) {
                                  onDescriptionChange(
                                    e.target.value,
                                    machine?.machine.make,
                                    machine?.machine.model,
                                    parts[index]?.suggestedPartSelected,
                                    index
                                  )
                                }
                              }
                            : undefined
                        }
                        disabled={!machineSelected}
                      />
                    </Field>

                    <Field label="Quantity" className="w-16 flex-shrink-0">
                      <TextInput
                        type="number"
                        min="0"
                        {...form.register(`parts.${index}.quantity`)}
                        disabled={!machineSelected}
                      />
                    </Field>
                  </div>

                  <Field
                    label="Part Number (optional)"
                    error={form.formState.errors.parts?.[index]?.partNumber?.message}
                    htmlFor={`partNumber${index}`}
                  >
                    <TextInput
                      {...form.register(`parts.${index}.partNumber`)}
                      disabled={!machineSelected}
                      id={`partNumber${index}`}
                    />
                  </Field>

                  {jobNumbersEnabled && formValues.parts?.[index] && (
                    <JobQuantitiesForm
                      control={form.control}
                      errors={form.formState.errors.parts?.[index]}
                      partIndex={index}
                      part={formValues.parts[index]}
                      changePartQuantity={(quantity) =>
                        form.setValue(`parts.${index}.quantity`, quantity)
                      }
                    />
                  )}

                  <Controller
                    name={`parts.${index}.pictures`}
                    control={form.control}
                    render={({ field: pictureField }) => (
                      <PartImages
                        value={formValues.parts?.[index]?.pictures ?? []}
                        desc={
                          index === 0 && (
                            <p className="text-gray-600 mt-1">
                              Pictures can reduce processing time and help verify fitment with your
                              machine.
                            </p>
                          )
                        }
                        onChange={pictureField.onChange}
                        addDisabled={!machineSelected}
                      />
                    )}
                  />
                </div>
                <div
                  className={twMerge(
                    'space-y-2 flex-1 md:block',
                    index === 0 && parts[index]?.suggestedParts?.length > 2 && 'pt-0',
                    index === 0 &&
                      parts[index]?.suggestedParts?.length === 2 &&
                      'pt-0 md:pt-12 xl:pt-8',
                    index === 0 &&
                      parts[index]?.suggestedParts?.length < 2 &&
                      'pt-0 md:pt-28 xl:pt-24'
                  )}
                >
                  {featureFlags.predictParts &&
                    !parts[index]?.loadingPartNumber &&
                    !parts[index]?.suggestedPartSelected &&
                    parts[index]?.suggestedParts?.map((item, itemIndex) => (
                      <div
                        key={item.suggestedPartNumber}
                        className="max-w-lg py-3 px-4 rounded-lg bg-white border border-gray-200 shadow-base"
                      >
                        <div className="flex items-center justify-between gap-x-2">
                          <div className="min-w-0 flex-1">
                            <h3 className="flex-1 items-center capitalize text-xl font-medium leading-6 text-gray-900 truncate">
                              {item.suggestedPartDescription}
                            </h3>
                          </div>
                        </div>

                        <div className="pt-0.5 flex items-center capitalize text-gray-600 leading-5 text-lg">
                          {item.suggestedPartNumber}
                        </div>

                        {(item.suggestedPreviousCount || 0) > 0 && (
                          <div className="mt-3 flex flex-col items-start text-sm text-gray-900">
                            <span className="font-medium">
                              Previous orders ({item.suggestedPreviousCount})
                            </span>
                            {(item.suggestedPreviousStoreOrders ?? []).map((storeOrder) => (
                              <div className="flex gap-x-1 items-center">
                                <Link.T
                                  className="text-sm"
                                  to={`/orders/${storeOrder.id}`}
                                  target="_blank"
                                >
                                  {Time.formatDateWithDaySmartYear(storeOrder.insertedAt)}
                                </Link.T>
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="pt-2 flex flex-wrap gap-2 w-full justify-between items-end">
                          <div className="flex items-center gap-x-2">
                            <SimpleTooltip text="This feature is still under active development. Please verify this part number.">
                              <div className="px-2 py-1 flex items-center gap-x-1 text-xs font-medium rounded bg-orange-100 border border-orange-200 text-orange-500">
                                <BeakerIcon className="w-4 h-4" />
                                Suggestion
                              </div>
                            </SimpleTooltip>
                            {!item.suggestedFeedback ? (
                              <div className="space-x-1 text-xs text-gray-400">
                                <button
                                  type="button"
                                  onClick={() =>
                                    feedback(
                                      index,
                                      itemIndex,
                                      parts[index].description,
                                      item.suggestedPartDescription,
                                      item.suggestedPartNumber,
                                      true
                                    )
                                  }
                                >
                                  <ThumbUpIcon className="h-5 inline-block" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    feedback(
                                      index,
                                      itemIndex,
                                      parts[index].description,
                                      item.suggestedPartDescription,
                                      item.suggestedPartNumber,
                                      false
                                    )
                                  }
                                >
                                  <ThumbDownIcon className="h-5 inline-block" />
                                </button>
                              </div>
                            ) : (
                              <AnimatedFeedback
                                done={() =>
                                  form.setValue(
                                    `items.${index}.parts.${itemIndex}.suggestedFeedbackDone` as FieldPath<FormValues>,
                                    true
                                  )
                                }
                                show={!item.suggestedFeedbackDone}
                              />
                            )}
                          </div>

                          <div className="space-x-2">
                            <>
                              {item.suggestedDiagrams && item.suggestedDiagrams?.length > 0 && (
                                <button
                                  onClick={() => {
                                    setAvailableDiagrams(item.suggestedDiagrams)
                                  }}
                                  type="button"
                                  className="inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-gray-700 border-gray-300 bg-white focus:ring-yellow-500 hover:bg-gray-50 px-4 py-2 text-sm"
                                >
                                  Diagram
                                </button>
                              )}
                              <button
                                onClick={() => insertPartNumber(item.suggestedPartNumber, index)}
                                type="button"
                                className="inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-white border-transparent bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 px-4 py-2 text-sm flex-shrink-0 w-[4.5rem] h-9"
                              >
                                Select
                              </button>
                            </>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>

          {featureFlags.predictParts &&
            machine?.machine.make &&
            /^(cat|john|deere)/i.test(machine.machine.make) && (
              <SuggestedParts
                make={machine.machine.make}
                partNumbers={partNumbers}
                addPart={addPart}
                orderedWithText={suggestedPartsOrderWithText}
              />
            )}

          <Action.S
            className="font-medium text-sm flex items-center"
            onClick={() => {
              partsField.append({
                suggestedParts: [],
                partNumber: '',
                description: '',
                quantity: 1,
                externalId: null,
                taskNumber: null,
                suggestion: null,
                pictures: [],
                jobQuantities: [],
              })

              onAddPartsClicked()
            }}
            disabled={!machineSelected}
          >
            <PlusIcon className="mr-2 h-4 inline-block" /> Add Another Part
          </Action.S>
        </FormSection>

        <FormSection disabled={!machineSelected} contentClassName="max-w-lg">
          <h5 className="leading-6">What is your urgency on getting your parts?</h5>
          <p className="text-sm text-gray-600 mt-2">
            This helps vendors prioritize your request and provide accurate quotes.
          </p>

          <Controller
            name="urgency"
            control={form.control}
            render={({ field, formState }) => (
              <div className="text-sm mt-4">
                <fieldset ref={priorityRef}>
                  <PrioritySelector
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)

                      if (value.priority) {
                        onPriorityChanged(value.priority)
                      }
                    }}
                    disabled={!machineSelected}
                  />
                </fieldset>

                <FieldError
                  error={
                    formState.errors.urgency?.priority?.message ??
                    formState.errors.urgency?.neededByDate?.message
                  }
                />

                {field.value.priority && (
                  <Field label="Select Date" className="mt-4">
                    <DateInput
                      value={field.value.neededByDate}
                      onChange={(value) => field.onChange({ ...field.value, neededByDate: value })}
                      className="max-w-44"
                      disabled={!machineSelected}
                      minDate={DateTime.now()}
                    />
                  </Field>
                )}
              </div>
            )}
          />

          {commentsEnabled && (
            <>
              <hr className="border-gray-300 my-6" />

              <Field label="Comments (optional)" error={form.formState.errors.comments?.message}>
                <p className="text-gray-600 text-sm">
                  Add comments that would help vendors better meet your needs i.e. additional
                  fitment details, refurbished options requested, etc.
                </p>
                <TextArea {...form.register('comments')} disabled={!machineSelected} />
              </Field>
            </>
          )}
        </FormSection>

        <StickyBottomBar>
          {reset && <StartOverButton reset={reset} />}

          <div className="flex-grow" />

          <Action.P color="blue" type="submit">
            {submitButtonText}
          </Action.P>
        </StickyBottomBar>
      </form>
    </>
  )
}

export default PartDetailsStep
