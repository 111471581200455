import { Controller, useForm } from 'react-hook-form'
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { InternalRefetchQueriesInclude } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Transition } from '@headlessui/react'
import useMsgs from '@/gf/hooks/useMsgs'
import TextInput from '@/gf/components/next/forms/TextInput'
import Field from '@/gf/components/next/forms/Field'
import TextArea from '@/gf/components/next/forms/TextArea'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import { ModalSize } from '../../types'
import useToggle from '@/gf/hooks/useToggle'
import Typeahead from '@/gf/components/next/Typeahead'
import useMakeAutocomplete from '../hooks/useMakeAutocomplete'
import useModelAutocomplete from '../hooks/useModelAutocomplete'
import { AddMachineToOrgMutationResult, useAddMachineToOrgMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import CloseModalButton from '@/gf/components/CloseModalButton'

// Helper function from this yup issue thread: https://github.com/jquense/yup/issues/298
const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') return null

  return value
}

type AccountMachineForm = {
  name: string
  serialNumber: string
  make: string
  model: string
  year?: number
  description?: string
  owned: boolean
}

const accountMachineSchema = yup.object({
  name: yup.string().required().label('Unit #'),
  serialNumber: yup.string().nullable().optional().label('Serial Number'),
  make: yup.string().required().label('Make'),
  model: yup.string().required().label('Model'),
  year: yup.number().nullable().transform(emptyStringToNull).label('Year'),
  engineMake: yup.string().optional().label('Engine Make'),
  engineModel: yup.string().optional().label('Engine Model'),
  engineSerialNumber: yup.string().optional().label('Engine Serial Number'),
  description: yup.string().nullable().label('Description'),
  owned: yup.boolean().label('Owned'),
})

const AddAccountMachineModal = ({
  open,
  onClose,
  onAdd,
  accountId,
  refetchQueries,
}: {
  open: boolean
  onClose: () => void
  onAdd?: (machine: NonNullable<AddMachineToOrgMutationResult['data']>['addMachineToOrg']) => void
  accountId: string
  refetchQueries?: InternalRefetchQueriesInclude
}) => {
  const client = useGqlClient()
  const [_msgs, msgsMgr] = useMsgs()
  const [detailsOpen, { toggle: toggleDetails }] = useToggle(false)
  const [addMachineToOrg] = useAddMachineToOrgMutation({ client, refetchQueries })
  const autocompleteMakes = useMakeAutocomplete()
  const autocompleteModels = useModelAutocomplete()

  const accountMachineForm = useForm<AccountMachineForm>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      serialNumber: '',
      make: '',
      model: '',
      description: '',
      owned: true,
    },
    resolver: yupResolver(accountMachineSchema),
  })

  const onSubmit = accountMachineForm.handleSubmit((formData, event) => {
    event?.preventDefault()
    event?.stopPropagation()
    msgsMgr.clear()

    addMachineToOrg({
      variables: {
        orgId: accountId,
        name: formData.name,
        serialNumber: formData.serialNumber,
        description: formData.description ?? null,
        owned: formData.owned,
        engineMake: null,
        engineModel: null,
        engineSerialNumber: null,
        machine: {
          make: formData.make,
          model: formData.model,
          year: formData.year ?? null,
        },
      },
    })
      .then((result) => {
        accountMachineForm.reset()
        if (onAdd && result.data) onAdd(result.data.addMachineToOrg)
        if (!result.errors) onClose()
      })
      .catch((err: GraphQLError) => {
        msgsMgr.add(err.message || 'Failed Adding Machine', 'negative')
      })
  })

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.MD}>
      <form onSubmit={onSubmit} className="p-6 relative">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <hgroup className="space-y-1">
          <h2 className="text-2xl font-medium">Add Machine</h2>
          <p className="text-base">
            Input the machine make, model and manufactured year to help vendors find the correct
            parts for your machine.
          </p>
        </hgroup>
        <div className="space-y-4 w-full max-w-screen-lg pt-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Controller
              control={accountMachineForm.control}
              name="make"
              render={({ field }) => (
                <Field
                  label="Make"
                  error={accountMachineForm.formState.errors.make?.message}
                  htmlFor="make"
                >
                  <Typeahead
                    inputRef={field.ref}
                    value={field.value}
                    onChange={(make) => {
                      field.onChange(make)
                      accountMachineForm.setValue('model', '')
                    }}
                    onFetchOptions={autocompleteMakes}
                    placement="bottom-start"
                    id="make"
                    menuWidth={350}
                    menuHeight={400}
                    menuTitle="Makes"
                    menuOpenOnClick
                    fetchWithEmptyPrefix
                    renderOption={(option) => <div>{option.label}</div>}
                    transformSelection={(option) => option.value}
                  />
                </Field>
              )}
            />

            <Controller
              control={accountMachineForm.control}
              name="model"
              render={({ field }) => (
                <Field
                  label="Model"
                  error={accountMachineForm.formState.errors.model?.message}
                  htmlFor="model"
                >
                  <Typeahead
                    inputRef={field.ref}
                    value={field.value}
                    onChange={(model) => field.onChange(model)}
                    onFetchOptions={(prefix) =>
                      autocompleteModels(prefix, accountMachineForm.getValues('make'))
                    }
                    placement="bottom-start"
                    id="model"
                    menuWidth={350}
                    menuHeight={400}
                    menuTitle="Models"
                    menuOpenOnClick
                    fetchWithEmptyPrefix
                    renderOption={(option) => <div>{option.label}</div>}
                    transformSelection={(option) => option.value}
                  />
                </Field>
              )}
            />

            <Controller
              control={accountMachineForm.control}
              name="year"
              render={({ field }) => (
                <Field
                  label="Year"
                  error={accountMachineForm.formState.errors.year?.message}
                  htmlFor="year"
                >
                  <TextInput
                    ref={field.ref}
                    value={field.value ?? ''}
                    onChange={(e) => field.onChange(e.target.value)}
                    id="year"
                    type="number"
                  />
                </Field>
              )}
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Field
              label="Serial Number (optional)"
              error={accountMachineForm.formState.errors.serialNumber?.message}
              htmlFor="serialNumber"
            >
              <TextInput {...accountMachineForm.register('serialNumber')} id="serialNumber" />
            </Field>

            <Field
              label="Unit #"
              error={accountMachineForm.formState.errors.name?.message}
              htmlFor="name"
            >
              <TextInput
                {...accountMachineForm.register('name')}
                id="name"
                placeholder="Unique identifier within your fleet"
              />
            </Field>
          </div>

          <>
            <div
              className="flex gap-x-2 py-2 mb-1 hover:bg-slate-50 px-1 cursor-pointer border-b"
              onClick={toggleDetails}
            >
              <h4 className="font-medium flex items-center flex-grow gap-x-2 text-sm">
                Additional Details
                <span className="font-normal">(optional)</span>
              </h4>

              {detailsOpen ? (
                <MinusIcon className="h-6 text-gearflow" />
              ) : (
                <PlusIcon className="h-6 text-gearflow" />
              )}
            </div>

            <Transition
              as="div"
              show={detailsOpen}
              enter="transform transition duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="space-y-4 pt-2 pb-4 origin-top"
            >
              <Field label="Description">
                <TextArea {...accountMachineForm.register('description')} />
              </Field>

              <Field>
                <label className="flex gap-x-2 items-center">
                  <Checkbox {...accountMachineForm.register('owned')} />
                  My organization owns this machine.
                </label>
              </Field>
            </Transition>
          </>
        </div>
        <div className="flex justify-end gap-x-4 mt-6">
          <Action.S onClick={onClose}>Cancel</Action.S>
          <Action.P type="submit" color="blue" className="min-w-48">
            Save
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default AddAccountMachineModal
