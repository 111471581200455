import { useCallback, useState } from 'react'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import { Job, usePaginatedJobsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import usePage from '@/gf/hooks/usePage'
import { Table, Td, Th, Tr } from '@/gf/components/next/Table'
import debounce from 'lodash/debounce'
import Action from '@/gf/components/Action'
import Ghost from '@/gf/components/Ghost'
import useModal from '@/gf/hooks/useModal'
import { Maybe } from '@/types'
import EditJobNumberModal from './EditJobNumberModal'
import Box from '@/gf/components/Box'
import Page from '@/gf/components/Page'
import Frame from '@/buyers/components/Frame'

const Loading = () => (
  <>
    {[1, 2, 3, 4, 5, 6, 7, 8].map((a) => (
      <Tr key={a}>
        <Td className="w-20">
          <Ghost className="h-4 w-1/2 block" />
        </Td>
        <Td>
          <Ghost className="h-4 w-3/4 block" />
        </Td>
        <Td>
          <Ghost className="h-4 w-10 block" />
        </Td>
      </Tr>
    ))}
  </>
)
const JobsTable = () => {
  const client = useGqlClient()
  const [page, setPage] = usePage()
  const [searchTerm, setSearchTerm] = useState('')
  const jobModal = useModal<{ job: Maybe<Job> }>()
  const { data, previousData } = usePaginatedJobsQuery({
    client,
    variables: {
      page,
      searchTerm,
    },
  })

  const onSearchChanged = useCallback(
    debounce((text: string) => {
      setSearchTerm(text)
      setPage(1)
    }, 300),
    []
  )

  return (
    <Frame
      breadcrumbs={{
        copy: '',
        crumbs: [
          { name: 'Settings', href: '/settings' },
          { name: 'Organization', href: '/settings/organization' },
          { name: 'Job Numbers', href: '/settings/organization/job-numbers' },
        ],
      }}
    >
      <Page
        title="Job Numbers"
        actionsNext={[<Action.P onClick={() => jobModal.open({ job: null })}>Add Job</Action.P>]}
        previousPage={'/settings/organization'}
      >
        <Box className="p-6 mt-6">
          {jobModal.isOpen && (
            <EditJobNumberModal open onClose={jobModal.close} job={jobModal.props?.job ?? null} />
          )}

          <div className="space-y-4">
            <div>
              <SearchInput
                value={searchTerm}
                onChange={onSearchChanged}
                placeholder="Search by job code or name"
                autoFocus
                autoCompleteOff
              />
            </div>

            <Table className="text-sm">
              <thead>
                <Tr>
                  <Th>Code</Th>
                  <Th>Name</Th>
                  <Th className="w-24" />
                </Tr>
              </thead>
              <tbody>
                {!data ? (
                  <Loading />
                ) : (
                  <>
                    {data?.jobs.entries.map((j) => (
                      <Tr key={j.id}>
                        <Td>{j.code}</Td>
                        <Td>{j.name} </Td>

                        <Td>
                          <Action.T
                            onClick={() => jobModal.open({ job: j })}
                            className="text-sm font-medium no-underline cursor-pointer"
                          >
                            Edit
                          </Action.T>
                        </Td>
                      </Tr>
                    ))}
                    {data.jobs.entries.length === 0 && searchTerm && (
                      <Tr>
                        <Td className="text-gray-700" colSpan={4}>
                          No results for{' '}
                          <span className="text-gray-900 font-medium">{searchTerm}</span>
                        </Td>
                      </Tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>

            <PaginationC
              page={page}
              pagination={(data ?? previousData)?.jobs.pagination}
              updatePage={setPage}
            />
          </div>
        </Box>
      </Page>
    </Frame>
  )
}

export default JobsTable
