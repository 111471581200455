import { useEffect, useRef, useState } from 'react'

import SlideOver from '@/gf/components/SlideOver'
import Action from '@/gf/components/Action'
import { Table, Td, Th, Tr } from '@/gf/components/next/Table'
import FilterDropdown from '@/gf/components/FilterDropdown'
import { CheckIcon } from '@heroicons/react/solid'
import { Diagram } from '../types'

const sortAlphanumeric = (a, b) => {
  const aMatch = a.order.match(/(\d+)(\w*)/)
  const bMatch = b.order.match(/(\d+)(\w*)/)

  const [, aNum, aLetter] = aMatch
  const [, bNum, bLetter] = bMatch

  if (aNum !== bNum) {
    return parseInt(aNum) - parseInt(bNum)
  }

  return aLetter.localeCompare(bLetter)
}
async function fetchSvgContent(svgUrl: string, setState: (data: string | null) => void) {
  const apiUrl = `/svg/extract?url=${encodeURIComponent(svgUrl)}`

  const response = await fetch(apiUrl)

  if (response.status === 200 && response.headers.get('Content-Type')?.includes('image/svg+xml')) {
    const data = await response.text()
    setState(data)
  } else {
    setState(null)
  }
}

type Position = { x: number; y: number }

const PartDiagramSlideOver = ({
  availableDiagrams,
  setAvailableDiagrams,
  onAddPart,
  addedPartNumbers,
}: {
  availableDiagrams: Diagram[] | null | undefined
  setAvailableDiagrams: (diagrams: Diagram[] | null | undefined) => void
  onAddPart: (partNumber: string, description: string) => void
  addedPartNumbers: string[]
}) => {
  if (!availableDiagrams) return null

  const [selectedDiagram, setSelectedDiagram] = useState<Diagram>(() => availableDiagrams[0])
  const [svgContent, setSvgContent] = useState<string | null>(null)
  const [selectedPartOrder, setSelectedPartOrder] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [hydrated, setHydrated] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const startPosition = useRef<Position>({ x: 0, y: 0 })
  const svgRef = useRef<HTMLObjectElement>(null)

  useEffect(() => {
    if (selectedDiagram?.url && hydrated) {
      setZoom(1)
      setLoading(true)
      fetchSvgContent(selectedDiagram.url, setSvgContent).finally(() => setLoading(false))
    }
  }, [selectedDiagram, hydrated])

  // Highlight the selected part in the diagram and zoom in
  useEffect(() => {
    const gTag = document.querySelector<SVGGElement>(`svg g[data-callout="${selectedPartOrder}"]`)
    const parentSvg = gTag?.closest('svg')
    const textTag = gTag?.querySelector<SVGTextElement>('text:last-of-type')

    if (!gTag || !textTag || !parentSvg) return

    const originalViewBox = parentSvg.getAttribute('viewBox')

    textTag.style.fontSize = '14px'
    textTag.style.fill = '#fff'

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    const textTagTransform = textTag.getAttribute('transform') || ''
    circle.setAttribute('transform', textTagTransform)
    circle.setAttribute('r', '15')
    circle.setAttribute('cx', '3')
    circle.setAttribute('cy', '4')
    circle.setAttribute('fill', '#000')
    circle.setAttribute('stroke', '#000')
    circle.setAttribute('stroke-width', '1')
    circle.setAttribute('class', 'highlight-circle')

    gTag?.insertBefore(circle, textTag)

    return () => {
      if (gTag && textTag && parentSvg) {
        circle.remove()
        textTag.style.fontSize = '9px'
        textTag.style.fill = '#000'

        if (originalViewBox) {
          parentSvg.setAttribute('viewBox', originalViewBox)
        }
      }
    }
  }, [selectedPartOrder])

  useEffect(() => {
    setHydrated(true)
  }, [])

  useEffect(() => {
    if (zoom === 1) {
      setPosition({ x: 0, y: 0 })
    }
  }, [zoom])

  const handleMouseDown = (e: React.MouseEvent<HTMLObjectElement>) => {
    setIsDragging(true)
    startPosition.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return

    const newX = e.clientX - startPosition.current.x
    const newY = e.clientY - startPosition.current.y

    setPosition((prev) => limitPan(newX, newY, prev))
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const limitPan = (newX: number, newY: number, prev: Position) => {
    const container = svgRef.current?.parentElement?.getBoundingClientRect()
    const image = svgRef.current?.getBoundingClientRect()

    if (!container || !image) {
      return prev
    }

    const maxX = Math.max((image.width - container.width) / 2, 0)
    const maxY = Math.max((image.height - container.height) / 2, 0)

    return {
      x: Math.min(maxX, Math.max(-maxX, newX)),
      y: Math.min(maxY, Math.max(-maxY, newY)),
    }
  }

  const updateTransform = (): React.CSSProperties => ({
    transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
  })

  return (
    <SlideOver
      title={selectedDiagram?.title}
      open={selectedDiagram !== null}
      onClose={() => {
        setAvailableDiagrams(null)
      }}
    >
      <div className="p-8 space-y-2">
        {availableDiagrams?.length > 1 && (
          <div className="flex flex-row items-center justify-end">
            <p className="text-sm text-gray-600 mr-2">Available diagrams: </p>

            <FilterDropdown
              title="Available diagrams"
              value={selectedDiagram?.id}
              onChange={(diagramId) =>
                setSelectedDiagram(
                  (oldDiagram) =>
                    availableDiagrams.find((diagram) => diagram.id === diagramId) || oldDiagram
                )
              }
              steps={availableDiagrams.map((d) => ({
                label: d.title,
                value: d.id,
              }))}
              disabled={loading}
            />
          </div>
        )}

        <div
          className="max-w-lg h-auto my-0 mx-auto relative"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="overflow-hidden">
            {loading ? (
              <div className="bg-slate-100 rounded-md animate-pulse w-103 h-103 my-14 mx-auto">
                &nbsp;
              </div>
            ) : svgContent ? (
              <>
                <object
                  dangerouslySetInnerHTML={{ __html: svgContent }}
                  ref={svgRef}
                  className="cursor-grab"
                  style={updateTransform()}
                  onMouseDown={handleMouseDown}
                />

                <div className="absolute right-4 bottom-4">
                  <button
                    type="button"
                    title="Increase zoom"
                    className="border p-2 w-10 text-lg disabled:opacity-50 font-normal text-gray-700 border-gray-300 bg-white hover:bg-gray-50 disabled:hover:bg-inherit"
                    disabled={zoom >= 6}
                    onClick={() => setZoom((prevZoom) => prevZoom + 1)}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    title="Decrease zoom"
                    className="border p-2 w-10 text-lg disabled:opacity-50 font-normal text-gray-700 border-gray-300 bg-white hover:bg-gray-50 disabled:hover:bg-inherit"
                    disabled={zoom <= 1}
                    onClick={() => setZoom((prevZoom) => prevZoom - 1)}
                  >
                    -
                  </button>
                </div>
              </>
            ) : (
              <img src={selectedDiagram?.url} alt={selectedDiagram.title} />
            )}
          </div>
        </div>

        <div>
          <div className="max-h-[70vh] overflow-y-auto scroll-shadows fancy-scroll">
            <Table className="text-sm">
              <thead>
                <Tr>
                  <Th className="w-4" />
                  <Th>Part Number</Th>
                  <Th>Part Name</Th>
                  <Th className="w-24" />
                </Tr>
              </thead>
              <tbody>
                {selectedDiagram?.diagramParts?.sort(sortAlphanumeric).map((p) => (
                  <Tr
                    key={p.id}
                    onMouseEnter={() => setSelectedPartOrder(p.order)}
                    onMouseLeave={() => setSelectedPartOrder(null)}
                  >
                    <Td>{p.order}</Td>
                    <Td>{p.partNumber}</Td>
                    <Td>{p.name} </Td>
                    <Td>
                      {addedPartNumbers
                        .map((pn) => pn.toLowerCase())
                        .includes(p.partNumber.toLowerCase()) ? (
                        <CheckIcon className="inline-block h-5 w-5 text-green-500" />
                      ) : (
                        <Action.T
                          onClick={() => onAddPart(p.partNumber, p.name)}
                          className="text-sm font-medium no-underline"
                        >
                          Add Part
                        </Action.T>
                      )}
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </SlideOver>
  )
}

export default PartDiagramSlideOver
