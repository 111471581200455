import { Maybe } from '@/types'
import usePersistedState from '../../CreateRequest/usePersistedState'

const useRequestSuggestedPart = (
  partNumber: string,
  partName: string,
  quantity: number,
  orgMachineId: Maybe<string>
) => {
  const { update, emptyState } = usePersistedState('new-request-data-2')

  const createRequest = () => {
    update({
      ...emptyState,
      parts: [
        {
          partNumber,
          description: partName,
          quantity,
          externalId: null,
          pictures: [],
          taskNumber: null,
          suggestion: null,
          jobQuantities: [],
        },
      ],
      machineOrgId: orgMachineId ?? undefined,
    })

    // use this hard redirect to make sure the request page picks up the new state
    document.location.href = `/rfqs/create?source.path=${encodeURIComponent(
      document.location.pathname
    )}&source.button=periodic-part-request`
  }

  return createRequest
}

export default useRequestSuggestedPart
