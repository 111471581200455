import { useCallback, useState } from 'react'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import { CostCode, useDeleteCostCodeMutation, usePaginatedCostCodesQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import usePage from '@/gf/hooks/usePage'
import { Table, Td, Th, Tr } from '@/gf/components/next/Table'
import debounce from 'lodash/debounce'
import Action from '@/gf/components/Action'
import Ghost from '@/gf/components/Ghost'
import useModal from '@/gf/hooks/useModal'
import { Maybe } from '@/types'
import Box from '@/gf/components/Box'
import Page from '@/gf/components/Page'
import Frame from '@/buyers/components/Frame'
import EditCostCodeModal from './EditCostCodeModal'
import ConfirmationModal from '@/gf/components/ConfirmationModal'
import useMsgs from '@/gf/hooks/useMsgs'

const Loading = () => (
  <>
    {[1, 2, 3, 4, 5, 6, 7, 8].map((a) => (
      <Tr key={a}>
        <Td className="w-20">
          <Ghost className="h-4 w-1/2 block" />
        </Td>
        <Td>
          <Ghost className="h-4 w-3/4 block" />
        </Td>
        <Td>
          <Ghost className="h-4 w-10 block" />
        </Td>
      </Tr>
    ))}
  </>
)
const CostCodesTable = () => {
  const client = useGqlClient()
  const [_, msgs] = useMsgs()
  const [page, setPage] = usePage()
  const [searchTerm, setSearchTerm] = useState('')
  const costCodeModal = useModal<{ costCode: Maybe<CostCode> }>()
  const deleteConfirmationModal = useModal<{ id: string }>()

  const [deleteMutation] = useDeleteCostCodeMutation({ client })
  const { data, previousData } = usePaginatedCostCodesQuery({
    client,
    variables: {
      page,
      searchTerm,
    },
  })

  const onSearchChanged = useCallback(
    debounce((text: string) => {
      setSearchTerm(text)
      setPage(1)
    }, 300),
    []
  )

  const deleteCostCode = async (id: string) => {
    try {
      await deleteMutation({ variables: { id } })

      client.reFetchObservableQueries()

      msgs.add('Cost code deleted!', 'positive')
    } catch (err) {
      msgs.add('Cost Code cannot be deleted, once it is linked to an order.', 'negative')
    } finally {
      deleteConfirmationModal.close()
    }
  }

  return (
    <Frame
      breadcrumbs={{
        copy: '',
        crumbs: [
          { name: 'Settings', href: '/settings' },
          { name: 'Organization', href: '/settings/organization' },
          { name: 'Cost Codes', href: '/settings/organization/cost-codes' },
        ],
      }}
    >
      <Page
        title="Cost Codes"
        actionsNext={[
          <Action.P onClick={() => costCodeModal.open({ costCode: null })}>Add Cost Code</Action.P>,
        ]}
        previousPage={'/settings/organization'}
      >
        <ConfirmationModal
          open={deleteConfirmationModal.isOpen}
          onCancel={deleteConfirmationModal.close}
          onConfirm={() => {
            if (deleteConfirmationModal.props) {
              deleteCostCode(deleteConfirmationModal.props.id)
            }
          }}
          title="Delete Cost Code"
          text="Are you sure you want to delete this cost code? This action cannot be undone."
        />
        <Box className="p-6 mt-6">
          {costCodeModal.isOpen && (
            <EditCostCodeModal
              open
              onClose={costCodeModal.close}
              costCode={costCodeModal.props?.costCode ?? null}
            />
          )}

          <div className="space-y-4">
            <div>
              <SearchInput
                value={searchTerm}
                onChange={onSearchChanged}
                placeholder="Search by cost code or name"
                autoFocus
                autoCompleteOff
              />
            </div>

            <Table className="text-sm">
              <thead>
                <Tr>
                  <Th className="w-40">Code</Th>
                  <Th>Name</Th>
                  <Th className="w-24" />
                </Tr>
              </thead>
              <tbody>
                {!data ? (
                  <Loading />
                ) : (
                  <>
                    {data?.costCodes.entries.map((j) => (
                      <Tr key={j.id}>
                        <Td>{j.code}</Td>
                        <Td>{j.name} </Td>

                        <Td>
                          <div className="flex gap-x-4">
                            <Action.T
                              onClick={() => costCodeModal.open({ costCode: j })}
                              className="text-sm font-medium no-underline cursor-pointer"
                            >
                              Edit
                            </Action.T>

                            <Action.T
                              onClick={() => deleteConfirmationModal.open({ id: j.id })}
                              className="text-sm font-medium no-underline cursor-pointer"
                            >
                              Delete
                            </Action.T>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                    {data.costCodes.entries.length === 0 && searchTerm && (
                      <Tr>
                        <Td className="text-gray-700" colSpan={4}>
                          No results for{' '}
                          <span className="text-gray-900 font-medium">{searchTerm}</span>
                        </Td>
                      </Tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>

            <PaginationC
              page={page}
              pagination={(data ?? previousData)?.costCodes.pagination}
              updatePage={setPage}
            />
          </div>
        </Box>
      </Page>
    </Frame>
  )
}

export default CostCodesTable
