import { useMemo } from 'react'
import nth from 'lodash/nth'
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { usePendingInvitesQuery, useUserAdditionQuery } from '@/buyers/_gen/gql'
import useAddUserForm from '@/buyers/hooks/useAddUserForm'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useConfig from '@/gf/hooks/useConfig'

import Details from './Add/Details'
import Permissions from './Add/Permissions'
import Review from './Add/Review'
import Success from './Add/Success'
import ProgressNav from '@/buyers/components/ProgressNav'

const progressNav = {
  steps: [
    { id: '1', name: 'Details' },
    { id: '2', name: 'Permissions' },
    { id: '3', name: 'Review' },
  ],
  theme: {
    textPrimary: 'text-gearflow',
    bgPrimary: 'bg-gearflow',
    textMedDark: 'text-orange-700',
    textDark: 'text-orange-900',
    hoverBgDark: 'hover:bg-orange-700',
    groupHoverBgDark: 'group-hover:bg-orange-700',
    bgLight: 'bg-orange-50',
    borderPrimary: 'border-gearflow',
    borderTopPrimary: 'border-t-gearflow',
    borderLight: 'border-orange-200',
    ringPrimary: 'ring-orange-500',
    focusRingPrimary: 'focus:ring-orange-500',
  },
}

const stepParams = ['details', 'permissions', 'review']

const Add = () => {
  const { stripePublicKey } = useConfig()
  const location = useLocation()
  const navigate = useNavigate()
  const { orgId, organization } = useSession()
  const inviteId = useMatch('/settings/users/invites/:inviteId/*')?.params.inviteId || null

  const pendingInvites = usePendingInvitesQuery({ variables: { orgId }, client: useGqlClient() })
    .data?.org?.pendingInvites

  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [])
  const successMatch = useMatch('/settings/users/add/success/:userId')

  const queryData = useUserAdditionQuery({
    variables: { orgId: organization.id },
    client: useGqlClient(),
  }).data

  const defaultRoleId = queryData?.org
    ? (queryData.org?.roles.find((role) => role.name === 'Purchaser')?.id ??
      nth(queryData.org.roles, 0)?.id)
    : undefined
  const formReducer = useAddUserForm(defaultRoleId)

  const cancel = () => {
    formReducer.reset()
    navigate('/settings/users')
  }

  if (!queryData || !pendingInvites) return null

  const { org } = queryData
  const stepParam = location.pathname.split('/').slice(-1)[0]
  const stepIndex = successMatch ? 4 : stepParams.indexOf(stepParam)

  const navigateToStepIndex = (index: number) => {
    const pathPrefix = inviteId ? `/settings/users/invites/${inviteId}/add` : `/settings/users/add`
    navigate(`${pathPrefix}/${stepParams[index]}`)
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl">Add User</h2>
      <ProgressNav
        stepIndex={stepIndex}
        setStepIndex={navigateToStepIndex}
        navLinksDisabled={!!successMatch}
        {...progressNav}
      />
      <Routes>
        <Route
          path="details"
          element={
            <Details {...formReducer} cancel={cancel} navigateToStepIndex={navigateToStepIndex} />
          }
        />
        <Route
          path="permissions"
          element={
            <Permissions
              {...formReducer}
              cancel={cancel}
              roles={org?.roles}
              permissionSummaries={org?.permissionSummaries}
              navigateToStepIndex={navigateToStepIndex}
            />
          }
        />
        <Route
          path="review"
          element={
            <Elements stripe={stripePromise}>
              <Review
                {...formReducer}
                roles={org?.roles}
                permissionSummaries={org?.permissionSummaries}
                cancel={cancel}
                navigateToStepIndex={navigateToStepIndex}
              />
            </Elements>
          }
        />
        <Route path="success/:userId" element={<Success />} />
      </Routes>
    </div>
  )
}

export default Add
