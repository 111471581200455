import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import ExternalOrderForm from '../components/ExternalOrderForm'
import { Navigate, useParams } from 'react-router-dom'
import { useEditExternalOrderQuery } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import { useMemo } from 'react'
import Id from '@/gf/modules/Id'
import uniqueId from 'lodash/uniqueId'

const EditExternalOrder = () => {
  const { storeOrderId } = useParams() as { storeOrderId: string }

  const client = useGqlClient()
  const { data } = useEditExternalOrderQuery({
    client,
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
  })

  const vendorId = data?.storeOrder?.order.requestForQuote?.vendorLinks?.[0].vendor.id ?? null
  const locationId = data?.storeOrder?.shippingLocation?.id ?? null
  const orgMachineId = data?.storeOrder?.orgMachines[0]?.id ?? null
  const purchaseOrderNumber = data?.storeOrder?.purchaseOrder ?? ''
  const workOrderNumber = data?.storeOrder?.order.requestForQuote?.workOrderNumber ?? ''
  const shippingCost = data?.storeOrder?.shippingAmount ?? null
  const taxCost = data?.storeOrder?.salesTax ?? null
  const items =
    data?.storeOrder?.lineItems.map((li) => ({
      id: li.id,
      partNumber: li.product.mpn ?? '',
      description: li.product.name ?? '',
      unitPrice: li.unitPrice,
      quantity: li.quantity,
      jobQuantities:
        li.jobQuantities?.map((jq) => ({
          id: uniqueId(),
          jobId: jq.job.id,
          quantity: jq.quantity,
        })) ?? [],
    })) ?? []

  const orderUrl = `/orders/${storeOrderId}`
  const breadcrumbs = useMemo(
    () => ({
      copy: 'Back to Dashboard',
      crumbs: [
        { name: 'Orders', href: '/orders' },
        { name: `Order ${Id.shorten(storeOrderId)}`, href: orderUrl },
        { name: 'Edit', href: `${orderUrl}/edit-external` },
      ],
    }),
    [storeOrderId]
  )

  if (data && !data.storeOrder?.externalOrder) {
    return <Navigate to={orderUrl} replace />
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Edit Logged Order" previousPage={orderUrl}>
        {data && (
          <ExternalOrderForm
            storeOrderId={storeOrderId}
            initialValues={{
              vendorId,
              locationId,
              orgMachineId,
              machineInvolved: !!orgMachineId,
              purchaseOrderNumber,
              workOrderNumber,
              shippingCost,
              taxCost,
              items,
              documentUrl: null,
            }}
          />
        )}
      </Page>
    </Frame>
  )
}

export default EditExternalOrder
