import { LimitedOrderQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import useToggle from '@/gf/hooks/useToggle'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import EditPartRequestJobNumbersModal from '@/buyers/components/EditPartRequestJobNumbersModal'

const JobNumbersAction = ({
  lineItem,
}: {
  lineItem: NonNullable<NonNullable<LimitedOrderQuery['storeOrder']>['lineItems'][number]>
}) => {
  const [open, toggle] = useToggle()
  const client = useGqlClient()
  return (
    <>
      <EditPartRequestJobNumbersModal
        open={open}
        onClose={toggle.off}
        part={{
          partNumber: lineItem.product.mpn,
          description: lineItem.product.name,
          quantity: lineItem.quantity,
        }}
        jobQuantities={lineItem.jobQuantities ?? []}
        lineItemId={lineItem.id}
        onSuccess={() => client.reFetchObservableQueries()}
      />

      {lineItem.jobQuantities && lineItem.jobQuantities.length > 0 ? (
        lineItem.jobQuantities.map((jq) => (
          <span key={jq.job.id} className="block text-sm">
            <Action.T onClick={toggle.on}>
              {jq.job.code} (x{jq.quantity})
            </Action.T>
          </span>
        ))
      ) : (
        <Action.T className="text-sm" onClick={toggle.on}>
          Add
        </Action.T>
      )}
    </>
  )
}

export default JobNumbersAction
