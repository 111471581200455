/**
 * Address Input Component
 * -----------------
 * Input fields for entering an address in a form. This is a controlled component.
 *
 * props
 * -----------------
 * address - the address value displayed in this component
 * onChange - this function is called with the new address value every time an address field is changed
 */

import { useEffect, useRef, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'

import pick from 'lodash/pick'

import CountryStates from '@/suppliers/modules/CountryStates'
import Smarty from '@/suppliers/modules/Smarty'
import AddressM from '../modules/Address'

import TextField from './TextField'
import SelectField from './SelectField'

import {
  Address,
  AddressSuggestion,
  AutocompleteSuggestion,
  InternationalAddressSuggestion,
  Option,
} from '../../types'
import useOnClickOutside from '../hooks/useClickOutside'
import nth from 'lodash/nth'

const emptyAddress = AddressM.init()

type GqlAddress = {
  firstName: string | null
  lastName: string | null
  companyName: string | null
  lineOne: string | null
  lineTwo: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country: string | null
}

interface AddressInputProps {
  address: Address | GqlAddress | undefined | null
  onChange: (newAddress: Address) => void
  requireFirstLastName?: boolean
  hideFirstLastName?: boolean
  hideCompany?: boolean
}

const AddressInput = ({
  address,
  onChange,
  requireFirstLastName,
  hideFirstLastName = false,
  hideCompany = false,
}: AddressInputProps) => {
  const addressQueryField = useRef<HTMLInputElement>(null)
  const [showAutocompleteSuggestions, setShowAutocompleteSuggestions] = useState(false)
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<AutocompleteSuggestion[]>(
    []
  )

  const newAddress: Address = address
    ? {
        ...address,
        firstName: address.firstName || '',
        lastName: address.lastName || '',
        lineOne: address.lineOne || '',
        postalCode: address.postalCode || '',
        city: address.city || '',
        state: address.state || '',
        country: address.country || emptyAddress.country,
      }
    : emptyAddress
  newAddress.country = newAddress.country ?? 'United States'
  const international = newAddress.country !== 'United States'
  const addressQueryWrapper = useRef(null)
  useOnClickOutside(addressQueryWrapper, () => setShowAutocompleteSuggestions(false))

  const countryOptions: Option[] = CountryStates.countries.map((country) => ({
    id: country.isoCode,
    display: country.name,
  }))
  const selectedCountryCode =
    CountryStates.countries.find((country) => country.name === newAddress.country)?.isoCode ?? 'US'
  const states = CountryStates.states[selectedCountryCode]
  const stateOptions: Option[] = [
    { id: '', display: '' },
    ...states.map((state) => ({
      id: state.isoCode,
      display: state.name,
    })),
  ]

  const debouncedAutocompleteSearch = useRef(
    Smarty.debouncedAutocompleteSearch((suggestions) => setAutocompleteSuggestions(suggestions))
  ).current

  useEffect(
    () =>
      // Cleanup any in-progress search requests when component is unmounted
      () => {
        debouncedAutocompleteSearch.cancel()
      },
    [debouncedAutocompleteSearch]
  )

  const selectAddress = (suggestion: AddressSuggestion | InternationalAddressSuggestion) => {
    // Only International Addresses have an addressId
    if ('addressId' in suggestion) {
      Smarty.autocompleteSearch(suggestion.addressId, international, '', suggestion.addressId).then(
        (suggestions) => {
          const internationalAddress = nth(suggestions, 0)?.suggestion
          if (
            suggestions.length === 1 &&
            internationalAddress &&
            'lineOne' in internationalAddress
          ) {
            onChange({
              ...newAddress,
              ...pick(internationalAddress, ['lineOne', 'lineTwo', 'city', 'state', 'postalCode']),
            })
            setShowAutocompleteSuggestions(false)
          } else {
            setAutocompleteSuggestions(suggestions)
            onChange({
              ...newAddress,
              lineOne: suggestion.addressText,
            })
            addressQueryField.current?.focus()
          }
        }
      )
    } else if (suggestion.entries > 1) {
      // Narrow down the selection
      const selected = `${suggestion.lineOne} (${suggestion.entries}) ${suggestion.city} ${suggestion.state} ${suggestion.postalCode}`
      Smarty.autocompleteSearch(suggestion.lineOne, international, selected).then((suggestions) =>
        setAutocompleteSuggestions(suggestions)
      )
      onChange({
        ...newAddress,
        lineOne: suggestion.lineOne,
      })
      addressQueryField.current?.focus()
    } else {
      // Select the address
      onChange({
        ...newAddress,
        ...pick(suggestion, ['lineOne', 'lineTwo', 'city', 'state', 'postalCode']),
      })
      setShowAutocompleteSuggestions(false)
    }
  }

  return (
    <>
      {!hideFirstLastName && (
        <div className="grid grid-cols-2 gap-2">
          <TextField
            label="First name"
            required={requireFirstLastName}
            value={newAddress.firstName ?? ''}
            onChange={(e) => onChange({ ...newAddress, firstName: e.target.value })}
            noErrorText
          />
          <TextField
            label="Last name"
            required={requireFirstLastName}
            value={newAddress.lastName ?? ''}
            onChange={(e) => onChange({ ...newAddress, lastName: e.target.value })}
            noErrorText
          />
        </div>
      )}
      {!hideCompany && (
        <TextField
          label="Company"
          value={newAddress.companyName ?? ''}
          onChange={(e) =>
            onChange({
              ...newAddress,
              companyName: e.target.value,
            })
          }
          noErrorText
        />
      )}
      <SelectField
        label="Country"
        required
        options={countryOptions}
        currentId={selectedCountryCode}
        onChange={(selectedId) => {
          onChange({
            ...newAddress,
            country: CountryStates.getCountryByCode(selectedId)?.name ?? '',
            // Clear the address for the new country
            lineOne: emptyAddress.lineOne,
            lineTwo: emptyAddress.lineTwo,
            city: emptyAddress.city,
            state: emptyAddress.state,
            postalCode: emptyAddress.postalCode,
          })
          // Clear autocomplete suggestions
          setAutocompleteSuggestions([])
        }}
      />
      <div className="relative" ref={addressQueryWrapper}>
        <TextField
          label="Address"
          ref={addressQueryField}
          required
          value={newAddress.lineOne}
          onChange={(e) => {
            onChange({
              ...newAddress,
              lineOne: e.target.value,
            })
            setShowAutocompleteSuggestions(true)
            debouncedAutocompleteSearch(e.target.value, international)
          }}
          onClick={() => setShowAutocompleteSuggestions(true)}
          noErrorText
        />
        {showAutocompleteSuggestions && (
          <div className="absolute rounded-md shadow-md bg-white w-full max-h-60 overflow-y-scroll z-10">
            {autocompleteSuggestions.map((autocompleteSuggestion) => (
              <button
                key={autocompleteSuggestion.display}
                className="text-sm text-gray-700 px-4 py-2 hover:bg-gray-100 cursor-pointer flex flex-row justify-between items-center w-full"
                type="button"
                onClick={() => selectAddress(autocompleteSuggestion.suggestion)}
              >
                <span>{autocompleteSuggestion.display}</span>
                {autocompleteSuggestion.suggestion.entries > 1 && (
                  <ChevronRightIcon className="h-4 w-4 flex text-gray-500" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>
      <TextField
        label="Address Line 2"
        value={newAddress.lineTwo ?? ''}
        onChange={(e) =>
          onChange({
            ...newAddress,
            lineTwo: e.target.value,
          })
        }
        noErrorText
      />
      <TextField
        label="City"
        required
        value={newAddress.city}
        onChange={(e) =>
          onChange({
            ...newAddress,
            city: e.target.value,
          })
        }
        noErrorText
      />
      <div className="grid grid-cols-2 gap-2">
        <SelectField
          label={selectedCountryCode === 'CA' ? 'Province' : 'State'}
          required
          options={stateOptions}
          currentId={newAddress.state}
          onChange={(selectedId) =>
            onChange({
              ...newAddress,
              // We currently store the state value as the state code, not the state name
              state: selectedId,
            })
          }
        />
        <TextField
          label="Postal code"
          required
          value={newAddress.postalCode ?? ''}
          onChange={(e) =>
            onChange({
              ...newAddress,
              postalCode: e.target.value,
            })
          }
          noErrorText
        />
      </div>
    </>
  )
}

export default AddressInput
