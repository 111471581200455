import AdminGqlClientProvider from '@/admin/providers/GqlClient'
import useSession from '@/buyers/hooks/useSession'
import ErrorBoundary from '@/gf/components/ErrorBoundary'
import HashLinks from '@/gf/components/HashLinks'
import useLocationChange from '@/gf/hooks/useLocationChange'
import * as GE from '@/gf/modules/GrammarEvents'
import ConfigProvider from '@/gf/providers/ConfigProvider'
import ErrorTrackerProvider from '@/gf/providers/ErrorTracker'
import MessageProvider from '@/gf/providers/MsgsProvider'
import StableNavigateProvider from '@/gf/providers/StableNavigateProvider'
import qs from 'query-string'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import AddVendors from './pages/AddVendors'
import ApproveRequestNext from './pages/ApproveRequestNext'
import BrokerRequest from './pages/BrokerRequest'
import CopyRequest from './pages/CopyRequest'
import CreateExternalOrder from './pages/CreateExternalOrder'
import CreateOrg from './pages/CreateOrg'
import CreateRequest from './pages/CreateRequest'
import Fleet from './pages/Fleet'
import ImportFleet from './pages/Fleet/Import'
import Home from './pages/Home'
import Inbox from './pages/Inbox'
import Inventory from './pages/Inventory'
import Invoices from './pages/Invoices'
import MachineDetails from './pages/MachineDetails'
import ManualOrder from './pages/ManualOrder'
import MobileCheckoutOrder from './pages/MobileCheckoutOrder'
import NotFound from './pages/NotFound'
import Notifications from './pages/Notifications'
import OAuth from './pages/OAuth'
import Order from './pages/Order'
import OrgBrands from './pages/OrgBrands'
import Orgs from './pages/Orgs'
import Reporting from './pages/Reporting'
import Requests from './pages/Requests'
import RetailCheckoutComplete from './pages/RetailCheckoutComplete'
import RFQ from './pages/RFQ'
import CreateQuote from './pages/RFQ/CreateQuote'
import FulfillFromInventory from './pages/RFQ/FulfillFromInventory'
import RFQAddVendors from './pages/RFQAddVendors'
import Settings from './pages/Settings'
import Setup from './pages/Setup'
import ShippingLocation from './pages/ShippingLocation'
import ShippingLocations from './pages/ShippingLocations'
import StoreOrders from './pages/StoreOrders'
import Vendor from './pages/Vendor'
import Vendors from './pages/Vendors'
import VendorsMap from './pages/VendorsMap'
import AccountBuilder from './pages/AccountBuilder'
import ApolloWsClientProvider from './providers/ApolloWsClient'
import FrameProvider from './providers/FrameProvider'
import GqlClientProvider from './providers/GqlClient'
import LegacyGqlClientProvider from './providers/LegacyGqlClient'
import LimitedRouter from './providers/LimitedRouter'
import SessionProvider from './providers/SessionProvider'
import TokenSignInProvider from './providers/TokenSignInProvider'
import KnowledgeCenter from './pages/KnowledgeCenter'
import PartsManual from './pages/PartsManual'
import UpdateQuickOrder from './pages/UpdateQuickOrder'
import EditExternalOrder from './pages/EditExternalOrder'
import JobsTable from './pages/Settings/Org/JobsTable'
import CostCodesTable from './pages/Settings/Org/CostCodesTable'

const Router = () => {
  const { user } = useSession()

  useEffect(() => {
    GE.init(user.id, 'parts-hub')
  }, [user.id])

  useLocationChange(() => GE.viewsPage())

  if (!user.claimed && (user.shpAdded || user.adminAdded)) return <LimitedRouter />

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/setup/*" element={<Setup />} />
      <Route path="/orders" element={<StoreOrders />} />
      <Route path="/orders/create-external/*" element={<CreateExternalOrder />} />
      <Route path="/orders/:storeOrderId/edit-external/*" element={<EditExternalOrder />} />
      <Route path="/orders/:storeOrderId/update" element={<UpdateQuickOrder />} />
      <Route path="/orders/:storeOrderId/*" element={<Order />} />

      <Route
        path="/mobile-checkout/:storeOrderId/method/:paymentMethod/*"
        element={<MobileCheckoutOrder />}
      />

      <Route path="/rfqs" element={<Requests />} />
      <Route path="/rfqs/create/*" element={<CreateRequest />} />
      <Route path="/rfqs/:rfqId/copy" element={<CopyRequest />} />
      <Route path="/rfqs/:rfqId/broker" element={<BrokerRequest />} />
      <Route path="/rfqs/:rfqId/approve/*" element={<ApproveRequestNext />} />
      <Route path="/rfqs/:rfqId/add-vendors/*" element={<RFQAddVendors />} />
      <Route path="/rfqs/:rfqId/vendors/:vendorId/create-quote" element={<CreateQuote />} />
      <Route path="/rfqs/:rfqId/fulfill-from-inventory/*" element={<FulfillFromInventory />} />
      <Route path="/rfqs/:rfqId/order-manually" element={<ManualOrder />} />
      <Route path="/rfqs/:rfqId/*" element={<RFQ />} />
      <Route path="/vendors/*" element={<Vendors />} />
      <Route path="/vendors/finish-setup/:vendorId/*" element={<Vendors />} />
      <Route path="/vendors/new" element={<AddVendors />} />
      <Route path="/vendors/:vendorId/*" element={<Vendor />} />
      <Route path="/locations" element={<ShippingLocations />} />
      <Route path="/locations/add" element={<ShippingLocations />} />
      <Route path="/locations/:locationId" element={<ShippingLocation />} />
      <Route path="/orgs/create" element={<CreateOrg />} />
      <Route path="/orgs" element={<Orgs />} />
      <Route path="/fleet" element={<Fleet />} />
      <Route path="/fleet/import" element={<ImportFleet />} />
      <Route path="/machines/:machineId/*" element={<MachineDetails />} />
      <Route path="/reporting/*" element={<Reporting />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/settings/organization/job-numbers" element={<JobsTable />} />
      <Route path="/settings/organization/cost-codes" element={<CostCodesTable />} />
      <Route path="/settings/*" element={<Settings />} />

      <Route path="/inbox" element={<Inbox />} />
      <Route path="/inbox/messages/:conversationId" element={<Inbox />} />
      <Route path="/retail-checkout-complete" element={<RetailCheckoutComplete />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/vendors-map" element={<VendorsMap />} />
      <Route path="/brands" element={<OrgBrands />} />
      <Route path="/account-builder" element={<AccountBuilder />} />
      <Route path="/oauth/*" element={<OAuth />} />
      <Route path="/knowledge-center" element={<KnowledgeCenter />} />
      <Route path="/parts-manual/:id" element={<PartsManual />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

const App = () => (
  <ConfigProvider app="buyers">
    <ErrorTrackerProvider>
      <ErrorBoundary>
        <LegacyGqlClientProvider>
          <GqlClientProvider>
            <AdminGqlClientProvider>
              <TokenSignInProvider>
                <SessionProvider>
                  <ApolloWsClientProvider>
                    <MessageProvider>
                      <StableNavigateProvider>
                        <FrameProvider>
                          <QueryParamProvider
                            adapter={ReactRouter6Adapter}
                            options={{
                              searchStringToObject: qs.parse,
                              objectToSearchString: qs.stringify,
                            }}
                          >
                            <HashLinks />
                            <Router />
                          </QueryParamProvider>
                        </FrameProvider>
                      </StableNavigateProvider>
                    </MessageProvider>
                  </ApolloWsClientProvider>
                </SessionProvider>
              </TokenSignInProvider>
            </AdminGqlClientProvider>
          </GqlClientProvider>
        </LegacyGqlClientProvider>
      </ErrorBoundary>
    </ErrorTrackerProvider>
  </ConfigProvider>
)

export default App
