import { PencilAltIcon, TrashIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import MultiButton, { MultiButtonAction, MultiButtonLink } from '@/gf/components/MultiButton'
import { VendorsQuery } from '../../_gen/gql'
import DeleteVendorModal from '../Vendor/DeleteVendorModal'

type Vendor = Exclude<VendorsQuery['org'], null>['paginatedVendors']['vendors'][number]

// Custom wrapper to ensure consistent button width
const ActionButton = ({ children }: { children: React.ReactNode }) => (
  <div className="w-20 text-left">{children}</div>
)

const Actions = ({ vendor, refetch }: { vendor: Vendor; refetch: () => void }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isMultiButtonOpen, setIsMultiButtonOpen] = useState(false)
  const actions: (MultiButtonAction | MultiButtonLink)[] = []

  // If vendor has setup finished (not in draft mode)
  if (!vendor.draft) {
    actions.push({
      display: <ActionButton>Edit</ActionButton>,
      icon: PencilAltIcon,
      description: 'Edit this vendor',
      to: `/vendors/${vendor.id}?edit=true`,
    })
  } else {
    // If vendor doesn't have setup finished
    actions.push({
      display: <ActionButton>Finish Setup</ActionButton>,
      icon: CheckCircleIcon,
      description: 'Complete the vendor setup process',
      to: `/vendors/finish-setup/${vendor.id}`,
    })
  }

  // Both types of vendors can be removed
  actions.push({
    display: <ActionButton>Remove</ActionButton>,
    icon: TrashIcon,
    description: 'Remove this vendor',
    onClick: () => {
      setDeleteModalOpen(true)
      setIsMultiButtonOpen(false)
    },
  })

  return (
    <>
      <MultiButton
        align="left"
        actions={actions}
        open={isMultiButtonOpen}
        onOpenChange={setIsMultiButtonOpen}
      />

      <DeleteVendorModal
        vendorId={vendor.id}
        vendorName={vendor.name}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false)
          refetch()
        }}
      />
    </>
  )
}

export default Actions
