import { useMemo, useState } from 'react'
import { Maybe } from '@/types'
import { CostCodeAutoCompleteQuery, useCostCodeAutoCompleteQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'

const label = (j: CostCodeAutoCompleteQuery['costCodesAutocomplete'][number]) =>
  `${j.code} - ${j.name}`

const CostCodeSelector = ({
  value,
  onChange,
}: {
  value: Maybe<string>
  onChange: (value: Maybe<string>) => void
}) => {
  const client = useGqlClient()
  const [search, setSearch] = useState('')
  const { data } = useCostCodeAutoCompleteQuery({ client, variables: { search, ids: null } })
  const { data: selectedData } = useCostCodeAutoCompleteQuery({
    client,
    variables: { ids: [value!], search: null },
    skip: !value,
  })

  const options = useMemo(() => {
    if (!data) {
      return data
    }

    return data.costCodesAutocomplete.map((j) => ({ value: j.id, label: label(j) }))
  }, [data])

  const selectedOption = useMemo(() => {
    if (!value) {
      return null
    }

    const selected = selectedData?.costCodesAutocomplete[0]
    return { value, label: selected ? label(selected) : '' }
  }, [value, selectedData])

  return (
    <Select
      options={options}
      value={selectedOption}
      onInputChange={setSearch}
      className="text-sm"
      isClearable
      onChange={(o) => {
        onChange(o?.value ?? null)
      }}
    />
  )
}

export default CostCodeSelector
