import {
  QuotedOrderQuery,
  SelectedPaymentMethod,
  useApproveBalanceQuery,
  useRequestPoMutation,
} from '@/buyers/_gen/gql'
import Time from '@/gf/modules/Time'
import TextTag from '@/gf/components/next/TextTag'
import Box from '@/buyers/components/Box'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import BalanceTermsAndConditions from '@/gf/components/BalanceTermsAndConditions'
import Field from '@/gf/components/Field'
import Link from '@/gf/components/Link'
import Spinner from '@/gf/components/Spinner'
import TextInput from '@/gf/components/inputs/Text'
import Money from '@/gf/modules/Money'
import qs from 'query-string'
import { useState, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'
import GeneratedPONumber from './GeneratedPONumber'
import { PaymentMethodField } from './PaymentMethodField'
import Prices from './Prices'
import StripeCheckout from './StripeCheckout'
import useApproveForm from './useApproveForm'
import useBalanceModal from './useBalanceModal'
import { CheckCircleIcon } from '@heroicons/react/outline'

const ApproveForm = ({
  paymentOption,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  onApproved,
}: {
  paymentOption: Exclude<QuotedOrderQuery['storeOrder'], null>['paymentOption'] | undefined
  paymentMethods: SelectedPaymentMethod[] | undefined
  selectedPaymentMethod: SelectedPaymentMethod | null | undefined
  onApproved: (paymentMethod: SelectedPaymentMethod) => void
  setSelectedPaymentMethod: (pm: SelectedPaymentMethod | null) => void
}) => {
  const { featureFlags } = useSession()
  const [requesting, setRequesting] = useState(false)
  const { storeOrder, org, user, rejectedLineItemIds } = useOrderContext()
  const navigate = useNavigate()

  const [requestPoNumber] = useRequestPoMutation({ client: useGqlClient() })

  const { purchaseOrder, approving, errors, setPurchaseOrder, approve, validate } = useApproveForm({
    selectedPaymentMethod,
    onApproved,
  })

  const { balanceTransactions, balanceCreditLimit } =
    useApproveBalanceQuery({
      variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrder.id]) },
      client: useGqlClient(),
      skip: !paymentOption || (!paymentOption.balance && !paymentOption.balanceTerms),
    }).data?.storeOrder || {}

  const vendorLink =
    storeOrder.order.requestForQuote?.vendorLinks.find(
      (vl) => vl.vendor.storeId === storeOrder.store.id
    ) ?? null

  const { open: openBalanceModal } = useBalanceModal({ approve, balanceTransactions })

  useEffect(() => {
    // selectedPaymentMethod might be SelectedPaymentMethod.Balance bc balanceCreditLimit wasn't loaded yet, so
    // correctly default it here now that it's loaded
    if (
      balanceCreditLimit &&
      paymentMethods &&
      paymentMethods.some((pm) => pm === SelectedPaymentMethod.BalanceTerms)
    )
      setSelectedPaymentMethod(SelectedPaymentMethod.BalanceTerms)
  }, [!paymentMethods, balanceCreditLimit === undefined])

  const canApprove =
    user.role === 'admin' ||
    user.userRole?.name === 'admin' ||
    (user.can.approveStoreOrders &&
      (!org.approvalThresholdEnabled ||
        (org.approvalThreshold !== null &&
          Money.compare(org.approvalThreshold, storeOrder.total) !== -1)))

  const accept = () => {
    validate().then(() => {
      if (selectedPaymentMethod === SelectedPaymentMethod.Direct) approve()
      if (selectedPaymentMethod === SelectedPaymentMethod.BalanceTerms) approve({ useTerms: true })
      if (selectedPaymentMethod === SelectedPaymentMethod.Balance) openBalanceModal()
      if (selectedPaymentMethod === SelectedPaymentMethod.Stripe)
        navigate(`/orders/${storeOrder.id}/accept`)
    })
  }

  const requestPo = () => {
    setRequesting(true)

    return requestPoNumber({ variables: { storeOrderId: storeOrder.id } })
      .then(() => {
        window.location.reload()
      })
      .finally(() => setRequesting(false))
  }

  return (
    <Box className="space-y-4">
      <div>
        {!org.customApprovalEnabled ? (
          <Field
            label={`Purchase Order${!org.showPurchaseOrder ? ' (optional)' : ''}`}
            errors={errors.purchaseOrder}
            className="pb-4 border-b"
          >
            {featureFlags.poNumberGenerator && org.generatePurchaseOrderNumber ? (
              // Don't generate the PO number if one is already set
              storeOrder.purchaseOrder ? (
                <p className="text-sm">{storeOrder.purchaseOrder}</p>
              ) : (
                <GeneratedPONumber storeOrderId={storeOrder.id} />
              )
            ) : (
              <TextInput
                value={purchaseOrder}
                setValue={setPurchaseOrder}
                required={org.showPurchaseOrder}
              />
            )}
          </Field>
        ) : org.customApprovalEnabled && storeOrder.poRequestedAt ? (
          <>
            <TextTag color="green" className="w-full mb-4 flex items-center">
              <CheckCircleIcon className="inline w-5 h-5 mr-2" /> PO Requested:{' '}
              {Time.formatDateTimeWithDaySmartYear(storeOrder.poRequestedAt)}
            </TextTag>
            <Field
              label={`Purchase Order${!org.showPurchaseOrder ? ' (optional)' : ''}`}
              errors={errors.purchaseOrder}
              className="pb-4 border-b"
            >
              {featureFlags.poNumberGenerator && org.generatePurchaseOrderNumber ? (
                // Don't generate the PO number if one is already set
                storeOrder.purchaseOrder ? (
                  <p className="text-sm">{storeOrder.purchaseOrder}</p>
                ) : (
                  <GeneratedPONumber storeOrderId={storeOrder.id} />
                )
              ) : (
                <TextInput
                  value={purchaseOrder}
                  setValue={setPurchaseOrder}
                  required={org.showPurchaseOrder}
                />
              )}
            </Field>
          </>
        ) : (
          <></>
        )}

        {canApprove &&
          (paymentMethods ? (
            <PaymentMethodField
              selectedPaymentMethod={selectedPaymentMethod}
              paymentMethods={paymentMethods}
              balanceCreditLimit={balanceCreditLimit}
              vendorLink={vendorLink}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          ) : (
            <div className="py-2">
              <Spinner />
            </div>
          ))}

        {paymentMethods && (
          <div className="pt-4">
            <Prices
              selectedDirect={selectedPaymentMethod === SelectedPaymentMethod.Direct}
              onEditCostCode={() => {}}
              shippingCostCode={null}
              showShippingCostCodeSelection={false}
            />
          </div>
        )}
      </div>

      {paymentMethods && (
        <div className="space-y-2">
          {selectedPaymentMethod === SelectedPaymentMethod.Direct &&
          rejectedLineItemIds.length > 0 ? (
            <Link.P
              to={`reject?partial=1&${qs.stringify({
                rejectedLineItemIds,
              })}`}
              className="w-full text-center"
            >
              Request Updated Quote
            </Link.P>
          ) : (
            <>
              {!org.customApprovalEnabled ? (
                <Action.P
                  onClick={accept}
                  performing={approving}
                  className="w-full"
                  disabled={!canApprove || !selectedPaymentMethod}
                >
                  Accept Quote
                </Action.P>
              ) : org.customApprovalEnabled && storeOrder.poRequestedAt ? (
                <Action.P
                  onClick={accept}
                  performing={approving}
                  className="w-full"
                  disabled={!canApprove || !selectedPaymentMethod}
                >
                  Accept Quote
                </Action.P>
              ) : (
                <Action.P
                  onClick={requestPo}
                  performing={requesting}
                  className="w-full"
                  disabled={!canApprove}
                >
                  Request PO
                </Action.P>
              )}
            </>
          )}

          <Link.S to="reject" className="w-full text-center">
            Reject Quote
          </Link.S>
        </div>
      )}

      {/* We only need to show the Balance terms for orders paid with net terms */}
      {canApprove && selectedPaymentMethod === SelectedPaymentMethod.BalanceTerms && (
        <div>
          <BalanceTermsAndConditions buttonText="Accept Quote" />
        </div>
      )}

      {!canApprove && (
        <>
          <p className="text-base">This order requires additional approval.</p>

          <div>
            <Link.T to="messages/internalOrg">Message my team</Link.T>
          </div>
        </>
      )}

      <Routes>
        <Route path="accept" element={<StripeCheckout approve={approve} />} />
      </Routes>
    </Box>
  )
}

export default ApproveForm
