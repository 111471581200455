import { DeliveryMethod, PartNumbersQuery, QueryDiagramsQuery } from '@/buyers/_gen/gql'
import { Address, Maybe, Point } from '@/types'
import { DateTime } from 'luxon'

export enum Priority {
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
}

// TODO: remove thyis type
export enum SourcingType {
  VENDORS,
  GF_NETWORK,
}

export type Filters = {
  search: string
  location: Maybe<Point>
  brands: string[]
}

export type Urgency = { priority: Priority; machineDown: boolean; neededByDate: DateTime }

export type JobQuantity = {
  jobId: string
  quantity: number
}

export type CreateRequestState = {
  billingCompanyId?: Maybe<string>
  workOrderNumber?: string
  machineOrgId?: Maybe<string>
  machineInvolved?: boolean
  urgency?: { priority: Priority; machineDown: boolean; neededByDate: DateTime }
  parts?: {
    partNumber: Maybe<string>
    description: Maybe<string>
    quantity: number
    externalId: Maybe<string>
    taskNumber: Maybe<string>
    suggestion: Maybe<boolean>
    pictures: string[]
    jobQuantities: {
      jobId: string
      quantity: number
    }[]
  }[]
  comments?: string

  nearbyReferencePoint?: Point
  locationId?: string
  shippingAddress?: Address

  dealerLocationIds: string[]
  vendors: {
    vendorId: string
    contactIds: string[]
    deliveryMethod: DeliveryMethod
    accountNumber: string
    fleetioId: number | null
  }[]
  brokerRfqId?: Maybe<string>

  broadcastToNetwork: boolean
  quickOrder?: boolean

  tutorial: boolean
}

export type PartialRequestUpdate = {
  request: CreateRequestState
  updateRequest: (changes: Partial<CreateRequestState>) => void
}

export type VendorSelectionType = 'limited' | 'full' | 'disabled'

export type Diagram = NonNullable<
  NonNullable<PartNumbersQuery['partNumbers']>[number]
>['diagrams'][number]

export type DiagramOutput = NonNullable<QueryDiagramsQuery['queryDiagrams']>
