import { useAllPlansQuery, useSettingsBillingDetailsQuery } from '@/buyers/_gen/gql'
import PricingOptions from '@/buyers/components/PricingOptions'
import UpdatePayment from '@/buyers/components/UpdatePayment'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import Spinner from '@/gf/components/Spinner'
import MoneyM from '@/gf/modules/Money'
import { PlanType } from '@/retail/components/PricingSection'
import { Route, Routes } from 'react-router-dom'
import AddLicenses from './AddLicenses'
import Plan from './Plan'

const Details = () => {
  const {
    user: { id: userId },
    organization: { id: orgId, buyerDashboardAccess, buyerDashboardAccessExpiresAt },
  } = useSession()

  const client = useGqlClient()
  const reloadSession = useReloadSession()

  const stripePlan = useAllPlansQuery({ client }).data?.allPlans.find(
    (p) => p.subscriptionType === 'organization'
  )

  const queryResult = useSettingsBillingDetailsQuery({ variables: { orgId, userId }, client })

  if (!stripePlan || !queryResult.data) return <Spinner />

  const { planSubscription, planPaymentMethod, user } = queryResult.data

  // For now - until we start storing what type of plan an account is on:
  // - NO trial OR Stripe subscription         -> Basic
  // - free trial                              -> Pro
  // - Stripe subscription OR indefinite trial -> Enterprise
  const currentPlanId: PlanType =
    planSubscription || (buyerDashboardAccess && !buyerDashboardAccessExpiresAt)
      ? planSubscription?.plan?.subscriptionType === 'php_fixed_plus'
        ? planSubscription?.enterprise
          ? PlanType.Enterprise
          : PlanType.Pro
        : PlanType.Enterprise
      : buyerDashboardAccessExpiresAt
        ? PlanType.Pro
        : PlanType.Basic

  const basePrice = planSubscription?.plan?.basePrice
    ? planSubscription.plan.basePrice
    : MoneyM.fromInt(0, 'USD')

  const isFixedPlus = planSubscription?.plan?.subscriptionType === 'php_fixed_plus'

  const fixedPlusQuantity = planSubscription
    ? isFixedPlus
      ? planSubscription.plan.baseLicenses
      : planSubscription.quantity
    : 0

  const fixedPlusPrice = planSubscription ? basePrice : MoneyM.fromInt(0, 'USD')

  const licenseCost = planSubscription
    ? isFixedPlus
      ? planSubscription?.plan?.amount
      : null
    : null

  const planPrice = planSubscription
    ? isFixedPlus
      ? fixedPlusPrice
      : planSubscription.plan.amount
    : MoneyM.fromInt(0, 'USD')

  const planQuantity = planSubscription
    ? isFixedPlus
      ? fixedPlusQuantity || 0
      : planSubscription.quantity
    : 0

  const reloadAndRefetch = () => Promise.all([reloadSession(), queryResult.refetch()])

  return (
    <>
      {planSubscription && (
        <Routes>
          <Route
            path="add-licenses"
            element={
              <AddLicenses
                planPaymentMethod={planPaymentMethod}
                planSubscription={planSubscription}
                onAdded={queryResult.refetch}
              />
            }
          />
        </Routes>
      )}

      <section className="pb-8 grid gap-4">
        {planSubscription && <Plan planSubscription={planSubscription} />}

        <PricingOptions
          refetch={reloadAndRefetch}
          currentPlanId={currentPlanId}
          currentPlanPricing={
            planSubscription
              ? {
                  frequency: planSubscription.plan.frequency as
                    | 'monthly'
                    | 'yearly'
                    | 'semi-annual'
                    | 'quarterly',
                  price: planPrice,
                  licenses: planQuantity,
                  licenseCost,
                }
              : undefined
          }
          isFreeTrial={!!buyerDashboardAccessExpiresAt}
        />

        {stripePlan && planPaymentMethod && planSubscription && user.orgUser.role === 'owner' && (
          <UpdatePayment
            planSubscription={planSubscription}
            planPaymentMethod={planPaymentMethod}
            onUpdate={queryResult.refetch}
          />
        )}
      </section>
    </>
  )
}

export default Details
