import { LineSvgProps, ResponsiveLine } from '@nivo/line'
import get from 'lodash/get'
import pick from 'lodash/pick'
import values from 'lodash/values'
import { DateTime } from 'luxon'
import { useState } from 'react'

import { SortByInput, SortOrder } from '@/buyers/_gen/gql'

import useSession from '@/buyers/hooks/useSession'
import { SecondDegreeStandardAggregateT } from '@/buyers/modules/Reporting'
import useChartPointSymbol, { pointToDate } from '@/gf/hooks/useChartPointSymbol'
import DurationM from '@/gf/modules/Duration'
import TimeM from '@/gf/modules/Time'
import Chart from './Chart'
import RequestsTable from './RequestsTable'
import StandardColumns from './StandardColumns'
import useMetrics from './useMetrics'
import useReportingFormQueryParams from './useReportingFormQueryParams'
import useSelectedValues, { SelectedValue } from './useSelectedValues'
import useSortBy from './useSortBy'

import RequestForQuote from '@/buyers/modules/RequestForQuote'
import RequestsTableHeader from '@/buyers/modules/RequestsTableHeader'
import Ghost from '@/gf/components/Ghost'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import NivoLineTooltip from '@/gf/components/Reports/NivoPointTooltip'
import ReportingTable, {
  NoResults,
  getBreakdownSelectMessage,
} from '@/gf/components/Reports/ReportingTable'
import Tabs from '@/gf/components/next/Tabs'
import TitleMetric from '@/gf/components/next/TitleMetric'
import Tag from '../../../gf/components/Reports/Tag'
import { timeComparison } from '../Home/ReportsSection/RequestFulfillment'
import Timeline from '../Home/ReportsSection/RequestFulfillment/Timeline'
import { getReportingTableValue } from './ReportingTableValue'
import Scorecards from './Scorecards'

type Tab = 'Vendor' | 'Requester' | 'Urgency' | 'Purchaser'
type Metric =
  | 'request fulfillment time'
  | 'time to review request'
  | 'response time'
  | 'review quotes time'
  | 'order fulfillment time'
  | 'time savings'

const getMetricRequestKey = (metric: Metric) =>
  metric === 'time savings'
    ? 'timeSavings'
    : metric === 'request fulfillment time'
      ? 'lifespanDuration'
      : metric === 'time to review request'
        ? 'requestApprovalDuration'
        : metric === 'response time'
          ? 'responseDuration'
          : metric === 'review quotes time'
            ? 'quoteApprovalDuration'
            : 'orderFulfillmentDuration'

const getMetricAggregatePath = (metric: Metric) =>
  `${getMetricRequestKey(metric)}.${metric === 'time savings' ? 'sum' : 'median'}`

const getMetricLabel = (metric: Metric) =>
  metric === 'request fulfillment time'
    ? 'Request Fulfillment Time'
    : metric === 'time to review request'
      ? 'Review Request'
      : metric === 'response time'
        ? 'Receive Quote'
        : metric === 'review quotes time'
          ? 'Review Quote'
          : 'Order Fulfillment'

const getUseRequestProcessedStoreOrders = (metric: Metric) =>
  metric !== 'time to review request' && metric !== 'response time'

const useReportingMetrics = <TabT extends Tab, MetricT extends Metric>({
  durationStart,
  durationEnd,
  metric,
  tab,
  urgentRequestsOnly,
  selectedValue,
  selectedDate,
  sortBy,
  setSortBy,
  requestsSortBy,
  toggleSelectedValue,
  clearSelectedValue,
}: {
  durationStart: DateTime
  durationEnd: DateTime
  metric: MetricT
  tab: TabT
  urgentRequestsOnly: boolean
  selectedValue: SelectedValue | undefined
  selectedDate: DateTime | undefined
  sortBy: SortByInput
  setSortBy: (prev: SortByInput) => void
  requestsSortBy: SortByInput
  toggleSelectedValue: ReturnType<typeof useSelectedValues>['toggleSelectedValue']
  clearSelectedValue: ReturnType<typeof useSelectedValues>['clearSelectedValue']
}) => {
  const metricFilter = (row) => get(row, getMetricRequestKey(metric)) !== null

  const {
    error,
    prevError,
    refetch,
    // Aggregates
    aggregatedRequestMetrics,
    prevAggregatedRequestMetrics,
    aggregatedStoreMetrics,
    aggregatedCreatorMetrics,
    aggregatedUrgencyMetrics,
    // Chart data
    chartData,
    chartDailyAverage,
    selectedChartData,
    // Table metrics
    orderedStoreMetrics,
    orderedCreatorMetrics,
    orderedMachineMetrics,
    orderedUrgencyMetrics,
    orderedPurchaserMetrics,
    filteredOrderedRequestMetrics,
  } = useMetrics({
    form: { durationStart, durationEnd, tab, urgentRequestsOnly },
    selectedValues: selectedValue ? [selectedValue] : [],
    selectedDate,
    sortBy,
    requestsSortBy,
    storeFilter: (row) => !!row.store && metricFilter(row),
    creatorFilter: (row) => !!row.creator && metricFilter(row),
    machineFilter: (row) => !!row.orgMachine && metricFilter(row),
    categoryFilter: (row) => !!row.category && metricFilter(row),
    urgencyFilter: (row) => !!row.urgency && metricFilter(row),
    requestFilter: metricFilter,
    purchaserFilter: (row) => !!row.assignedUser && metricFilter(row),
    getChartValue: (row) => get(row, getMetricAggregatePath(metric)),
    useRequestProcessedStoreOrders: getUseRequestProcessedStoreOrders(metric),
  })

  const chartSpec = {
    yAxisLegend: getMetricLabel(metric),
  }
  const getMetricColumns = (
    aggregate:
      | {
          lifespanDuration: SecondDegreeStandardAggregateT
          requestApprovalDuration: SecondDegreeStandardAggregateT
          responseDuration: SecondDegreeStandardAggregateT
          quoteApprovalDuration: SecondDegreeStandardAggregateT
          orderFulfillmentDuration: SecondDegreeStandardAggregateT
          timeSavings: SecondDegreeStandardAggregateT
        }
      | undefined
  ) =>
    metric === 'time savings'
      ? {
          metric: StandardColumns.getTimeSavingsColumn({ aggregate }),
          requests: StandardColumns.getTimeSavingsRequestsCountColumn(),
        }
      : metric === 'request fulfillment time'
        ? {
            metric: StandardColumns.getLifespanDurationMedianTimeColumn({ aggregate }),
            requests: StandardColumns.getLifespanDurationRequestsCountColumn(),
          }
        : metric === 'time to review request'
          ? {
              metric: StandardColumns.getRequestApprovalDurationMedianTimeColumn({ aggregate }),
              requests: StandardColumns.getRequestApprovalDurationRequestsCountColumn(),
            }
          : metric === 'response time'
            ? {
                metric: StandardColumns.getResponseDurationMedianTimeColumn({ aggregate }),
                requests: StandardColumns.getResponseDurationRequestsCountColumn(),
              }
            : metric === 'review quotes time'
              ? {
                  metric: StandardColumns.getQuoteApprovalDurationMedianTimeColumn({ aggregate }),
                  requests: StandardColumns.getQuoteApprovalDurationRequestsCountColumn(),
                }
              : {
                  metric: StandardColumns.getOrderFulfillmentDurationMedianTimeColumn({
                    aggregate,
                  }),
                  requests: StandardColumns.getOrderFulfillmentDurationRequestsCountColumn(),
                }

  const breakdown =
    tab === 'Vendor' ? (
      <ReportingTable
        data={orderedStoreMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.store?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.store?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.store && toggleSelectedValue({ value: row.store.id, display: row.store.name }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Vendor',
            getValue: (row) => row.store?.name,
          },
          ...values(pick(getMetricColumns(aggregatedStoreMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : tab === 'Requester' ? (
      <ReportingTable
        data={orderedCreatorMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.creator?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.creator?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.creator &&
            toggleSelectedValue({ value: row.creator.id, display: row.creator.displayName }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Requester',
            getValue: (row) => row.creator?.displayName,
          },
          ...values(pick(getMetricColumns(aggregatedCreatorMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : tab === 'Urgency' ? (
      <ReportingTable
        data={orderedUrgencyMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.urgency ?? ''}
        checkbox={{
          getChecked: (row) => row.urgency === selectedValue?.value,
          onToggleRow: (row) =>
            toggleSelectedValue({
              value: row.urgency,
              display: RequestForQuote.urgencyToDisplay(row.urgency),
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Urgency',
            getValue: (row) => RequestForQuote.urgencyToDisplay(row.urgency),
          },
          ...values(pick(getMetricColumns(aggregatedUrgencyMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : (
      <ReportingTable
        data={orderedPurchaserMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.assignedUser?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.assignedUser?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.assignedUser &&
            toggleSelectedValue({
              value: row.assignedUser.id,
              display: row.assignedUser.displayName,
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Purchaser',
            getValue: (row) => row.assignedUser?.displayName,
          },
          ...values(pick(getMetricColumns(aggregatedCreatorMetrics), ['requests', 'metric'])),
        ]}
      />
    )
  if (error || prevError) return { error: error || prevError, refetch }
  return {
    refetch,
    current: {
      aggregate: aggregatedRequestMetrics,
      chart: {
        data: chartData,
        selectedCharts: selectedChartData,
        spec: chartSpec,
        dailyAverage: chartDailyAverage,
      },
      breakdown,
      orderedStoreMetrics,
      orderedCreatorMetrics,
      orderedMachineMetrics,
      orderedPurchaserMetrics,
      filteredOrderedRequestMetrics,
    },
    prev: { aggregate: prevAggregatedRequestMetrics },
  }
}

const Time = () => {
  const { organization } = useSession()
  const [selectedDate, setSelectedDate] = useState<DateTime>()
  const { form, updateForm } = useReportingFormQueryParams<Tab, Metric>({
    defaultDurationId,
    defaultDurationDates,
    defaultTab: 'Vendor',
    defaultMetric: 'request fulfillment time',
  })
  const { selectedValue, toggleSelectedValue, clearSelectedValue } = useSelectedValues(form.tab)
  const { sortBy, setSortBy } = useSortBy(`${form.metric}:${form.tab}`, {
    field: getMetricAggregatePath(form.metric),
    order:
      form.tab === 'Requester' && form.metric !== 'time savings' ? SortOrder.Asc : SortOrder.Desc,
  })
  const [requestsSortBy, setRequestsSortBy] = useState<SortByInput>({
    field: 'requestForQuote.insertedAt',
    order: SortOrder.Desc,
  })

  const clearFilters = () => {
    updateForm({ urgentRequestsOnly: false })
    setSelectedDate(undefined)
    clearSelectedValue()
  }

  const toggleMetric = (metric: Metric) =>
    updateForm({ metric: form.metric === metric ? 'request fulfillment time' : metric })

  const days = form.durationEnd.diff(form.durationStart).as('days')
  const pointSymbol = useChartPointSymbol(selectedDate)

  const durationGraph: Partial<LineSvgProps> = {
    ...Chart.getBaseGraph({ days, pointSymbol }),
    yFormat: (dayValue) =>
      typeof dayValue === 'number' && dayValue !== 0
        ? DurationM.human(DurationM.fromSeconds(dayValue))
        : 'none',
  }

  const { error, current, prev } = useReportingMetrics({
    ...pick(form, ['durationStart', 'durationEnd', 'metric', 'tab', 'urgentRequestsOnly']),
    selectedValue,
    selectedDate,
    sortBy,
    setSortBy,
    requestsSortBy,
    toggleSelectedValue,
    clearSelectedValue,
  })

  const orderFulfillmentDuration = timeComparison(
    typeof current?.aggregate === 'undefined'
      ? undefined
      : (current?.aggregate?.orderFulfillmentDuration?.median ?? null),
    typeof prev?.aggregate === 'undefined'
      ? undefined
      : (prev?.aggregate?.orderFulfillmentDuration?.median ?? null)
  )

  const quoteApprovalDuration = timeComparison(
    typeof current?.aggregate === 'undefined'
      ? undefined
      : (current?.aggregate?.quoteApprovalDuration?.median ?? null),
    typeof prev?.aggregate === 'undefined'
      ? undefined
      : (prev?.aggregate?.quoteApprovalDuration?.median ?? null)
  )

  const responseDuration = timeComparison(
    typeof current?.aggregate === 'undefined'
      ? undefined
      : (current?.aggregate?.responseDuration?.median ?? null),
    typeof prev?.aggregate === 'undefined'
      ? undefined
      : (prev?.aggregate?.responseDuration?.median ?? null)
  )

  const requestApprovalDuration = timeComparison(
    typeof current?.aggregate === 'undefined'
      ? undefined
      : (current?.aggregate?.requestApprovalDuration?.median ?? null),
    typeof prev?.aggregate === 'undefined'
      ? undefined
      : (prev?.aggregate?.requestApprovalDuration?.median ?? null)
  )

  return (
    <ChartBreakdownLayout
      error={!!error}
      scorecards={
        <div className="flex flex-col grow gap-y-6">
          <Scorecards
            className="max-w-screen-sm flex grow"
            scorecards={[
              {
                name: StandardColumns.requestLifespanTitle,
                tooltip: StandardColumns.requestLifespanTooltip,
                value:
                  typeof current?.aggregate === 'undefined'
                    ? undefined
                    : (current.aggregate.lifespanDuration?.median ?? null),
                fromValue:
                  typeof prev?.aggregate === 'undefined'
                    ? undefined
                    : (prev.aggregate.lifespanDuration?.median ?? null),
                formatValue: TimeM.secondsToString,
                downIsGood: true,
                active: form.metric === 'request fulfillment time',
                onClick: () => toggleMetric('request fulfillment time'),
              },
              {
                name: StandardColumns.timeSavingsTitle,
                tooltip: StandardColumns.timeToQuoteTooltip,
                value:
                  typeof current?.aggregate === 'undefined'
                    ? undefined
                    : (current.aggregate.timeSavings?.sum ?? null),
                fromValue:
                  typeof prev?.aggregate === 'undefined'
                    ? undefined
                    : (prev.aggregate.timeSavings?.sum ?? null),
                formatValue: TimeM.secondsToString,
                active: form.metric === 'time savings',
                onClick: () => toggleMetric('time savings'),
              },
            ]}
          />
          {/* Request Timeline */}
          {form.metric !== 'time savings' && (
            <div className="flex flex-col grow gap-y-4">
              <div className="px-6 py-4 w-full border border-gray-300 rounded-xl bg-white shadow-sm">
                <Timeline
                  showReviewStep={organization.requestApproval}
                  reviewRequest={{
                    data: requestApprovalDuration,
                    active: form.metric === 'time to review request',
                    onClick: () => toggleMetric('time to review request'),
                  }}
                  receiveQuote={{
                    data: responseDuration,
                    active: form.metric === 'response time',
                    onClick: () => toggleMetric('response time'),
                  }}
                  reviewQuote={{
                    data: quoteApprovalDuration,
                    active: form.metric === 'review quotes time',
                    onClick: () => toggleMetric('review quotes time'),
                  }}
                  orderProcessing={{
                    data: orderFulfillmentDuration,
                    active: form.metric === 'order fulfillment time',
                    onClick: () => toggleMetric('order fulfillment time'),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      }
      titleMetric={
        <TitleMetric
          title={
            form.metric === 'request fulfillment time' ? (
              <StandardColumns.RequestLifespanTooltipTitle />
            ) : form.metric === 'time to review request' ? (
              <StandardColumns.ApproveTimeTooltipTitle />
            ) : form.metric === 'response time' ? (
              <StandardColumns.TimeToQuoteTooltipTitle />
            ) : form.metric === 'review quotes time' ? (
              <StandardColumns.ReviewQuotesTimeTooltipTitle />
            ) : form.metric === 'order fulfillment time' ? (
              <StandardColumns.OrderFulfillmentTimeTooltipTitle />
            ) : (
              <StandardColumns.TimeSavingsTooltipTitle />
            )
          }
          value={
            typeof current?.aggregate === 'undefined'
              ? undefined
              : ((form.metric === 'request fulfillment time'
                  ? current.aggregate.lifespanDuration?.median
                  : form.metric === 'time to review request'
                    ? current.aggregate.requestApprovalDuration?.median
                    : form.metric === 'response time'
                      ? current.aggregate.responseDuration?.median
                      : form.metric === 'review quotes time'
                        ? current.aggregate.quoteApprovalDuration?.median
                        : form.metric === 'order fulfillment time'
                          ? current.aggregate.orderFulfillmentDuration?.median
                          : current.aggregate.timeSavings?.sum) ?? null)
          }
          comparisonValue={
            typeof prev?.aggregate === 'undefined'
              ? undefined
              : ((form.metric === 'request fulfillment time'
                  ? prev?.aggregate?.lifespanDuration?.median
                  : form.metric === 'time to review request'
                    ? prev?.aggregate?.requestApprovalDuration?.median
                    : form.metric === 'response time'
                      ? prev?.aggregate?.responseDuration?.median
                      : form.metric === 'review quotes time'
                        ? prev?.aggregate?.quoteApprovalDuration?.median
                        : form.metric === 'order fulfillment time'
                          ? prev?.aggregate?.orderFulfillmentDuration?.median
                          : prev?.aggregate?.timeSavings?.sum) ?? null)
          }
          downIsGood={form.metric !== 'time savings'}
          valueToDisplay={TimeM.secondsToString}
          duration={form}
        />
      }
      durationInput={
        <DurationInput
          start={form.durationStart}
          end={form.durationEnd}
          durationId={form.durationId}
          onChange={({ start, end, durationId }) =>
            updateForm({ durationStart: start, durationEnd: end, durationId })
          }
        />
      }
      chart={
        <div className="w-full h-full flex flex-col gap-y-6">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="min-h-[30px] w-full flex flex-row justify-end items-center gap-x-2">
              {selectedDate && (
                <Tag onRemove={() => setSelectedDate(undefined)}>
                  {selectedDate.toLocaleString(DateTime.DATE_MED)}
                </Tag>
              )}
              {selectedValue && (
                <Tag onRemove={() => clearSelectedValue()}>{selectedValue.display}</Tag>
              )}
            </div>
          </div>
          {!current?.chart.data ? (
            <Ghost className="w-full h-full flex bg-gray-300" />
          ) : (
            <div className="w-full h-full">
              <ResponsiveLine
                {...durationGraph}
                data={[
                  {
                    id: form.metric,
                    data: current.chart.data,
                    color: Chart.getDataColor(0),
                  },
                  ...(typeof current.chart.dailyAverage !== 'undefined'
                    ? [
                        {
                          id: `Daily average ${form.metric}`,
                          data: current.chart.data.map(({ x }) => ({
                            x,
                            y: current.chart.dailyAverage,
                            noPoint: true,
                          })),
                          color: Chart.SECONDARY_LINE_COLOR,
                        },
                      ]
                    : []),
                  ...(current.chart.selectedCharts?.map((selected, index) => ({
                    ...selected,
                    color: Chart.getDataColor(index + 1),
                  })) || []),
                ].reverse()}
                tooltip={NivoLineTooltip}
                onClick={(point) => {
                  const pointDate = pointToDate(point.data.x)
                  if (selectedDate && pointDate?.equals(selectedDate)) setSelectedDate(undefined)
                  else setSelectedDate(pointDate)
                }}
              />
            </div>
          )}
        </div>
      }
      breakdownTable={
        <div className="flex grow flex-col gap-y-3 bg-white border border-gray-300 rounded-xl shadow-sm overflow-hidden">
          <div className="px-4 flex flex-col gap-y-3">
            <div className="pt-6 flex flex-row items-center gap-x-[18px]">
              <Tabs
                tabs={[
                  { name: 'Vendor' },
                  { name: 'Urgency' },
                  { name: 'Purchaser' },
                  { name: 'Requester' },
                ]}
                selectedTabName={form.tab}
                onTabSelect={(tab) => updateForm({ tab: tab as Tab })}
              />
            </div>
            <span className="text-sm text-gray-900">
              {form.tab === 'Urgency'
                ? getBreakdownSelectMessage(current?.orderedMachineMetrics?.length, 'urgency')
                : form.tab === 'Vendor'
                  ? getBreakdownSelectMessage(current?.orderedStoreMetrics?.length, 'vendor')
                  : form.tab === 'Requester'
                    ? getBreakdownSelectMessage(current?.orderedCreatorMetrics?.length, 'requester')
                    : getBreakdownSelectMessage(
                        current?.orderedPurchaserMetrics?.length,
                        'purchaser'
                      )}
            </span>
          </div>
          <div className="px-4 pb-0.5 overflow-x-scroll overflow-y-scroll">
            {current?.breakdown ? current.breakdown : null}
          </div>
        </div>
      }
      historyTable={
        <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
          <div className="px-4 text-lg text-gray-900 font-medium">
            <RequestsTableHeader
              selectedValue={selectedValue}
              selectedDate={selectedDate}
              form={form}
            />
          </div>
          <div className="px-4 pb-3 max-h-96 overflow-x-scroll overflow-y-scroll">
            <ReportingTable
              data={current?.filteredOrderedRequestMetrics}
              sortBy={{ sortBy: requestsSortBy, setSortBy: setRequestsSortBy }}
              getRowKey={({ requestForQuote }) => requestForQuote.id}
              noResults={<NoResults onClearFilters={clearFilters} />}
              columns={[
                RequestsTable.getRequestColumn(),
                RequestsTable.getCreatedColumn(),
                {
                  header: {
                    key: form.metric,
                    children:
                      form.metric === 'time savings' ? (
                        <StandardColumns.TimeSavingsTooltipTitle />
                      ) : form.metric === 'request fulfillment time' ? (
                        <StandardColumns.RequestLifespanTooltipTitle />
                      ) : form.metric === 'time to review request' ? (
                        <StandardColumns.ApproveTimeTooltipTitle />
                      ) : form.metric === 'response time' ? (
                        <StandardColumns.TimeToQuoteTooltipTitle />
                      ) : form.metric === 'review quotes time' ? (
                        <StandardColumns.ReviewQuotesTimeTooltipTitle />
                      ) : (
                        <StandardColumns.OrderFulfillmentTimeTooltipTitle />
                      ),
                  },
                  // TODO: need a better way to do this (for example, a clearer Number cell or Cost cell abstraction)
                  getValue: (row) =>
                    getReportingTableValue(
                      row,
                      (r) => get(r, getMetricRequestKey(form.metric)) ?? null,
                      TimeM.secondsToString,
                      form.metric !== 'time savings'
                        ? {
                            aggregate: current?.aggregate,
                            getValues: (a) => get(a, getMetricRequestKey(form.metric)) ?? null,
                            downIsGood: true,
                          }
                        : undefined
                    ),
                  sortByField: getMetricRequestKey(form.metric),
                },
                RequestsTable.getCreatorColumn(),
                RequestsTable.getVendorColumn(getUseRequestProcessedStoreOrders(form.metric)),
                RequestsTable.getAssignedUserColumn(),
              ]}
            />
          </div>
        </div>
      }
    />
  )
}

export default Time
