import { QuoteSourceDocument } from '@/buyers/_gen/gql'
import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import { DocumentTextIcon } from '@heroicons/react/outline'

const QuoteFromPdfDisclaimer = ({ document }: { document: QuoteSourceDocument }) => {
  return (
    <Box className="bg-gray-50 border-gray-200 shadow-base p-4 xl:p-6 flex gap-x-4 xl:gap-x-6">
      {document.thumbnailUrl && (
        <img
          src={document.thumbnailUrl}
          className="w-32 h-32 object-cover border border-gray-200 shadow-base rounded-lg overflow-hidden flex-shrink-0"
          alt=""
        />
      )}

      <div className="space-y-1.5">
        <h4 className="text-lg font-medium">Review Original Quote</h4>
        <p className="text-sm">
          We scanned your vendor’s quote and automatically generated the price and availability
          below. You can review the original quote here to confirm the information is correct.
        </p>

        <A.P
          color="blue"
          href={document.documentUrl}
          className="inline-flex items-center gap-x-1"
          size="sm"
          target="_blank"
        >
          <DocumentTextIcon className="h-4 w-4 inline-block" /> View Original Quote
        </A.P>
      </div>
    </Box>
  )
}

export default QuoteFromPdfDisclaimer
