import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import { SearchIcon } from '@heroicons/react/solid'
import Action from '@/gf/components/Action'
import { ManualDetailsQuery, useManualDetailsQuery, useSearchManualQuery } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import { useParams } from 'react-router-dom'
import NotFound from './NotFound'
import Box from '@/gf/components/Box'
import useModal from '@/gf/hooks/useModal'
import ViewComponentModal from './PartsManual/ViewComponentModal'
import SearchInput from '@/gf/components/SearchInput'
import { useRef, useState } from 'react'
import debounce from 'lodash/debounce'
// import GearflowInsight from './PartsManual/GearflowInsight'

type Component = ManualDetailsQuery['manual']['components'][number]

const PartsManual = () => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { orgId } = useSession()
  const client = useGqlClient()
  const { data } = useManualDetailsQuery({ client, variables: { id, orgId } })
  const [search, setSearch] = useState('')
  const { data: searchData } = useSearchManualQuery({ client, variables: { id, search, page: 1 } })

  const componentModal = useModal<{ component: Component; highlighedPart: string | null }>()

  const manual = data?.manual

  const onSearchChanged = useRef(debounce(setSearch, 400)).current

  const openComponent = (componentId: string, partId: string | null) => {
    const component = manual?.components.find((c) => c.id === componentId)

    if (component) {
      componentModal.open({ component, highlighedPart: partId })
    }
  }

  if (manual === null) return <NotFound />

  const title = manual?.title ?? ''

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Knowledge Center', href: '/knowledge-center' },
      { name: title, href: `/parts-manual/${id}` },
    ],
  }

  const categories: Record<string, Component[]> = manual
    ? manual.components.reduce((acc, item) => {
        const categ = item.category ?? 'General'
        return acc[categ] ? { ...acc, [categ]: [...acc[categ], item] } : { ...acc, [categ]: [item] }
      }, {})
    : {}

  const searching = search !== ''

  return (
    <Frame breadcrumbs={breadcrumbs}>
      {componentModal.props && (
        <ViewComponentModal
          open={componentModal.isOpen}
          onClose={componentModal.close}
          component={componentModal.props.component}
          highlighedPart={componentModal.props.highlighedPart}
        />
      )}

      <Page title={title}>
        <SearchInput
          value={search}
          onChange={onSearchChanged}
          autoCompleteOff
          autoFocus
          placeholder="Type to search for parts"
          className="mt-6"
        />

        {searching && searchData && searchData.searchManuals.entries.length > 0 ? (
          <div className="space-y-4 mt-6">
            {/* <GearflowInsight partsManualId={id} question={search} /> */}

            <Box className="space-y-4 p-6 text-base">
              <h3 className="text-2xl font-medium">Search results</h3>

              {searching && searchData.searchManuals.entries.length === 0 ? (
                <p className="text-sm">
                  No results found for the search: <span className="font-medium">{search}</span>
                </p>
              ) : (
                <ul>
                  {searchData.searchManuals.entries.map((r) => (
                    <li key={r.id} className="block border-b mt-2 pb-2 text-sm">
                      <a
                        href={`${r.manual.url}#page=${r.page}`}
                        target="_blank"
                        className="block p-1 hover:bg-gray-50 rounded cursor-pointer"
                      >
                        <span className="font-medium">on page {r.page}</span>{' '}
                        <span
                          className="search-headline "
                          dangerouslySetInnerHTML={{ __html: r.headline }}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          </div>
        ) : (
          <Box className="shadow-base px-6 py-4 mt-6 space-y-6">
            {Object.entries(categories).length === 0 ? (
              <hgroup className="space-y-1">
                <h3 className="text-lg font-medium">Parts not found</h3>
                <p className="text-base">
                  We were not able to extract pars from this manual but you can always use the
                  search bar to find what you are looking for in the document.
                </p>
              </hgroup>
            ) : (
              Object.entries(categories).map(([categoryName, components]) => (
                <section key={categoryName}>
                  <h3 className="capitalize font-medium text-xl">
                    {categoryName.toLocaleLowerCase()}
                  </h3>

                  <Table className="mt-2">
                    <Thead>
                      <Tr>
                        <Th>Number</Th>
                        <Th>Name</Th>
                        <Th>Serial Number</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {components.map((c) => (
                        <Tr key={c.id}>
                          <Td>{c.number}</Td>
                          <Td>{c.name}</Td>
                          <Td>{c.serialNumber}</Td>
                          <Td>
                            <Action.T
                              className="flex items-center gap-x-1 cursor-pointer"
                              onClick={() => openComponent(c.id, null)}
                            >
                              <SearchIcon className="inline-block h-4 w-4" /> View
                            </Action.T>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </section>
              ))
            )}
          </Box>
        )}
      </Page>
    </Frame>
  )
}

export default PartsManual
