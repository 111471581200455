import { GraphQLError } from 'graphql'
import { useNavigate } from 'react-router-dom'

import { useDeleteVendorMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import ModalForm from '@/gf/components/ModalForm'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

const DeleteVendorModal = ({
  vendorId,
  vendorName,
  open,
  onClose,
}: {
  vendorId: string
  vendorName: string
  open: boolean
  onClose: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [deleteVendor] = useDeleteVendorMutation({ client: useGqlClient() })
  const navigate = useNavigate()

  const onSubmit = () => {
    spinner.on()
    deleteVendor({
      variables: { vendorId },
    })
      .then(() => {
        msgr.add('Vendor removed successfully.', 'positive')
        navigate('/vendors')
      })
      .catch((err: GraphQLError) => {
        if (err.message) msgr.add(err.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(() => {
        spinner.off()
        onClose()
      })
  }

  return (
    <ModalForm
      title="Remove Vendor"
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      submitButtonText="Remove"
      submitButtonShowSpinner={spinnerLive}
      submitButtonDisabled={spinnerLive}
      submitButtonClassName="bg-red-600 hover:bg-red-700"
    >
      <div className="space-y-4">
        <p className="prose">
          Are you sure you want to remove <span className="font-bold">{vendorName}</span>?
        </p>
        <p className="prose text-sm text-slate-600">
          This will remove the vendor from your account, but their activity history and contacts
          will remain in the system.
        </p>
      </div>
    </ModalForm>
  )
}

export default DeleteVendorModal
