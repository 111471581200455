import { useState } from 'react'
import { DateTime } from 'luxon'
import Action from '@/gf/components/Action'
import RadioInput from '@/gf/components/next/forms/RadioInput'
import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/gf/components/ui/dialog'
import { useSaveSpectrumConfigMutation, useSpectrumConfigQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'

const ExportOrdersModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const client = useGqlClient()

  const [saveConfig] = useSaveSpectrumConfigMutation({ client })

  const [exportDurationId, setExportDurationId] = useState(defaultDurationId)
  const [exportDurationStart, setExportDurationStart] = useState<DateTime<true>>(
    defaultDurationDates.start
  )
  const [exportDurationEnd, setExportDurationEnd] = useState<DateTime<true>>(
    defaultDurationDates.end
  )
  const [format, setFormat] = useState<'standard' | 'spectrum'>('standard')
  const [batchCode, setBatchCode] = useState('')
  const [jobCode, setJobCode] = useState('')

  useSpectrumConfigQuery({
    client,
    onCompleted: (data) => {
      setBatchCode(data.spectrumConfig?.batchCode ?? '')
      setJobCode(data.spectrumConfig?.jobCode ?? '')
    },
  })

  const handleExportDurationChange = ({
    durationId,
    start,
    end,
  }: {
    durationId: string
    start: DateTime<true>
    end: DateTime<true>
  }) => {
    setExportDurationId(durationId)
    setExportDurationStart(start)
    setExportDurationEnd(end)
  }

  const handleDownload = async () => {
    if (format === 'spectrum') {
      await saveConfig({ variables: { batchCode, jobCode } })
    }

    onClose()

    // Format dates as ISO-8601 standard (YYYY-MM-DD) for the backend
    const startDateStr = exportDurationStart.toISODate()
    const endDateStr = exportDurationEnd.toISODate()

    // Create URL with query parameters for the download
    const downloadUrl = `/csv/download/orders?format=${format}&start_date=${startDateStr}&end_date=${endDateStr}`

    // Open the URL in a new tab/window to trigger the download
    window.open(downloadUrl, '_blank')
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[550px]">
        <DialogHeader>
          <DialogTitle>Download Orders</DialogTitle>
          <DialogDescription className="text-base pt-2">
            Download a CSV file with your orders placed during the specified time period
          </DialogDescription>
        </DialogHeader>

        <div className="my-4 space-y-6">
          <DurationInput
            start={exportDurationStart}
            end={exportDurationEnd}
            durationId={exportDurationId}
            onChange={handleExportDurationChange}
            showDates={true}
          />

          <div className="space-y-3">
            <label className="font-medium text-base">CSV Format</label>

            <div className="flex gap-x-6">
              <label className="flex gap-x-2">
                <RadioInput
                  checked={format === 'standard'}
                  onClick={() => setFormat('standard')}
                  name="format"
                />
                Standard
              </label>
              <label className="flex gap-x-2">
                <RadioInput
                  checked={format === 'spectrum'}
                  onClick={() => setFormat('spectrum')}
                  name="format"
                />
                Spectrum
              </label>
            </div>
          </div>
        </div>

        {format === 'spectrum' && (
          <div className="flex gap-x-8">
            <Field label="Batch Code">
              <TextInput
                value={batchCode}
                onChange={(e) => setBatchCode(e.target.value)}
                className="max-w-32"
                required
              />
            </Field>
            <Field label="Job Code">
              <TextInput
                value={jobCode}
                onChange={(e) => setJobCode(e.target.value)}
                className="max-w-32"
                required
              />
            </Field>
          </div>
        )}

        <DialogFooter className="flex flex-row-reverse sm:flex-row sm:justify-end gap-3 sm:gap-2 mt-6">
          <Action.S onClick={onClose}>Cancel</Action.S>
          <Action.P onClick={handleDownload}>Download</Action.P>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ExportOrdersModal
