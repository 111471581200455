import {
  ChartBarIcon,
  CursorClickIcon,
  DeviceMobileIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/solid'
import { useState } from 'react'
import { DateTime } from 'luxon'
import useSession from '@/buyers/hooks/useSession'

import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import FreeProTrialModal from './FreeProTrialModal'
import TrialExpiredModal from '@/gf/components/TrialExpiredModal'
import Img from './parts-hub-pro.png'
import SiteUrls from '@/retail/modules/SiteUrls'

const UpgradeButtons = () => {
  const [expiredTrialModalOpen, setExpiredTrialModalOpen] = useState(false)
  return (
    <>
      <TrialExpiredModal
        open={expiredTrialModalOpen}
        onClose={() => setExpiredTrialModalOpen(false)}
      />
      <A.P href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}>Schedule a Demo</A.P>
    </>
  )
}
const Buttons = () => {
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useState(false)
  return (
    <>
      <FreeProTrialModal
        open={isFreeProTrialModalOpen}
        onClose={() => setIsFreeProTrialModalOpen(false)}
        onClaimed={() => {
          setIsFreeProTrialModalOpen(false)
          return Promise.resolve()
        }}
      />
      <A.S href={SiteUrls.BUYER_SCHEDULE_DEMO_URL} target="_blank">
        Book a Demo
      </A.S>
      <Action.P onClick={() => setIsFreeProTrialModalOpen(true)}>Try It Now!</Action.P>
    </>
  )
}

const PartHubProPitch = () => {
  const { organization } = useSession()
  const inTrialPeriod = organization.buyerDashboardAccess === true
  const trialExpirationDate = organization.buyerDashboardAccessExpiresAt ?? undefined
  const expiresAt = DateTime.fromISO(`${trialExpirationDate}`).endOf('day')
  const { days } = expiresAt.diff(DateTime.now().endOf('day'), ['days'])
  const totalDays = days > 0.1 ? Math.ceil(days) : Math.round(days)
  const trialExpired = totalDays <= 0
  const hasExpiredTrial = inTrialPeriod && trialExpired

  return (
    <div className="bg-gradient-to-bl from-neutral-100 to-white rounded-lg border-x-1 border-b-1 border-gray-200 drop-shadow-sm">
      <Card.Section>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="space-y-6 px-8 py-6">
            <div className="text-2xl">Parts Hub Pro</div>
            <p className="prose text-lg">
              Manage all of your parts ordering in one place and reduce your parts-related costs by
              80%.
            </p>
            <ul className="space-y-4">
              <li className="prose text-lg flex gap-3 items-center leading-6">
                <span className="bg-gearflow border-1 rounded-full p-2 leading-6">
                  <CursorClickIcon className="text-white w-6 h-6" />
                </span>{' '}
                <span>Make one-click requests to your local vendors</span>
              </li>
              <li className="prose text-lg flex gap-3 items-center leading-6">
                <span className="bg-gearflow border-1 rounded-full p-2">
                  <DeviceMobileIcon className="text-white w-6 h-6" />
                </span>{' '}
                <span>Submit requests from the field via the Parts Hub Pro app</span>
              </li>
              <li className="prose text-lg flex gap-3 items-center leading-6">
                <span className="bg-gearflow border-1 rounded-full p-2">
                  <TruckIcon className="text-white w-6 h-6" />
                </span>{' '}
                <span>Book same-day deliveries to your job site&mdash;no more parts runs!</span>
              </li>
              <li className="prose text-lg flex gap-3 items-center leading-6">
                <span className="bg-gearflow border-1 rounded-full p-2">
                  <UserGroupIcon className="text-white w-6 h-6" />
                </span>{' '}
                <span>Collaborate across your team for streamlined workflows</span>
              </li>
              <li className="prose text-lg flex gap-3 items-center leading-6">
                <span className="bg-gearflow border-1 rounded-full p-2">
                  <ChartBarIcon className="text-white w-6 h-6" />
                </span>{' '}
                <span>
                  Get advanced cost and performance insights to seize margin opportunities in real
                  time
                </span>
              </li>
            </ul>
          </div>
          <div className="mt-8">
            <img src={Img} alt="Parts Hub Pro" />
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        <div className="flex gap-4 justify-center">
          {inTrialPeriod && hasExpiredTrial ? <UpgradeButtons /> : <Buttons />}
        </div>
      </Card.Section>
    </div>
  )
}

PartHubProPitch.Buttons = Buttons

export default PartHubProPitch
