import { ReactNode } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

const TextTag = ({
  children,
  className,
  onRemoveClick,
  color = 'blue',
}: {
  children: ReactNode
  className?: string
  onRemoveClick?: () => void
  color?: 'blue' | 'red' | 'green' | 'orange' | 'gray'
}) => (
  <span
    className={twMerge(
      `${className} px-2 py-1 text-xs font-medium border rounded-[0.25rem] inline-flex gap-x-1 items-center`,
      color === 'blue' && 'text-blue-700 border-blue-200 bg-blue-100',
      color === 'red' && 'text-red-700 border-red-200 bg-red-100',
      color === 'green' && 'text-green-700 border-green-200 bg-green-100',
      color === 'orange' && 'text-orange-700 border-orange-200 bg-orange-100',
      color === 'gray' && 'text-gray-600 border-gray-200 bg-gray-100'
    )}
  >
    {children}
    {onRemoveClick && (
      <button className="inline-flex" type="button" onClick={onRemoveClick}>
        <XIcon className="-mr-0.5 w-4 h-4 inline-flex shrink-0" />
      </button>
    )}
  </span>
)

export default TextTag
