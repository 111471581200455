import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'

const ConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  text,
}: {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  title: string
  text: string
}) => {
  return (
    <Modal open={open} onClose={onCancel} size={ModalSize.SM}>
      <div className="relative p-6 flex flex-col gap-y-4">
        <CloseModalButton onClick={onCancel} className="absolute top-3 right-3" />

        <h3 className="text-2xl font-medium">{title}</h3>
        <p className="text-lg">{text}</p>

        <div className="flex gap-x-4 pt-4">
          <Action.S onClick={onCancel} className="flex-1">
            Cancel
          </Action.S>
          <Action.P onClick={onConfirm} className="flex-1" color="blue">
            Confirm
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
