import { useSessionQuery } from '@/buyers/_gen/gql'
import ReloadSessionContext from '@/buyers/contexts/ReloadSession'
import type { AuthenticatedSession } from '@/buyers/contexts/SessionContext'
import SessionContext from '@/buyers/contexts/SessionContext'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useConfig from '@/gf/hooks/useConfig'
import useIsMobile from '@/gf/hooks/useIsMobile'
import { updatePartsHubSession } from '@/gf/modules/Analytics'
import { useRollbarPerson } from '@rollbar/react'
import toPairs from 'lodash/toPairs'
import { ReactNode, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import useOrgId from '../hooks/useOrgId'
import useTrackUserIsActive from '../hooks/useTrackUserIsActive'

declare const window: Window & { hj?: (fn: 'identify', userId: string, attrs: object) => unknown }

// Map parts hub website paths to parts hub mobile app paths
// Match the path variables from left to right
// For example, :rfqId from the website (left) will fill in :rfqId for the mobile app (right)
const websiteToMobile = [
  ['/', '/'],
  ['/rfqs', '/requests'],
  ['/rfqs/:rfqId/messages/:conversationId', '/requests/conversation/:conversationId'],
  ['/rfqs/:rfqId/*', '/requests/:rfqId'],
  ['/orders/:storeOrderId/*', '/requests/order/:storeOrderId'],
  ['/inbox/messages/:conversationId', '/inbox/:conversationId'],
]

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const config = useConfig()
  const client = useGqlClient()
  const { data, previousData, refetch } = useSessionQuery({ client })
  const session = (data || previousData)?.session
  const { org, switchOrg } = useOrgId(session?.user?.orgs)
  useRollbarPerson({ email: session?.user?.email })

  // Don't prompt to open the mobile app after you've already prompted it
  const [promptedMobile, setPromptedMobile] = useState(false)
  const { pathname } = useLocation()
  const isMobile = useIsMobile()

  useEffect(() => {
    // Setup Hotjar
    if (window.hj && session?.user && org) {
      const userName = session.user.displayName
      const companyName = org.name ?? null
      const role = session.user.userRole?.name ?? null

      if (!session.xrayUserId) {
        window.hj('identify', session.user.id, {
          user_name: userName,
          company_name: companyName,
          role,
          xray: false,
        })
      } else {
        window.hj('identify', `${session.xrayUserId}:${session.user.id}`, {
          user_name: `(xray) ${userName ?? ''}`,
          company_name: `(xray) ${companyName ?? ''}`,
          role: `(xray) ${role ?? ''}`,
          xray: true,
        })
      }
    }

    // Setup GA session
    if (session?.user && org) {
      updatePartsHubSession(
        org.id,
        org.name,
        session.user.id,
        session.user.displayName,
        session.user.userRole?.name,
        !!session.xrayUserId
      )
    }
  }, [session])

  // Redirect to mobile app
  useEffect(() => {
    if (!session?.featureFlags.mobileAppRedirect) return
    if (!isMobile || promptedMobile) return

    const websiteToMobileResult = websiteToMobile.find(
      ([website, _]) => !!matchPath(website, pathname)
    )
    if (websiteToMobileResult) {
      const [website, mobile] = websiteToMobileResult
      const match = matchPath(website, pathname)
      if (match) {
        const mobilePath = toPairs(match.params).reduce(
          (path, [paramKey, paramValue]) =>
            paramValue ? path.replace(`:${paramKey}`, paramValue) : path,
          mobile
        )
        // TODO: use a URL that automatically logs you in
        const mobileUrl = `${config.buyersMobileAppUrl}${mobilePath}`

        // Open the app
        window.location.href = mobileUrl
        // Don't prompt to open the app again if the user is navigating around the web page
        setPromptedMobile(true)
        // TODO: Prompt to download the app if they don't have it installed
      }
    }
  }, [session, pathname, isMobile])

  useTrackUserIsActive(session)

  if (!session) return null

  // public url
  if (window.location.pathname.startsWith('/~/')) {
    const unauthenticatedSession = {
      ...session,
      user: null as unknown as AuthenticatedSession['user'],
      orgId: null as unknown as string,
      organization: null as unknown as AuthenticatedSession['organization'],
      pendingInvites: [],
      switchOrg,
    }

    return (
      <ReloadSessionContext.Provider value={refetch}>
        <SessionContext.Provider value={unauthenticatedSession}>{children}</SessionContext.Provider>
      </ReloadSessionContext.Provider>
    )
  }

  // authenticated but org not auto selected
  if (session.user && !org) return null

  // unauthenticated
  if (!session.user || !org) {
    window.location.href = `${config.gfBaseUrl}/login?return_path=${encodeURIComponent(
      window.location.href
    )}`

    return null
  }

  // rebuild session as a hack to convince TS it's an AuthenticatedSession
  const authenticatedSession = {
    ...session,
    user: session.user,
    orgId: org.id,
    organization: org,
    switchOrg,
  }

  return (
    <ReloadSessionContext.Provider value={refetch}>
      <SessionContext.Provider value={authenticatedSession}>{children}</SessionContext.Provider>
    </ReloadSessionContext.Provider>
  )
}

export default SessionProvider
