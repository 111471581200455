import { useMemo, useState } from 'react'
import { Maybe } from '@/types'
import { JobAutoCompleteQuery, useJobAutoCompleteQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'

const jobLabel = (j: JobAutoCompleteQuery['jobAutocomplete'][number]) => `${j.code} - ${j.name}`

const JobSelector = ({
  value,
  onChange,
}: {
  value: Maybe<string>
  onChange: (value: string) => void
}) => {
  const client = useGqlClient()
  const [search, setSearch] = useState('')
  const { data } = useJobAutoCompleteQuery({ client, variables: { search, ids: null } })
  const { data: selectedData } = useJobAutoCompleteQuery({
    client,
    variables: { ids: [value!], search: null },
    skip: !value,
  })

  const options = useMemo(() => {
    if (!data) {
      return data
    }

    return data.jobAutocomplete.map((j) => ({ value: j.id, label: jobLabel(j) }))
  }, [data])

  const selectedOption = useMemo(() => {
    if (!value) {
      return null
    }

    const selected = selectedData?.jobAutocomplete[0]
    return { value, label: selected ? jobLabel(selected) : '' }
  }, [value, selectedData])

  return (
    <Select
      options={options}
      value={selectedOption}
      onInputChange={setSearch}
      className="text-sm"
      onChange={(o) => {
        if (o?.value) {
          onChange(o.value)
        }
      }}
    />
  )
}

export default JobSelector
