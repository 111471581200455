import { SortOrder, useMachinePartsHistoryQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { Table, Td, Th, Tr } from '@/gf/components/next/Table'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import Spinner from '@/gf/components/Spinner'
import useToggle from '@/gf/hooks/useToggle'
import { ModalSize } from '@/types'
import { CheckIcon } from '@heroicons/react/solid'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

const ViewMachinePartsHistory = ({
  orgMachineId,
  onAddPart,
  addedPartNumbers,
}: {
  orgMachineId: string
  onAddPart: (partNumber: string, partDescription: string) => void
  addedPartNumbers: string[]
}) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [open, toggle] = useToggle()
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { data, previousData, variables } = useMachinePartsHistoryQuery({
    client,
    variables: {
      orgId,
      orgMachineId,
      page,
      sortBy: { field: 'last_requested_at', order: SortOrder.Desc },
      search: searchTerm,
    },
  })

  const onSearchChanged = useCallback(
    debounce((text: string) => {
      setSearchTerm(text)
      setPage(1)
    }, 300),
    []
  )

  const totalResults = (data || previousData)?.periodicPartRequests.pagination.totalResults ?? 0
  if ((!data && !previousData) || (totalResults === 0 && !open)) {
    return null
  }

  return (
    <>
      <Action.T onClick={toggle.on} className="text-sm font-medium pt-1">
        View Parts List
      </Action.T>

      <Modal open={open} onClose={toggle.off} size={ModalSize.MD}>
        <div className="p-6 relative flex flex-col gap-y-4">
          <h4 className="text-xl font-medium">Parts History</h4>

          <CloseModalButton className="absolute top-3 right-3" onClick={toggle.off} />

          <div>
            <SearchInput
              value={searchTerm}
              onChange={onSearchChanged}
              placeholder="Search by part number or part name"
              autoFocus
              autoCompleteOff
            />
          </div>
          <div className="max-h-[70vh] overflow-y-auto scroll-shadows fancy-scroll">
            <Table className="text-sm">
              <thead>
                <Tr>
                  <Th>Part Number</Th>
                  <Th>Part Name</Th>
                  <Th>Last Request</Th>
                  <Th className="w-24" />
                </Tr>
              </thead>
              <tbody>
                {!data ? (
                  <Tr>
                    <Td className="py-8" colSpan={4}>
                      <Spinner />
                    </Td>
                  </Tr>
                ) : (
                  <>
                    {data?.periodicPartRequests.entries.map((p) => (
                      <Tr key={p.id}>
                        <Td>{p.partNumber}</Td>
                        <Td>{p.partDescription} </Td>
                        <Td>{p.lastRequestedAt.toLocaleString(DateTime.DATE_SHORT)} </Td>
                        <Td>
                          {addedPartNumbers
                            .map((pn) => pn.toLowerCase())
                            .includes(p.partNumber.toLowerCase()) ? (
                            <CheckIcon className="inline-block h-5 w-5 text-green-500" />
                          ) : (
                            <Action.T
                              onClick={() => onAddPart(p.partNumber, p.partDescription)}
                              className="text-sm font-medium no-underline"
                            >
                              Add Part
                            </Action.T>
                          )}
                        </Td>
                      </Tr>
                    ))}
                    {data.periodicPartRequests.entries.length === 0 && variables?.search && (
                      <Tr>
                        <Td className="text-gray-700" colSpan={4}>
                          No results for{' '}
                          <span className="text-gray-900 font-medium">{variables.search}</span>
                        </Td>
                      </Tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
          <PaginationC
            page={page}
            pagination={(data ?? previousData)?.periodicPartRequests.pagination}
            updatePage={setPage}
          />
        </div>
      </Modal>
    </>
  )
}

export default ViewMachinePartsHistory
