import { useEffect, useState } from 'react'
import { Maybe, ModalSize } from '@/types'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/next/forms/Field'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import CostCodeSelector from '@/buyers/components/CostCodeSelector'
import { useSetShippingCostCodeMutation } from '@/buyers/_gen/gql'
import { useNavigate } from 'react-router-dom'

const SetShippingCostCodeModal = ({
  storeOrderId,
  costCodeId: initialCostCodeId,
}: {
  storeOrderId: string
  costCodeId: Maybe<string>
}) => {
  const client = useGqlClient()
  const navigate = useNavigate()
  const [updateMutation] = useSetShippingCostCodeMutation({ client })
  const [_, msgs] = useMsgs()
  const [performing, performingToggle] = useToggle()
  const [costCodeId, setCostCodeId] = useState<Maybe<string>>(initialCostCodeId)

  useEffect(() => {
    if (!open) {
      setCostCodeId(initialCostCodeId)
      performingToggle.off()
    }
  }, [open, initialCostCodeId])

  const navigateToStoreOrder = () => navigate(`/orders/${storeOrderId}`, { replace: true })

  const onSubmit = async () => {
    try {
      performingToggle.on()

      await updateMutation({ variables: { storeOrderId: storeOrderId, costCodeId: costCodeId } })

      msgs.add('Cost Code saved.', 'positive')

      navigateToStoreOrder()
      client.reFetchObservableQueries()
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      performingToggle.off()
    }
  }

  return (
    <Modal open onClose={navigateToStoreOrder} size={ModalSize.SM}>
      <div className="p-8 relative flex flex-col gap-y-6">
        <CloseModalButton onClick={navigateToStoreOrder} className="absolute top-3 right-3" />

        <h3 className="text-2xl font-medium">Select Cost Code for Shipping</h3>

        <Field label="Cost Code">
          <CostCodeSelector value={costCodeId} onChange={setCostCodeId} />
        </Field>

        <div className="flex gap-x-4">
          <Action.S onClick={navigateToStoreOrder} className="flex-1 text-base font-medium">
            Cancel
          </Action.S>
          <Action.P
            onClick={onSubmit}
            performing={performing}
            color="blue"
            className="flex-1 text-base font-medium"
          >
            Update
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default SetShippingCostCodeModal
