import { StoreOrder } from '@/buyers/pages/LimitedOrder'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Money from '@/gf/modules/Money'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ArrowCircleLeftIcon, CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { useOrderContext, willSomeNotShip } from '../../context'
import CircleProgress from '../CircleProgress'
import Availability from './Availability'
import JobNumbersAction from './JobNumbersAction'
import CostCodeAction from './CostCodeAction'

const PartsTable = ({
  title,
  canSelectParts,
  className,
  quickOrder,
  jobNumbersEnabled,
  costCodesEnabled,
  ...props
}: {
  title: ReactNode
  items: StoreOrder['lineItems']
  canSelectParts: boolean
  className?: string
  quickOrder: boolean
  jobNumbersEnabled: boolean
  costCodesEnabled: boolean
}) => {
  const { storeOrder, rejectedLineItemIds, toggleRejectedLineItemId } = useOrderContext()
  const someWillNotShip = willSomeNotShip(storeOrder)

  const items = props.items.map((i) => {
    const refundedQuantity = someWillNotShip ? i.quantity - i.shippedQuantity : 0
    return { ...i, refundedQuantity, receivableQuantity: i.quantity - refundedQuantity }
  })

  const showPrices = !quickOrder || items.some((i) => i.extendedPrice.amount > 0)
  const showAvailability = showPrices || items.some((i) => i.receivedQuantity > 0)

  return (
    <div className={className}>
      <h2 className="text-lg font-medium flex items-center gap-x-1.5">{title}</h2>

      <div className="w-full overflow-x-auto fancy-scroll">
        <table className="w-full text-sm lg:text-base text-left border-b border-gray-200">
          <thead className="hidden lg:table-header-group border-b font-medium text-gray-500">
            <tr className="h-16 divide-x border-gray-200 [&>th]:align-middle">
              {jobNumbersEnabled && <th className="pr-3 py-2 w-28 min-w-28">Job Numbers</th>}
              {costCodesEnabled && <th className="pr-3 py-2 w-28 min-w-28">Cost Code</th>}
              <th
                className={classNames(
                  'py-2 w-28 min-w-28',
                  jobNumbersEnabled || costCodesEnabled ? 'px-3' : 'pr-3'
                )}
              >
                Part Number
              </th>
              <th className="px-3 py-2">Description</th>
              <th className="px-3 py-2 w-16">Qty</th>
              {showPrices && (
                <>
                  <th className="px-3 py-2 w-24">Price</th>
                  <th className="px-3 py-2 w-30">Total</th>
                </>
              )}
              {showAvailability && <th className="py-2 pl-3 min-w-48">Availability</th>}
            </tr>
          </thead>

          <tbody>
            {items.map((item) => (
              <tr
                key={item.id}
                className={classNames(
                  'border-b last:border-0 flex flex-col gap-y-1.5',
                  'lg:table-row lg:divide-x [&>td]:align-middle'
                )}
              >
                {jobNumbersEnabled && (
                  <td
                    className={classNames(
                      "grid grid-cols-[12ch_auto] before:content-['Job_Numbers'] before:font-medium pt-4 break-all",
                      'lg:table-cell lg:before:content-none lg:py-2 lg:pr-3'
                    )}
                  >
                    <JobNumbersAction lineItem={item} />
                  </td>
                )}

                {costCodesEnabled && (
                  <td
                    className={classNames(
                      "grid grid-cols-[12ch_auto] before:content-['Cost_Code'] before:font-medium pt-4 break-all",
                      'lg:table-cell lg:before:content-none lg:py-2 lg:pr-3'
                    )}
                  >
                    <CostCodeAction lineItem={item} />
                  </td>
                )}
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Part_Number'] before:font-medium pt-4 break-all",
                    'lg:table-cell lg:before:content-none lg:py-2',
                    jobNumbersEnabled || costCodesEnabled ? 'lg:px-3' : 'lg:pr-3'
                  )}
                >
                  {items.length > 1 && canSelectParts && (
                    <SimpleTooltip
                      text={
                        rejectedLineItemIds.includes(item.id)
                          ? 'Add back to quote'
                          : 'Remove from quote'
                      }
                      placement="bottom"
                    >
                      <Checkbox
                        checked={!rejectedLineItemIds.includes(item.id)}
                        onChange={() => toggleRejectedLineItemId(item.id)}
                        className="mr-2 focus:ring-0 focus:ring-white cursor-pointer"
                      />
                    </SimpleTooltip>
                  )}
                  {item.rfqPart && item.rfqPart.mpn && item.rfqPart.mpn !== item.product.mpn ? (
                    <SimpleTooltip text={`Requested: ${item.rfqPart.mpn}`} placement="bottom">
                      <span className="flex items-center">
                        {item.product.mpn}{' '}
                        <InformationCircleIcon className="ml-1 inline-block w-4 h-4 text-gray-700" />
                      </span>
                    </SimpleTooltip>
                  ) : (
                    <>{item.product.mpn}</>
                  )}
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Description'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  <SimpleTooltip
                    placement="bottom-start"
                    text={item.product.name || ''}
                    className="lg:max-w-56"
                  >
                    <span className="inline-block lg:max-w-[20ch] lg:truncate lg:text-ellipsis leading-5 text-left">
                      {item.product.name}
                    </span>
                  </SimpleTooltip>
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Quantity'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  {item.quantity}
                </td>
                {showPrices && (
                  <>
                    <td
                      className={classNames(
                        "grid grid-cols-[12ch_auto] before:content-['Unit_Price'] before:font-medium",
                        'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                      )}
                    >
                      {!Money.equals(item.unitPrice, item.discountedUnitPrice) && (
                        <span className="line-through text-gray-500 ">
                          {Money.format(item.unitPrice)}
                        </span>
                      )}

                      <span>{Money.format(item.discountedUnitPrice)}</span>
                    </td>
                    <td
                      className={classNames(
                        "grid grid-cols-[12ch_auto] before:content-['Total'] before:font-medium ",
                        'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                      )}
                    >
                      {Money.format(item.extendedPrice)}
                    </td>
                  </>
                )}
                {showAvailability && (
                  <td className="block lg:table-cell pb-4 lg:py-2 lg:px-3">
                    <div className="flex flex-col space-y-0.5">
                      {item.receivedQuantity === 0 && item.receivableQuantity !== 0 && (
                        <Availability lineItem={item} />
                      )}

                      {item.receivedQuantity > 0 &&
                        item.receivedQuantity < item.receivableQuantity && (
                          <div className="flex gap-1.5 items-center text-gray-700 text-sm font-medium">
                            <CircleProgress
                              progress={(item.receivedQuantity / item.receivableQuantity) * 100}
                              className="w-4 h-4"
                            />
                            {item.receivedQuantity.toLocaleString()}/
                            {item.receivableQuantity.toLocaleString()} received
                          </div>
                        )}

                      {item.receivedQuantity >= item.receivableQuantity && (
                        <div className="flex gap-1 items-center text-gray-700 text-sm font-medium">
                          <CheckCircleIcon className="-p-0.5 w-5 h-5 inline-flex shrink-0 text-blue-600" />
                          {item.refundedQuantity > 0 ? item.receivedQuantity : 'All'} received
                        </div>
                      )}

                      {item.refundedQuantity > 0 && (
                        <SimpleTooltip
                          text={`${
                            item.refundedQuantity === 1
                              ? 'This part was'
                              : `These ${item.refundedQuantity} parts were`
                          } refunded, so ${
                            item.refundedQuantity === 1 ? 'it' : 'they'
                          } won't be shipped.`}
                        >
                          <div className="flex gap-1 items-center text-gray-700 text-sm font-medium">
                            <ArrowCircleLeftIcon className="-p-0.5 w-5 h-5 inline-flex shrink-0 text-red-700" />
                            {item.refundedQuantity.toLocaleString()} refunded
                          </div>
                        </SimpleTooltip>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PartsTable
