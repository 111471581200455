import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import nth from 'lodash/nth'
import pick from 'lodash/pick'

import {
  Address,
  AddressSuggestion,
  AutocompleteSuggestion,
  InternationalAddressSuggestion,
} from '../../types'

import Smarty from '@/suppliers/modules/Smarty'
import CountryStates from '@/suppliers/modules/CountryStates'
import GoogleMaps from '@/gf/modules/GoogleMaps'

import Combobox from '@/gf/components/next/Combobox'

type SmartySuggestion = {
  type: 'smarty'
  suggestion: AddressSuggestion | InternationalAddressSuggestion
}

type GoogleMapsSuggestion = {
  type: 'maps'
  suggestion: google.maps.places.AutocompletePrediction
}

interface AddressOption {
  label: string
  value: SmartySuggestion | GoogleMapsSuggestion
}

interface Props {
  value: Address | null
  onAddressSelected: (address: Address) => void
  onAddressRemoved: () => void
  onAddressNotFound: (addressInput: string) => void
}

const createLabel = (address: Address): string => {
  const addressLine = [address.lineOne || '', address.lineTwo || '', address.city || '']
    .join(' ')
    .trim()
  const statePostalCode = [address.state, address.postalCode || ''].join(' ').trim()
  return `${addressLine}, ${statePostalCode}`
}

const smartySuggestionsToOptions = (suggestions: AutocompleteSuggestion[]): AddressOption[] =>
  suggestions.map(({ display, suggestion }) => ({
    label: display,
    value: { type: 'smarty', suggestion },
  }))

const addressFromSuggestion = async (
  suggestion: AddressSuggestion | google.maps.places.AutocompletePrediction,
  country: string
) => {
  if ('place_id' in suggestion) {
    const address = await GoogleMaps.getPlaceAddress(suggestion.place_id)
    return address
  }
  return {
    firstName: '',
    lastName: '',
    companyName: '',
    lineOne: suggestion.lineOne,
    lineTwo: suggestion.lineTwo,
    city: suggestion.city,
    postalCode: suggestion.postalCode,
    state: suggestion.state,
    country: CountryStates.getCountryByCode(country)?.name,
  } as Address
}

const createSelectedValue = (address: Address): AddressOption => ({
  label: createLabel(address),
  value: {
    type: 'smarty',
    suggestion: pick(address, [
      'lineOne',
      'lineTwo',
      'city',
      'state',
      'postalCode',
    ]) as AddressSuggestion,
  },
})

const OneLineAddress = ({
  value,
  onAddressSelected,
  onAddressRemoved,
  onAddressNotFound,
}: Props) => {
  const [country, setCountry] = useState('US')
  const international = country !== 'US'
  const [showAutocompleteSuggestions, setShowAutocompleteSuggestions] = useState(false)
  const [selectedValue, setSelectedValue] = useState<AddressOption | null>(() =>
    value ? createSelectedValue(value) : null
  )

  const [queryValue, setQueryValue] = useState('')
  const [options, setOptions] = useState<AddressOption[]>([])

  const loadOptions = async (
    newInputValue: string,
    isInternational: boolean
  ): Promise<AddressOption[]> => {
    if (!newInputValue) return []

    const smartySuggestions: AutocompleteSuggestion[] = await Smarty.autocompleteSearch(
      newInputValue,
      isInternational
    )
    if (smartySuggestions.length > 0) {
      return smartySuggestionsToOptions(smartySuggestions)
    }

    const googleMapsSuggestions = await GoogleMaps.autocompletePlaces(newInputValue, {
      country: [country],
    })
    return googleMapsSuggestions.map((suggestion) => ({
      label: suggestion.description,
      value: { type: 'maps', suggestion },
    }))
  }

  const debouncedLoadOptions = useRef(
    debounce(
      (newInputValue: string, isInternational: boolean) =>
        loadOptions(newInputValue, isInternational).then((newOptions) => setOptions(newOptions)),
      250
    )
  ).current

  useEffect(
    () =>
      // Cleanup any in-progress search requests when component is unmounted
      () => {
        debouncedLoadOptions.cancel()
      },
    [debouncedLoadOptions]
  )

  const onChange = async (option: AddressOption | null) => {
    const suggestion = option?.value.suggestion
    if (option?.value.type === 'smarty' && suggestion && 'addressId' in suggestion) {
      // Narrow down International address (only international addressed have an addressId)
      Smarty.autocompleteSearch(suggestion.addressId, international, '', suggestion.addressId).then(
        async (suggestions) => {
          const internationalAddress = nth(suggestions, 0)?.suggestion
          if (
            suggestions.length === 1 &&
            internationalAddress &&
            'lineOne' in internationalAddress
          ) {
            const address = await addressFromSuggestion(internationalAddress, country)
            onAddressSelected(address)
            setSelectedValue(option)
            setQueryValue('')
            setShowAutocompleteSuggestions(false)
          } else {
            setOptions(smartySuggestionsToOptions(suggestions))
            setQueryValue(suggestion.addressText)
          }
        }
      )
    } else if (
      option?.value.type === 'smarty' &&
      suggestion &&
      'lineOne' in suggestion &&
      'entries' in suggestion &&
      suggestion.entries > 1
    ) {
      // Narrow down the selection
      const selected = `${suggestion.lineOne} (${suggestion.entries}) ${suggestion.city} ${suggestion.state} ${suggestion.postalCode}`
      Smarty.autocompleteSearch(suggestion.lineOne, international, selected).then((suggestions) => {
        setOptions(smartySuggestionsToOptions(suggestions))
        setQueryValue(suggestion.lineOne)
      })
    } else {
      setSelectedValue(option)
      setQueryValue('')
      setShowAutocompleteSuggestions(false)
      if (option) {
        const address = await addressFromSuggestion(
          option.value.suggestion as AddressSuggestion,
          country
        )
        onAddressSelected(address)
      } else {
        onAddressRemoved()
      }
    }
  }

  return (
    <div className="flex items-center border border-slate-300 rounded-lg">
      <select
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        className="border-0 border-r border-slate-300 rounded-l-lg outline-none"
      >
        {CountryStates.countries.map((c) => (
          <option key={c.isoCode} value={c.isoCode}>
            {c.flag}
          </option>
        ))}
      </select>
      <Combobox
        open={showAutocompleteSuggestions}
        hideButton
        containerClassName="w-full ml-px"
        className="border-none shadow-none"
        queryValue={queryValue}
        value={selectedValue}
        options={options}
        onChange={(option) => onChange(option ?? null)}
        onQueryChange={(newQuery) => {
          setShowAutocompleteSuggestions(!!newQuery)
          setQueryValue(newQuery)
          debouncedLoadOptions(newQuery, international)
        }}
        getOptionDisplay={({ label }) => label}
        showAllOptions
        placeholder="Type the address here"
        createOption
        onCreateOptionSelected={onAddressNotFound}
      />
    </div>
  )
}

export default OneLineAddress
