import { Job, useAddJobMutation, useUpdateJobMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { ApolloErrorWithMessages, Maybe, ModalSize } from '@/types'
import { useForm } from 'react-hook-form'

const EditJobNumberModal = ({
  open,
  onClose,
  job,
}: {
  open: boolean
  onClose: () => void
  job: Maybe<Job>
}) => {
  const client = useGqlClient()
  const [_, msgs] = useMsgs()
  const [performing, performingToggle] = useToggle()
  const [addJob] = useAddJobMutation({ client })
  const [updateJob] = useUpdateJobMutation({ client })
  const form = useForm({
    defaultValues: {
      code: job?.code ?? '',
      name: job?.name ?? '',
    },
  })

  const onSubmit = async ({ code, name }) => {
    try {
      performingToggle.on()
      if (!job) {
        await addJob({
          variables: {
            code,
            name,
          },
        })

        msgs.add('Job Number Created!', 'positive')
      } else {
        await updateJob({
          variables: {
            id: job.id,
            code,
            name,
          },
        })

        msgs.add('Job Number Updated!', 'positive')
      }

      onClose()
      client.reFetchObservableQueries()
    } catch (err) {
      console.error(err)
      const serverErrors =
        (err as ApolloErrorWithMessages)?.graphQLErrors
          .map((gqlErr) => gqlErr.fields.map((f) => f.value).flat())
          .flat() ?? []

      if (serverErrors.length > 0) {
        msgs.add(serverErrors[0], 'negative')
      }
    } finally {
      performingToggle.off()
    }
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="relative p-6 flex flex-col gap-y-4">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <h3 className="text-2xl font-medium">
          {job ? `Edit Job Number ${job.code}` : 'Add Job Number'}
        </h3>
        <Field label="Code" error={form.formState.errors.code?.message}>
          <TextInput {...form.register('code', { required: 'Code is required' })} />
        </Field>

        <Field label="Name" error={form.formState.errors.name?.message}>
          <TextInput {...form.register('name', { required: 'Name is required' })} />
        </Field>

        <div className="flex gap-x-4 pt-4">
          <Action.S onClick={onClose} className="flex-1">
            Cancel
          </Action.S>
          <Action.P type="submit" className="flex-1" color="blue" performing={performing}>
            Save
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default EditJobNumberModal
