import { RequestQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'

import useToggle from '@/gf/hooks/useToggle'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import EditPartRequestJobNumbersModal from '../EditPartRequestJobNumbersModal'

const JobNumbersAction = ({
  rfqPart,
}: {
  rfqPart: RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]['partRequests'][number]
}) => {
  const [open, toggle] = useToggle()
  const client = useGqlClient()
  return (
    <>
      <EditPartRequestJobNumbersModal
        open={open}
        onClose={toggle.off}
        part={{
          partNumber: rfqPart.mpn,
          description: rfqPart.description,
          quantity: rfqPart.quantity,
        }}
        partRequestId={rfqPart.id}
        jobQuantities={rfqPart.jobQuantities ?? []}
        onSuccess={() => client.reFetchObservableQueries()}
      />

      {rfqPart.jobQuantities && rfqPart.jobQuantities.length > 0 ? (
        rfqPart.jobQuantities.map((jq) => (
          <span key={jq.job.id} className="block text-sm">
            <Action.T onClick={toggle.on}>
              {jq.job.code} (x{jq.quantity})
            </Action.T>
          </span>
        ))
      ) : (
        <Action.T className="text-sm" onClick={toggle.on}>
          Add
        </Action.T>
      )}
    </>
  )
}

export default JobNumbersAction
