import {
  useJobAutoCompleteQuery,
  useOrgMachineDetailsQuery,
  useOrganizationDetailsQuery,
  useSelectedBranchQuery,
  useSelectedDealerLocationsQuery,
  useSelectedLocationQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Field from '@/gf/components/next/forms/Field'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { PlusIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'
import { Fragment, useMemo, useState } from 'react'
import ImagesList from './ImagesList'
import PreviewEmailButton from './ReviewStep/PreviewEmailButton'
import UpdateBillingAddressModal from './ReviewStep/UpdateBillingAddressModal'
import UpdateLocationModal from './ReviewStep/UpdateLocationModal'
import UpdateOrgDetailsModal from './ReviewStep/UpdateOrgDetailsModal'
import UpdateOrgLogoModal from './ReviewStep/UpdateOrgLogoModal'
import UpdatePhoneModal from './ReviewStep/UpdatePhoneModal'
import StartOverButton from './StartOverButton'
import StickyBottomBar from './StickyBottomBar'
import { PartialRequestUpdate } from './types'
import PONumberModal from './ReviewStep/PONumberModal'

const ChangeDetails = ({
  onChangeClick,
  title,
  text,
}: {
  onChangeClick: () => void
  title?: React.ReactNode
  text: React.ReactNode
}) => (
  <div className="flex items-start">
    <div className="flex-grow text-sm leading-5">
      {title && <h5 className="mb-0.5">{title}</h5>}
      <div className="text-gray-600">{text}</div>
    </div>
    <Action.T type="button" onClick={onChangeClick} className="font-medium text-sm leading-5">
      Change
    </Action.T>
  </div>
)

const ReviewStep = ({
  reset,
  request,
  updateRequest,
  onBackClicked,
  onCreateRequestClicked,
  submitButtonText,
  submitInProgress,
  updateLocationEnabled = true,
}: {
  reset?: () => void
  onBackClicked: () => void
  onCreateRequestClicked: (poNumber?: string) => void
  submitButtonText?: string
  submitInProgress: boolean
  updateLocationEnabled?: boolean
} & PartialRequestUpdate) => {
  const client = useGqlClient()
  const [locationModalOpen, locationModalToggle] = useToggle()
  const [poNumberModalOpen, poNumberModalToggle] = useToggle()
  const [updatePhoneModalOpen, updatePhoneModalToggle] = useToggle()
  const [updateOrgModalOpen, updateOrgModalToggle] = useToggle()
  const [updateOrgLogoModalOpen, updateOrgLogoModalToggle] = useToggle()
  const [updateBillingAddressModalOpen, updateBillingAddressModalToggle] = useToggle()
  const {
    orgId,
    user,
    organization: {
      id: organizationId,
      logoUrl,
      generatePurchaseOrderNumber,
      requestsJobNumbersEnabled,
    },
  } = useSession()

  const [showOrgLogoSection] = useState(!logoUrl)

  const {
    data: orgData,
    loading: _loadingOrg,
    refetch: refetchOrg,
  } = useOrganizationDetailsQuery({
    client,
    variables: { id: organizationId },
  })

  const organization = orgData?.org ?? null

  const { data: locationData, loading: _loadingLocation } = useSelectedLocationQuery({
    client,
    variables: { orgId, id: request.locationId as string },
    skip: !request.locationId,
  })

  const location = locationData?.shippingLocationsSearch.shippingLocations?.[0] ?? null

  const { data: machineData, loading: _loadingMachine } = useOrgMachineDetailsQuery({
    client,
    variables: { id: request.machineOrgId as string },
    skip: !request.machineOrgId,
  })

  const machine = machineData?.orgMachine ?? null

  const { data: branchData, refetch: refetchBranch } = useSelectedBranchQuery({
    client,
    variables: { id: request.billingCompanyId ?? (location?.branch?.id as string) },
    skip: !request.billingCompanyId && !location?.branch,
  })

  const branch = branchData?.branch ?? null

  const billingAddress =
    branch?.billingAddress ??
    location?.billingAddress ??
    organization?.billingAddress ??
    location?.address ??
    request?.shippingAddress

  const { data: selectedVendorsData } = useSelectedDealerLocationsQuery({
    client,
    variables: {
      orgId,
      nearPoint: null,
      ids: request.vendors.map((v) => v.vendorId),
      tutorial: request.tutorial,
    },
    skip: request.vendors.length === 0,
  })

  const vendors = useMemo(
    () =>
      selectedVendorsData
        ? request.vendors.map((reqVendor) => {
            const vendor = selectedVendorsData.dealerLocations.entries.find(
              (dl) => (dl.vendor?.id as string) === reqVendor.vendorId
            )

            return {
              ...vendor,
              contacts: vendor?.contacts.filter((c) => reqVendor.contactIds.includes(c.id)) ?? [],
              accountNumber: reqVendor.accountNumber,
            }
          })
        : [],
    [selectedVendorsData, request.vendors]
  )

  const allSelectedJobIds =
    request.parts?.flatMap((p) => p.jobQuantities.map((jq) => jq.jobId)) ?? []
  const { data: allJobsData } = useJobAutoCompleteQuery({
    client,
    variables: { search: null, ids: allSelectedJobIds },
  })

  const allJobCodes: Record<string, string> = useMemo(() => {
    return (
      allJobsData?.jobAutocomplete.reduce((acc, job) => ({ ...acc, [job.id]: job.code }), {}) ?? {}
    )
  }, [allJobsData])

  const refetchOrgAndBranch = () => {
    refetchOrg()
    if (branch) {
      refetchBranch()
    }
  }

  const onSendRequest = () => {
    const needsPoNumber = request.quickOrder && !generatePurchaseOrderNumber

    if (needsPoNumber) {
      poNumberModalToggle.on()
    } else {
      onCreateRequestClicked()
    }
  }

  if (!organization || !billingAddress || !(location || request.shippingAddress)) {
    return null
  }

  const hasShippingAddressOnly = request.shippingAddress && request.locationId === null
  const locationIdRequired = updateLocationEnabled && !hasShippingAddressOnly

  const submitText =
    submitButtonText ??
    (request.quickOrder
      ? 'Send Order'
      : `Send ${request.vendors.length > 1 ? 'All Requests' : 'Request'}`)

  return (
    <>
      <PONumberModal
        open={poNumberModalOpen}
        onClose={poNumberModalToggle.off}
        onPoNumberConfirmed={(poNumber) => onCreateRequestClicked(poNumber)}
      />

      <UpdateLocationModal
        open={locationModalOpen}
        onClose={locationModalToggle.off}
        locationId={request.locationId as string}
        onChange={(locationId) => updateRequest({ locationId })}
      />

      <UpdatePhoneModal
        open={updatePhoneModalOpen}
        onClose={updatePhoneModalToggle.off}
        onSuccess={refetchOrgAndBranch}
      />
      <UpdateOrgDetailsModal
        open={updateOrgModalOpen}
        onClose={updateOrgModalToggle.off}
        onSuccess={refetchOrgAndBranch}
      />
      <UpdateOrgLogoModal open={updateOrgLogoModalOpen} onClose={updateOrgLogoModalToggle.off} />
      <UpdateBillingAddressModal
        open={updateBillingAddressModalOpen}
        onClose={updateBillingAddressModalToggle.off}
        branch={branch}
        address={billingAddress}
        organizationId={organizationId}
        onSuccess={refetchOrgAndBranch}
      />

      {request.vendors.length > 0 && showOrgLogoSection && (
        <Box className="p-8">
          <p className="leading-6">Add to your email request</p>
          <p className="text-sm text-gray-600 leading-5 mt-1">
            Add an optional company logo and personal message.
          </p>

          <Field label="Your company logo (optional)" className="mt-4 max-w-md">
            {organization.logoUrl ? (
              <ChangeDetails
                onChangeClick={updateOrgLogoModalToggle.on}
                text={
                  <span className="block">
                    <img src={organization.logoUrl} alt="" className="w-36 h-16 object-contain" />
                  </span>
                }
              />
            ) : (
              <Action.S className="mt-2 text-gray-700" onClick={updateOrgLogoModalToggle.on}>
                <PlusIcon className="inline-block h-4 -mt-0.5 mr-1" /> Add file
              </Action.S>
            )}
          </Field>
        </Box>
      )}

      <Box className="p-8">
        <div className="max-w-md space-y-4">
          <p className="leading-6">Your request details</p>
          <Field label="Request from">
            <ChangeDetails
              title={organization.name}
              onChangeClick={updateOrgModalToggle.on}
              text={
                <>
                  Requested by: {user.displayName}
                  <br />
                  Email: {user.email}
                  <br />
                  {user.phoneNumber ? (
                    <>Phone: {Phone.format(user.phoneNumber)}</>
                  ) : (
                    <button
                      type="button"
                      className="underline underline-offset-2 text-red-500 text-bold"
                      onClick={() => updatePhoneModalToggle.on()}
                    >
                      Add Phone Number
                    </button>
                  )}
                </>
              }
            />
          </Field>

          <hr />

          <Field label="Bill to">
            <ChangeDetails
              title={billingAddress.companyName}
              onChangeClick={updateBillingAddressModalToggle.on}
              text={
                <>
                  {billingAddress?.lineOne} <br />
                  {billingAddress?.city}, {billingAddress?.state} {billingAddress?.postalCode}
                  <br />
                  {billingAddress?.country}
                  <br />
                </>
              }
            />
          </Field>

          <hr />

          <Field label="Drop-off Location">
            {locationIdRequired ? (
              <ChangeDetails
                onChangeClick={locationModalToggle.on}
                text={
                  <>
                    {location?.address?.lineOne}
                    <br />
                    {location?.address?.city}, {location?.address?.state}{' '}
                    {location?.address?.postalCode}
                    <br />
                    {location?.address?.country}
                    <br />
                  </>
                }
              />
            ) : request?.shippingAddress ? (
              <p className="text-gray-600 text-sm leading-5">
                {request?.shippingAddress?.lineOne}
                <br />
                {request?.shippingAddress?.city}, {location?.address?.state}{' '}
                {request?.shippingAddress?.postalCode}
                <br />
                {request?.shippingAddress?.country}
                <br />
              </p>
            ) : (
              <p className="text-gray-600 text-sm leading-5">
                {location?.address?.lineOne}
                <br />
                {location?.address?.city}, {location?.address?.state}{' '}
                {location?.address?.postalCode}
                <br />
                {location?.address?.country}
                <br />
              </p>
            )}
          </Field>

          {request.vendors.length > 0 && (
            <>
              <hr />

              <Field label="Vendors">
                <ChangeDetails
                  onChangeClick={onBackClicked}
                  text={
                    <ul className="flex flex-col gap-y-2">
                      {vendors.map((v) => (
                        <li key={v.id}>
                          <span className="block text-gray-900">
                            {v.name} -{' '}
                            <span className="italix text-gray-600">Account #{v.accountNumber}</span>
                          </span>

                          <ul className="flex gap-x-4">
                            {v.contacts.map((c) => (
                              <li key={c.id} className="text-xs text-gray-600 inline-block">
                                {c.name}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  }
                />
              </Field>
            </>
          )}

          <hr />

          <Field label="Request status">
            <p className="text-gray-600 text-sm leading-5">
              Requested date: {DateTime.now().toFormat('D')}
              <br />
              Needed by: {request.urgency?.neededByDate.toFormat('D')}
              <br />
              Machine down: {request.urgency?.machineDown ? 'Yes' : 'No'}
            </p>
          </Field>

          <hr />

          <Field label="Fitment for machine">
            <p className="text-gray-600 text-sm leading-5">
              Unit number: {machine?.name} <br />
              Serial number: {machine?.serialNumber}
              <br />
              Make: {machine?.machine.make ?? 'Unknown'}
              <br />
              Model: {machine?.machine.model ?? 'Unknown'}
              <br />
              Year: {machine?.machine.year ?? 'Unknown'}
            </p>
          </Field>

          <hr />

          <Field label="Parts requested">
            <div className="space-y-3">
              {request.parts?.map((part) => (
                <Fragment key={part.partNumber ?? part.description}>
                  <p key={part.partNumber} className="text-gray-600 text-sm leading-5">
                    Part number: {part.partNumber || 'N/A'} <br />
                    {part.description && (
                      <>
                        Description: {part.description}
                        <br />
                      </>
                    )}
                    Quantity: {part.quantity.toFixed(0)}
                    {requestsJobNumbersEnabled &&
                      part.jobQuantities &&
                      part.jobQuantities.length > 0 && (
                        <>
                          <br />
                          Job Numbers:{' '}
                          {part.jobQuantities.map((jq) => allJobCodes[jq.jobId] ?? '').join(', ')}
                        </>
                      )}
                  </p>
                  {part.pictures.length > 0 && <ImagesList imageUrls={part.pictures} />}
                </Fragment>
              ))}
            </div>
          </Field>

          {request.comments && (
            <>
              <hr />

              <Field label="Additional details">
                <p className="text-gray-600 text-sm leading-5">{request.comments}</p>
              </Field>
            </>
          )}
        </div>
      </Box>
      <StickyBottomBar>
        {reset && <StartOverButton reset={reset} />}

        {request.vendors.length > 0 && location && (
          <PreviewEmailButton
            request={request}
            location={location}
            orgMachine={machine}
            organization={organization}
            branch={branch}
          />
        )}

        <div className="flex-grow" />
        <Action.S onClick={onBackClicked}>Previous</Action.S>
        {user.phoneNumber ? (
          <Action.P color="blue" onClick={onSendRequest} performing={submitInProgress}>
            {submitText}
          </Action.P>
        ) : (
          <SimpleTooltip
            placement="top-start"
            text="You must add a phone number to submit"
            className="lg:max-w-56"
          >
            <Action.P
              color="blue"
              onClick={onSendRequest}
              performing={submitInProgress}
              disabled={!user.phoneNumber}
            >
              {submitText}
            </Action.P>
          </SimpleTooltip>
        )}
      </StickyBottomBar>
    </>
  )
}

export default ReviewStep
