import { useCopyRequestQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import usePersistedState from '@/buyers/pages/CreateRequest/usePersistedState'
import nth from 'lodash/nth'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import useSession from '../hooks/useSession'

const CopyRequest = () => {
  const { orgId } = useSession()
  const { rfqId } = useParams() as { rfqId: string }
  const { update: updateCreateRequestForm } = usePersistedState('new-request-data-2')
  const [copied, setCopied] = useState(false)

  const rfqs = useCopyRequestQuery({
    variables: { orgId, filter: JSON.stringify(['id_eq', rfqId]) },
    client: useGqlClient(),
  }).data?.searchRequests.requests

  const rfq = rfqs && (nth(rfqs, 0) || null)

  useEffect(() => {
    if (rfq) {
      const machineOrgId = nth(rfq.orgMachines, 0)?.id

      updateCreateRequestForm({
        billingCompanyId: rfq.billingCompanyId,
        workOrderNumber: undefined,
        machineOrgId,
        machineInvolved: !!machineOrgId,
        urgency: undefined,
        parts: rfq.parts.map((p, index) => ({
          partNumber: p.mpn,
          description: p.description,
          quantity: p.quantity,
          externalId: p.externalId ?? null,
          taskNumber: p.taskNumber ?? null,
          suggestion: p.suggestion ?? null,
          // Put all the pictures on the first part until we have images linked to parts in the Request
          pictures: index === 0 ? rfq.images.map(({ url }) => url) : [],
          jobQuantities: [],
        })),
        comments: rfq.partsRequest ?? '',
        quickOrder: undefined,
        broadcastToNetwork: false,
        dealerLocationIds: rfq.vendorLinks.map((vl) => vl.vendor.id),
        vendors: rfq.vendorLinks.map((vl) => ({
          vendorId: vl.vendor.id,
          contactIds: vl.vendorContacts.map((vc) => vc.id),
          deliveryMethod: vl.deliveryMethod,
          accountNumber: vl.accountNumber ?? '',
          fleetioId: null,
          externalVendorId: null,
        })),
        locationId: undefined,
        nearbyReferencePoint: undefined,
      })

      setCopied(true)
    }
  }, [!rfq])

  return copied ? (
    <Navigate
      to={`/rfqs/create?source.path=${encodeURIComponent(
        document.location.pathname
      )}&source.button=copy-request`}
      replace
    />
  ) : null
}

export default CopyRequest
