import { useEffect, useState } from 'react'
import { Maybe, ModalSize } from '@/types'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/next/forms/Field'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import CostCodeSelector from '@/buyers/components/CostCodeSelector'
import { useSetLineItemCostCodeMutation } from '@/buyers/_gen/gql'

const NA = <span className="italic text-gray-500">N/A</span>

const EditLineItemCostCodeModal = ({
  open,
  onClose,
  lineItem,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  lineItem: { id: string; partNumber: string; description: string; costCodeId: Maybe<string> }
}) => {
  const client = useGqlClient()
  const [updateMutation] = useSetLineItemCostCodeMutation({ client })
  const [_, msgs] = useMsgs()
  const [performing, performingToggle] = useToggle()
  const [costCodeId, setCostCodeId] = useState<Maybe<string>>(lineItem.costCodeId)

  useEffect(() => {
    if (!open) {
      setCostCodeId(lineItem.costCodeId)
      performingToggle.off()
    }
  }, [open, lineItem])

  const onSubmit = async () => {
    try {
      performingToggle.on()

      await updateMutation({ variables: { lineItemId: lineItem.id, costCodeId: costCodeId } })

      msgs.add('Cost Code saved.', 'positive')

      onClose()
      onSuccess()
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      performingToggle.off()
    }
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="p-8 relative flex flex-col gap-y-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <h3 className="text-2xl font-medium">Select Cost Code</h3>

        <div className="space-y-2">
          <p className="grid grid-cols-[7rem_auto] gap-x-4 text-base">
            <span>Part Number: </span> <span>{lineItem.partNumber || NA}</span>
            <span>Description: </span> <span>{lineItem.description || NA}</span>
          </p>
        </div>

        <Field label="Cost Code">
          <CostCodeSelector value={costCodeId} onChange={setCostCodeId} />
        </Field>

        <div className="flex gap-x-4">
          <Action.S onClick={onClose} className="flex-1 text-base font-medium">
            Cancel
          </Action.S>
          <Action.P
            onClick={onSubmit}
            performing={performing}
            color="blue"
            className="flex-1 text-base font-medium"
          >
            Update
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default EditLineItemCostCodeModal
