import { useVendorQuery, VendorQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import DetailsPageWithSidebar from '@/gf/components/Layout/DetailsPageWithSidebar'
import RouterTabs from '@/gf/components/Layout/RouterTabs'
import Page from '@/gf/components/Page'
import VendorM from '@/gf/modules/Vendor'
import { RssIcon, UsersIcon } from '@heroicons/react/outline'
import { createContext, useContext as reactUseContext } from 'react'
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import Frame from '../components/Frame'
import Activity from './Vendor/Activity'
import Contacts from './Vendor/Contacts'
import Sidebar from './Vendor/VendorSidebar'

type Context = {
  vendor: NonNullable<VendorQuery['vendor']>
  org: Exclude<VendorQuery['org'], null> | undefined
  refetchVendor: () => void
  initialEditMode?: boolean
}

const Context = createContext<Context | undefined>(undefined)

export const useContext = () => {
  const context = reactUseContext(Context)
  if (context === undefined) throw new Error('must be used within provider')
  return context
}

const Vendor = () => {
  const { orgId } = useSession()
  const { vendorId } = useParams() as { vendorId: string }
  const [searchParams] = useSearchParams()
  const shouldEdit = searchParams.get('edit') === 'true'

  const { data, previousData, refetch } = useVendorQuery({
    variables: { id: vendorId, orgId },
    client: useGqlClient(),
  })

  const { vendor } = data || {}
  const { org } = data || previousData || {}
  const baseUrl = `/vendors/${vendorId}`

  if (!vendor || org === null) return null

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Vendors', href: '/vendors' },
      { name: vendor.name || 'Vendor details', href: baseUrl },
    ],
  }

  return (
    <Context.Provider value={{ vendor, org, refetchVendor: refetch, initialEditMode: shouldEdit }}>
      <Frame breadcrumbs={breadcrumbs}>
        <Page title={vendor.name || `Vendor ${VendorM.shortenId(vendor.id)}`}>
          <DetailsPageWithSidebar sidebar={<Sidebar />}>
            <RouterTabs
              tabs={[
                { label: 'Activity', path: baseUrl, icon: RssIcon },
                { label: 'Contacts', path: `${baseUrl}/contacts`, icon: UsersIcon },
              ]}
            >
              <Routes>
                <Route path="" element={<Activity />} />
                <Route path="contacts/*" element={<Contacts />} />
              </Routes>
            </RouterTabs>
          </DetailsPageWithSidebar>
        </Page>
      </Frame>
    </Context.Provider>
  )
}

export default Vendor
