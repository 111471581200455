import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import Checkbox from '@/gf/components/Checkbox'
import {
  OrgSettingsQuery,
  useUpdateCostCodesEnabledMutation,
  useUpdateRequestsJobNumbersMutation,
} from '@/buyers/_gen/gql'
import Link from '@/gf/components/Link'
import Card from '@/gf/components/Card'

const JobNumbersAndCostCodes = ({ org }: { org: NonNullable<OrgSettingsQuery['org']> }) => {
  const client = useGqlClient()
  const [jobNumberEnabled, setJobNumberEnabled] = useState(org.requestsJobNumbersEnabled)
  const [costCodesEnabled, setcostCodesEnabled] = useState(org.costCodesEnabled)
  const [updateJobNumbersEnabled] = useUpdateRequestsJobNumbersMutation({ client })
  const [updateCostCodesEnabled] = useUpdateCostCodesEnabledMutation({ client })

  const updateJobNumbersToggle = async (value: boolean) => {
    await updateJobNumbersEnabled({ variables: { value } })

    client.reFetchObservableQueries()
  }

  const updateCostCodesToggle = async (value: boolean) => {
    await updateCostCodesEnabled({ variables: { value } })

    client.reFetchObservableQueries()
  }

  useEffect(() => {
    updateJobNumbersToggle(jobNumberEnabled)
  }, [jobNumberEnabled])

  useEffect(() => {
    updateCostCodesToggle(costCodesEnabled)
  }, [costCodesEnabled])

  return (
    <Card title="Cost Settings">
      <Card.Section>
        <div className="space-y-6">
          <div className="space-y-1">
            <label className="flex items-center gap-x-2">
              <Checkbox
                checked={jobNumberEnabled}
                onChange={() => setJobNumberEnabled(!jobNumberEnabled)}
              />

              <span className="text-sm">Job Numbers Enabled</span>
            </label>
            <p className="text-sm text-gray-500">
              Specify each part's Job Number when ordering parts.
            </p>
            <Link.T to="job-numbers" className="inline-block">
              Manage Job Numbers
            </Link.T>
          </div>

          <div className="space-y-1">
            <label className="flex items-center gap-x-2">
              <Checkbox
                checked={costCodesEnabled}
                onChange={() => setcostCodesEnabled(!costCodesEnabled)}
              />

              <span className="text-sm">Cost Codes Enabled</span>
            </label>
            <p className="text-sm text-gray-500">
              Specify Cost Codes for order items and shipping.
            </p>
            <Link.T to="cost-codes" className="inline-block">
              Manage Cost Codes
            </Link.T>
          </div>
        </div>
      </Card.Section>
    </Card>
  )
}

export default JobNumbersAndCostCodes
