import { TrashIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import type { Org } from '../Integrations'

import {
  useInstallTennaAppMutation,
  useInstallAssetworksAppMutation,
  useInstallHcssAppMutation,
  useInstallQtaniumAppMutation,
  useUninstallAppMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import Time from '@/gf/modules/Time'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Link from '@/gf/components/Link'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import InstallFleetio from './Apps/InstallFleetio'

const appNameById = {
  tenna: 'Tenna',
  assetworks: 'AssetWorks',
  qtanium: 'Qtanium',
  hcss: 'E360',
  fleetio: 'Fleetio',
}

const Apps = ({
  org,
  onInstalled,
  onUninstalled,
}: {
  org?: Org
  onInstalled: () => Promise<unknown>
  onUninstalled: () => Promise<unknown>
}) => {
  const [_, msgr] = useMsgs()
  const [installing, setInstalling] = useState(false)
  const client = useGqlClient()
  const { featureFlags } = useSession()
  const [installQtaniumApp] = useInstallQtaniumAppMutation({ client })
  const [installHcssApp] = useInstallHcssAppMutation({ client })
  const [installAssetApp] = useInstallAssetworksAppMutation({ client })
  const [installTennaApp] = useInstallTennaAppMutation({ client })
  const [uninstallAppMutation] = useUninstallAppMutation({ client })
  const [uninstallingId, setUninstallingId] = useState<string | null>(null)

  if (!org) return null

  const installAsset = () => {
    setInstalling(true)

    installAssetApp({ variables: { orgId: org.id } })
      .then(() => {
        onInstalled().then(() => msgr.add('Asset Works app installed.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setInstalling(false))
  }

  const installTenna = () => {
    setInstalling(true)

    installTennaApp({ variables: { orgId: org.id } })
      .then(() => {
        onInstalled().then(() => msgr.add('Tenna app installed.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setInstalling(false))
  }

  const installHcss = () => {
    setInstalling(true)

    installHcssApp({ variables: { orgId: org.id } })
      .then(() => {
        onInstalled().then(() => msgr.add('HCSS app installed.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setInstalling(false))
  }

  const installQtanium = () => {
    setInstalling(true)

    installQtaniumApp({ variables: { orgId: org.id } })
      .then(() => {
        onInstalled().then(() => msgr.add('Qtanium app installed.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setInstalling(false))
  }

  const uninstallApp = (orgAppId: string) => {
    setUninstallingId(orgAppId)

    uninstallAppMutation({ variables: { orgAppId } })
      .then(() => {
        onUninstalled().then(() => msgr.add('App uninstalled.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setUninstallingId(null))
  }

  return (
    <>
      <Routes>
        <Route path="install-fleetio" element={<InstallFleetio onInstalled={onInstalled} />} />
      </Routes>

      <Card title="Apps">
        <Card.Section>
          <div className="space-y-4">
            <div className="flex gap-4">
              {!org.orgApps.find((oa) => oa.appId === 'qtanium') && (
                <Action.S onClick={installQtanium} performing={installing}>
                  Install Qtanium
                </Action.S>
              )}
              {!org.orgApps.find((oa) => oa.appId === 'assetworks') && (
                <Action.S onClick={installAsset} performing={installing}>
                  Install Asset Works
                </Action.S>
              )}
              {!org.orgApps.find((oa) => oa.appId === 'hcss') && (
                <Action.S onClick={installHcss} performing={installing}>
                  Install HCSS E360
                </Action.S>
              )}
              {featureFlags.tennaPush && !org.orgApps.find((oa) => oa.appId === 'tenna') && (
                <Action.S onClick={installTenna} performing={installing}>
                  Install Tenna
                </Action.S>
              )}

              {org.features.fleetioApp && !org.orgApps.find((oa) => oa.appId === 'fleetio') && (
                <Link.S to="install-fleetio">Install Fleetio</Link.S>
              )}
            </div>

            <Table>
              <Thead>
                <tr>
                  <Th>Name</Th>
                  <Th>API Key</Th>
                  <Th>Installed</Th>
                  <Th />
                </tr>
              </Thead>
              <Tbody>
                {org.orgApps.map((orgApp) => (
                  <Tr key={orgApp.id}>
                    <Td>{appNameById[orgApp.appId]}</Td>

                    <Td>
                      <div className="flex">
                        <div className="font-mono px-4 py-2 border-1 bg-gray-50">
                          {orgApp.key.key}
                        </div>
                      </div>
                    </Td>

                    <Td>{Time.formatDateTime(orgApp.installedAt)}</Td>

                    <Td>
                      <Action.S
                        onClick={() => uninstallApp(orgApp.id)}
                        performing={uninstallingId === orgApp.id}
                        size="sm"
                        className="text-gray-400 hover:text-red-700"
                      >
                        {uninstallingId !== orgApp.id && <TrashIcon className="w-5 h-5" />}
                      </Action.S>
                    </Td>
                  </Tr>
                ))}

                {org.orgApps.length === 0 && (
                  <Tr>
                    <Td colSpan={3}>
                      <div className="text-gray-500 italic text-center">No apps installed.</div>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        </Card.Section>
      </Card>
    </>
  )
}

export default Apps
