import { useEffect } from 'react'

import mapImage from '@/gf/images/map.png'
import reportsImage from '@/gf/images/reports.png'

import * as GE from '@/gf/modules/GrammarEvents'

import Action from '@/gf/components/Action'
import A from './A'
import SiteUrls from '@/retail/modules/SiteUrls'

const TrialExpiredSplashContent = ({ onClose }: { onClose: () => void }) => {
  useEffect(() => {
    GE.clicksButton('parts-hub-pro-trial-expired-open')
  }, [])

  return (
    <div className="flex flex-col gap-y-12 items-center">
      <div className="flex flex-col gap-y-6 items-center">
        <div className="space-y-4">
          <h2 className="max-w-lg text-2xl font-medium">Your Parts Hub Pro free trial has ended</h2>

          <div className="max-w-md prose text-center">
            Reduce your costs, spend less time waiting on parts, and get the insights you need to
            fuel equipment uptime, so your org can make more money and win more jobs.
          </div>
        </div>

        <div className="max-w-md w-full flex gap-x-4 items-center">
          <Action.S
            className="w-full text-center text-base"
            onClick={() => {
              GE.clicksButton('parts-hub-pro-trial-expired-maybe-later')
              onClose()
            }}
          >
            Maybe later
          </Action.S>
          <A.P
            href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
            size="md"
            className="w-full text-center text-base"
            color="blue"
          >
            Get pricing
          </A.P>
        </div>
      </div>

      <div className="flex flex-row gap-x-12 items-center">
        <div className="w-64 flex flex-col gap-y-4 items-center text-center">
          <img src={mapImage} alt="Vendors map" />
          <span className="text-base text-gray-900 font-medium">
            Work with all your current vendors
          </span>
        </div>
        <div className="w-64 flex flex-col gap-y-4 items-center text-center">
          <img src={reportsImage} alt="Parts report" />
          <span className="text-base text-gray-900 font-medium">
            Unlock parts purchasing insights
          </span>
        </div>
      </div>
    </div>
  )
}

export default TrialExpiredSplashContent
