import { LimitedOrderQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import useToggle from '@/gf/hooks/useToggle'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import EditLineItemCostCodeModal from './EditLineItemCostCodeModal'

const CostCodeAction = ({
  lineItem,
}: {
  lineItem: NonNullable<NonNullable<LimitedOrderQuery['storeOrder']>['lineItems'][number]>
}) => {
  const [open, toggle] = useToggle()
  const client = useGqlClient()
  return (
    <>
      <EditLineItemCostCodeModal
        open={open}
        onClose={toggle.off}
        lineItem={{
          id: lineItem.id,
          partNumber: lineItem.product.mpn ?? '',
          description: lineItem.product.name ?? '',
          costCodeId: lineItem.costCode?.id ?? null,
        }}
        onSuccess={() => client.reFetchObservableQueries()}
      />

      {lineItem.costCode ? (
        <Action.T onClick={toggle.on}>{lineItem.costCode.code}</Action.T>
      ) : (
        <Action.T className="text-sm" onClick={toggle.on}>
          Add
        </Action.T>
      )}
    </>
  )
}

export default CostCodeAction
