import { useCallback, useRef } from 'react'
import { useAutocompleteMakesLazyQuery } from '../_gen/gql'
import useGqlClient from './useGqlClient'
import debouncePromise from '@/gf/modules/debouncePromise'

type MakeOption = {
  id: string
  value: string
  label: string
}

const useMakeAutocomplete = () => {
  const client = useGqlClient()
  const [autocompleteMakes] = useAutocompleteMakesLazyQuery({ client })
  const debouncedAutocompleteMakes = useRef(debouncePromise(autocompleteMakes, 300)).current

  const onAutocompleteMakes = useCallback(
    async (prefix: string): Promise<MakeOption[]> => {
      const result = await debouncedAutocompleteMakes({ variables: { search: prefix } })

      if (result === 'skipped') {
        return []
      }

      return (result.data?.autocompleteMakes ?? []).map((make) => ({
        id: make,
        value: make,
        label: make,
      }))
    },
    [debouncedAutocompleteMakes]
  )

  return onAutocompleteMakes
}

export default useMakeAutocomplete
