import { useMemo, useState } from 'react'
import pick from 'lodash/pick'
import classNames from 'classnames'
import {
  CreateRequestSelectLocationsQuery,
  useCreateRequestSelectLocationsQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Box from '@/gf/components/Box'
import TextTag from '@/gf/components/next/TextTag'
import { US_CENTER } from '@/gf/modules/Map'
import { Maybe, Point } from '@/types'
import SearchInput from '@/gf/components/SearchInput'
import Dropdown from '@/gf/components/next/Dropdown'
import DropdownAction from '@/gf/components/next/DropdownAction'
import Ghost from '@/gf/components/Ghost'
import Action from '@/gf/components/Action'
import Address from '@/gf/modules/Address'

const Loading = () => (
  <>
    {[1, 2, 3, 4].map((v) => (
      <Box key={v} className="p-3">
        <Ghost className="h-6 w-2/3 block" />
        <Ghost className="h-4 w-1/2 block mt-2" />
      </Box>
    ))}
  </>
)

const LocationItem = ({
  location,
  selected,
  defaultLocationId,
  onClick,
}: {
  location: NonNullable<CreateRequestSelectLocationsQuery['defaultShippingLocation']>
  selected: boolean
  defaultLocationId: string | undefined
  onClick: () => void
}) => (
  <Box
    className={classNames(
      'p-3 cursor-pointer shadow-base transition duration-200',
      selected ? 'border-2 border-blue-600' : 'hover:border-gray-400'
    )}
    onClick={onClick}
  >
    <h6 className="flex justify-between items-center">
      <span className="text-base font-medium">
        {location.name} {location.code && `(${location.code})`}
      </span>
      {location.id === defaultLocationId && <TextTag color="blue">Default Location</TextTag>}
    </h6>
    <p className="text-base">{Address.formatOneLineNoCountry(location.address)}</p>
  </Box>
)

const LocationsList = ({
  selectedLocationId,
  onLocationSelected,
  onAddLocationClicked,
  orgHasLocations,
}: {
  selectedLocationId: Maybe<string>
  onLocationSelected: (point: Point, locationId: string) => void
  onAddLocationClicked: () => void
  orgHasLocations: boolean
}) => {
  const { orgId, user } = useSession()
  const client = useGqlClient()
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<'request_date' | 'alphabetical'>('request_date')

  const { data } = useCreateRequestSelectLocationsQuery({
    variables: {
      orgId,
      userId: user.id,
      search: search ?? '',
      orderBy:
        sorting === 'request_date'
          ? { id: 'request_date', order: 'desc' }
          : { id: 'name', order: 'asc' },
    },
    client,
  })

  const locations = useMemo(() => {
    if (search || sorting !== 'request_date') {
      return data?.org?.locations ?? []
    }

    const defaultLocation = data?.defaultShippingLocation ?? null
    const others = data?.org?.locations.filter((l) => l.id !== defaultLocation?.id) ?? []

    return defaultLocation ? [defaultLocation, ...others] : others
  }, [data, search, sorting])

  return (
    <div className="space-y-4">
      <div className="flex gap-x-3">
        <SearchInput
          value={search}
          onChange={(value) => setSearch(value)}
          placeholder="Type to search for a location"
          className="flex-grow"
        />

        {orgHasLocations ? (
          <Action.S className="flex-shrink-0" onClick={onAddLocationClicked}>
            Add Location
          </Action.S>
        ) : (
          <Action.P className="flex-shrink-0" onClick={onAddLocationClicked} color="blue">
            Add Location
          </Action.P>
        )}

        <Dropdown
          placement="bottom-end"
          trigger={
            <DropdownAction>
              <span className="text-sm">
                Sort: {sorting === 'request_date' ? 'Recently Used' : 'Alphabetical'}
              </span>
            </DropdownAction>
          }
        >
          <Box className="shadow-lg flex flex-col w-40 overflow-hidden">
            <button
              type="button"
              className={classNames(
                'px-3 py-2 bg-gray-50 text-sm text-left',
                sorting === 'alphabetical' && 'text-blue-600'
              )}
              onClick={() => setSorting('alphabetical')}
            >
              Alphabetical
            </button>
            <button
              type="button"
              className={classNames(
                'px-3 py-2 bg-gray-50 text-sm text-left',
                sorting === 'request_date' && 'text-blue-600'
              )}
              onClick={() => setSorting('request_date')}
            >
              Recently Used
            </button>
          </Box>
        </Dropdown>
      </div>

      <div className="overflow-y-auto space-y-3">
        {data === undefined ? (
          <Loading />
        ) : locations.length === 0 ? (
          <p>No locations found {search && `for ${search}`}.</p>
        ) : (
          locations.map((l) => (
            <LocationItem
              key={l.id}
              selected={selectedLocationId === l.id}
              location={l}
              defaultLocationId={data.defaultShippingLocation?.id}
              onClick={() =>
                onLocationSelected(
                  l.address.point ? pick(l.address.point, ['lat', 'lng']) : US_CENTER,
                  l.id
                )
              }
            />
          ))
        )}
      </div>
    </div>
  )
}

export default LocationsList
